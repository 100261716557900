<template>
  <v-dialog v-model="showUserInput" width="800">
    <v-card>
      <v-card-title class="primary darken-2 white--text"><h1 class="text-h5">{{
        userInputTitle
      }}</h1></v-card-title>

      <v-card-text>{{ userInputMessage }}</v-card-text>

      <v-divider></v-divider>
      <v-form ref="form" v-model="valid">
        <v-text-field
          ref="inputdata"
          v-model="inputdata"
          :label="$t('input')"
          :error-messages="inputErrors"
          v-on:input="validateInput"
          required
        ></v-text-field>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="primary"
          v-if="callbackSubmit"
          :disabled="!valid"
          @click="submit"
        >
          {{ $t("submit") }}
        </v-btn>
        <v-btn class="primary" v-if="callbackAbort" @click="cancel_and_abort">
          {{ $t("cancel") }}
        </v-btn>
        <!--
        <v-btn v-if="callbackSave"
          @click="cancel_and_save"
        >
          {{ $t('cancel_and_save') }}
        </v-btn>
        -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardUserInput",
  data() {
    return {
      userInputTitle: String,
      userInputMessage: String,
      inputErrors: [],
      showUserInput: false,
      valid: false,
      inputdata: "",
      buttons: [{ name: "ok", text: "$t{'input'}", form_must_be_valid: true }],
      callbackSubmit: false,
      callbackAbort: false,
      callbackSave: false,
      callbackArgument: null
    };
  },
  props: {},
  methods: {
    doShowUserInput: function(
      title,
      message,
      presetValue,
      errors,
      callbackSubmit,
      callbackAbort,
      callbackSave,
      callbackArgument
    ) {
      // this is the actual initialization, so reset everything here
      this.valid = false;
      this.inputdata = presetValue;
      this.inputErrors = errors;
      this.userInputTitle = title;
      this.userInputMessage = message;
      this.showUserInput = true;
      this.callbackSubmit = callbackSubmit;
      this.callbackAbort = callbackAbort;
      this.callbackSave = callbackSave;
      this.callbackArgument = callbackArgument;
    },
    validateInput: function() {
      if (this.inputdata === "") {
        this.inputErrors = [this.$t("input_empty_error")];
        this.valid = false;
      } else {
        this.inputErrors = [];
        this.valid = true;
      }
    },
    submit: function() {
      // TODO: callback for 'ok', maybe it can return whether there are errors?
      // or should that be another call
      this.showUserInput = false;
      this.callbackSubmit(this.inputdata, this.callbackArgument);
    },
    cancel_and_abort: function() {
      this.showUserInput = false;
      this.callbackAbort();
    },
    cancel_and_save: function() {
      this.showUserInput = false;
      this.callbackSave();
    }
  }
};
</script>
