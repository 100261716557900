<template>
  <v-card style="padding-left:16px; padding-right:16px; padding-bottom:16px;">
    <v-card-title class="cardheader">
      <h1 class="text-h5">{{ title }}</h1>
      <v-layout wrap justify-end>
        <v-card-actions class="text-center">
          <v-btn icon color="primary" @click.stop="closeEdit" :title="$t('close')"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-actions>
      </v-layout>
    </v-card-title>

    <v-form
      ref="form"
      v-model="valid"
      v-on:submit.prevent="saveCurrentTestAccessPoint"
    >
      <v-text-field
        :label="$t('name')"
        v-model="currentAccessPoint.name"
        :rules="testAccessPointFormNameRules"
        :error-messages="testAccessPointFormNameErrors"
        @input="clearTestAccessPointFormNameErrors"
        ref="initialfocus"
        autofocus
      ></v-text-field>
      <v-text-field
        :label="$t('description')"
        :rules="testAccessPointFormDescriptionRules"
        :error-messages="testAccessPointFormDescriptionErrors"
        @input="clearTestAccessPointFormDescriptionErrors"
        v-model="currentAccessPoint.description"
      ></v-text-field>
      <v-card class="options">
        <div><i>{{ $t('url_superflous_description') }}</i></div>
        <v-text-field
          :v-show="currentAccessPoint.can_receive"
          :label="$t('as2_url')"
          v-model="currentAccessPoint.url"
          :rules="testAccessPointFormUrlRules"
          @input="clearTestAccessPointFormUrlErrors"
          :error-messages="testAccessPointFormUrlErrors"
        >
        </v-text-field>
        <v-select
          item-text="name"
          item-value="value"
          :items="transportTypes"
          :label="$t('accesspoint_transport_type')"
          v-model="currentAccessPoint.transport_type"
        ></v-select>
        <v-card-actions>
          <v-row>
            <!-- When we implement certificate probing for AS4, we can enable this again
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="primary mr-2 mb-2"
                  :disabled="cannotProbeAccessPoint"
                  v-on:click.native="probeAccessPoint"
                  >{{ $t("probe") }}</v-btn
                >
              </template>
              <span>{{ $t("probe_tooltip") }}</span>
            </v-tooltip>
            <span>&nbsp;&nbsp;&nbsp;</span>
            -->
            <v-btn
              v-on="on"
              class="primary"
              v-on:click.native="showUploadCertificate"
              :title="$t('select_certificate_tooltip')"
              >{{ $t("select_certificate") }}</v-btn
            >
          </v-row>
        </v-card-actions>
        <v-card-text>
          <div style="text-align: left; color: #000000;">
            <p>{{ $t('as2_id')}}: {{ currentAccessPoint.accesspoint_id }}
            <p lang="en">Certificate subject: {{ currentAccessPoint.cert_subject }}</p>
            <p lang="en">Certificate issuer: {{ currentAccessPoint.cert_issuer }}</p>
          </div>
        </v-card-text>
      </v-card>

      <v-card-actions>
        <v-layout class="mt-4">
        <v-row>
          <v-btn class="primary mr-2 mb-2" type="submit">{{ $t("save") }}</v-btn>
          <v-btn class="primary" v-on:click.native="closeEdit" :title="$t('cancel')">{{
            $t("cancel")
          }}</v-btn>
        </v-row>
        </v-layout>
      </v-card-actions>
    </v-form>

    <v-dialog v-model="showUploadCertificateDialog" max-width="400" aria-labelledby="selectFileInput">
      <v-card>
        <v-form v-on:submit.prevent="uploadAndParseCertificateFile">
          <v-card-text>
            <v-file-input
              id="selectFileInput"
              :label="$t('select_file')"
              autofocus
              v-model="certificateSelectedFile"
              prepend-icon="folder"
            />
          </v-card-text>
          <v-card-actions>
            <v-layout>
            <v-row>
              <v-btn class="primary ml-3 mr-2 mb-2" type="submit">{{ $t("submit") }}</v-btn>
              <v-btn
                class="primary"
                @click="showUploadCertificateDialog = false"
                >{{ $t("cancel") }}</v-btn
              >
            </v-row>
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <BackendConnector ref="backend"></BackendConnector>
    <TestTaskViewer
      v-on:error="testTaskDone()"
      ref="taskViewer"
    ></TestTaskViewer>
    <StandardErrorMessage ref="errMsg"></StandardErrorMessage>
  </v-card>
</template>

<script>
import TestTaskViewer from "@/components/TestTaskViewer.vue";
import StandardErrorMessage from "@/components/StandardErrorMessage.vue";

export default {
  name: "AccessPointEdit",
  components: {
    TestTaskViewer,
    StandardErrorMessage
  },
  props: ["title", "accesspointId", "callbackDone"],
  data() {
    return {
      certificateSelectedFile: null,

      // JSON data representing the details of the access point
      currentAccessPoint: { pki_instance: "asdf", transport_type: "AS4" },
      savedAccessPoint: {},

      showUploadCertificateDialog: false,
      showTestAccessPointDialog: false,
      testAccessPointFormUrlRules: [v => !!v || this.$t("url_required")],
      testAccessPointFormUrlErrors: [],
      testAccessPointFormNameRules: [v => !!v || this.$t("name_required")],
      testAccessPointFormNameErrors: [],
      testAccessPointFormDescriptionRules: [
        v => !!v || this.$t("description_required")
      ],
      testAccessPointFormDescriptionErrors: [],
      valid: true,
      showTestAccessPointDeleteDialog: false,
      testAccessPointCertificateErrors: ["asdf"],
      currentTestAccessPoint: {
        name: "",
        id: -1,
        description: "",
        url: "",
        transport_type: "AS4"
      },
      testAccessPointFormCertificateRules: [
        v => !!v || this.$t("name_required")
      ],
      testAccessPointFormCertificateErrors: [],

      transportTypes: [
        { name: "AS4", value: "AS4" }
      ]
    };
  },
  methods: {
    clearTestAccessPointFormNameErrors: function() {
      this.testAccessPointFormNameErrors = [];
    },
    clearTestAccessPointFormDescriptionErrors: function() {
      this.testAccessPointFormDescriptionErrors = [];
    },
    clearTestAccessPointFormUrlErrors: function() {
      this.testAccessPointFormUrlErrors = [];
    },
    resetCurrentTestAccessPoint: function() {
      this.currentAccessPoint = JSON.parse(
        JSON.stringify(this.savedAccessPoint)
      );
    },
    saveCurrentTestAccessPoint: function() {
      var url = process.env.VUE_APP_ROOT_API + "/accesspoints/list/";
      if (this.accesspointId > -1 || this.currentAccessPoint.id) {
        url += this.currentAccessPoint.id + "/";
        this.$refs.backend
          .put_auth(url, this.currentAccessPoint)
          .then((response) => {
            if (this.callbackDone) {
              this.callbackDone();
            }
          })
          .catch((error) => {
            if (error.response.data) {
              // TODO: if (error.response.data.description) {
              if (error.response.data.name) {
                this.testAccessPointFormNameErrors = this.$t('error_prefix') + error.response.data.name;
                this.valid = false;
                this.$refs.form.validate();
              }
              if (error.response.data.description) {
                this.testAccessPointFormDescriptionErrors =
                  this.$t('error_prefix') + error.response.data.description;
                this.valid = false;
                this.$refs.form.validate();
              }
              if (error.response.data.url) {
                this.testAccessPointFormUrlErrors = this.$t('error_prefix') + error.response.data.url;
                this.valid = false;
                this.$refs.form.validate();
              }
            } else {
              alert(JSON.stringify(error.response));
            }
          });
      } else {
        this.$refs.backend
          .post_auth(url, this.currentAccessPoint)
          .then((response) => {
            // TODO: report success back to caller instead of calling loadData
            if (this.callbackDone) {
              this.callbackDone();
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.url) {
                this.testAccessPointFormUrlErrors = this.$t('error_prefix') + error.response.data.url;
                this.valid = false;
              }
              if (error.response.data.name) {
                this.testAccessPointFormNameErrors = this.$t('error_prefix') + error.response.data.name;
                this.valid = false;
              }
              if (error.response.data.description) {
                this.testAccessPointFormDescriptionErrors =
                  this.$t('error_prefix') + error.response.data.description;
                this.valid = false;
              }
              if (error.response.data.certificate) {
                this.testAccessPointFormCertificateErrors =
                  this.$t('error_prefix') + error.response.data.certificate;
                this.valid = false;
              }
            }
          });
      }
    },
    showUploadCertificate: function() {
      this.showUploadCertificateDialog = true;
    },
    closeUploadCertificateDialog: function() {
      this.showUploadCertificateDialog = false;
      alert("yo: " + this.certificateSelectedFile);
    },
    uploadAndParseCertificateFile: function() {
      // TODO: check form

      let file = this.certificateSelectedFile;
      let reader = new FileReader();
      var filedata = {};

      reader.onload = ((result) => {
        return (e) => {
          result.certificate_data = e.target.result;
          result.certificate_file = file.name;
          let url =
            process.env.VUE_APP_ROOT_API + "/accesspoints/parse_certificate/";
          this.$refs.backend
            .post_auth(url, result)
            .then((result) => {
              if (result.data) {
                if (result.data.error) {
                  this.$refs.errMsg.doShowError("Error!", result.data.error, "en");
                } else {
                  // handle
                  this.currentAccessPoint["accesspoint_id"] =
                    result.data.common_name;
                  this.currentAccessPoint["cert_subject"] = result.data.subject;
                  this.currentAccessPoint["cert_issuer"] = result.data.issuer;
                  this.currentAccessPoint["certificate_data"] = result.data.pem;
                  this.showUploadCertificateDialog = false;
                  console.log("New certificate data set: " + result.data.subject)
                }
              } else {
                // this should have been caught by the backend already
                //this.$refs.errMsg.doShowError('Error', 'Error connecting to backend...', 'en')
                console.error(error);
              }
            })
            .catch(function(error) {
              console.error(error);
              //alert('error! ' + error)
            });
        };
      })(filedata);
      //reader.readAsText(file, 'UTF-8')
      if (file === null) {
        this.$refs.errMsg.doShowError(this.$t('no_certificate_title'), this.$t('no_certificate_message'), this.$store.getters.getLanguage)
      }
      reader.readAsBinaryString(file);
    },
    probeAccessPoint: function() {
      let url = process.env.VUE_APP_ROOT_API_WS + "/accesspoints/probe/";
      let args = { url: this.currentAccessPoint.url };
      if (this.currentAccessPoint.accesspoint_id) {
        args["accesspoint_id"] = this.currentAccessPoint.accesspoint_id;
      }
      this.$refs.taskViewer
        .run(url, args, this.handleFinalResult)
        .then(function(result) {});
    },
    handleFinalResult: function(result) {
      this.currentAccessPoint["accesspoint_id"] = result.accesspoint_id;
      this.currentAccessPoint["cert_subject"] = result.subject;
      this.currentAccessPoint["cert_issuer"] = result.issuer;
      this.currentAccessPoint["certificate_data"] = result.pem;
      this.$forceUpdate();
    },
    closeEdit: function() {
      if (this.callbackDone) {
        this.callbackDone();
      }
    },
    setFocus: function() {
      this.$refs.initialfocus.focus();
    }
  },
  watch: {
    showUploadCertificateDialog(val) {
      if (!val) return;
      //requestAnimationFrame(() => {
      //  this.$refs.selectfilefocus.focus();
      //});
    }
  },
  computed: {
    cannotProbeAccessPoint: function() {
      return (
        !this.currentAccessPoint.url ||
        this.currentAccessPoint["transport_type"] == "AS4"
      );
    }
  }
};
</script>

<style scoped lang="stylus">
.options
  font-size 14px
  padding 0px
  margin 0px
  background-color #f4f4f4

.cardheader
  width 100%
</style>
