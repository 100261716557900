<template>
  <div>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-card class="elevation-12">
              <v-card-title mb-0 pb-0 dark>
                  <center>
                    <div
                      style="background-color: white; border-radius: 10px;" class="mb-6"
                    >
                      <v-img src="../assets/npa_logo.png" alt="Logo Nederlandse Peppolautoriteit" />
                    </div>
                  </center>
              </v-card-title>
              <v-card-text>
                <div class="home">
                  <v-col class="text-center">
                  <h2>{{ $t("contact") }}</h2>
                  <br />
                  <p>{{ $t("contact_intro1") }}<br/>{{ $t("contact_intro2") }}</p>
                  <p>{{ $t("contact_intro3") }}</p>
                  <p><a href="mailto:operations@peppolautoriteit.nl">operations@peppolautoriteit.nl</a></p>
                  </v-col>
                </div>
                <br />
              </v-card-text>
            </v-card>
        </v-layout>
      </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  }
};
</script>
