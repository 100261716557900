<template>
  <div></div>
</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      password1: "",
      password2: ""
    };
  },
  props: {
    // token can be null, in which case we post to a different place
    token: null,
    uid: null
  },
  computed: {
    cresult: function() {
      if (this.result === "true" || this.result === true) {
        return true;
      } else if (this.result === "false" || this.result === false) {
        return false;
      } else {
        return null;
      }
    }
  },
  methods: {
    submitChangePassword() {
      alert("submit");
    }
  },
  mounted() {
    // alert("yo" + this.result)
  }
};
</script>
