<template>
  <!-- overview goes here -->
  <div>
    <v-card>
      <v-card-actions>
        <v-btn class="primary" small @click="loadData()"
          ><v-icon left>refresh</v-icon>{{ $t("reload") }}</v-btn
        >
        <v-btn
          v-if="existing_challenge"
          class="primary"
          small
          @click="viewExistingChallenge()"
          ><v-icon left>create</v-icon
          >{{ $t("sender_extended_test_view_challenge") }}</v-btn
        >
        <v-btn
          v-if="existing_challenge"
          class="primary"
          small
          @click="removeValidationChallenge()"
          ><v-icon left>delete</v-icon
          >{{ $t("sender_extended_test_remove_challenge") }}</v-btn
        >
        <v-btn v-else class="primary" small @click="createValidationChallenge()"
          ><v-icon left>create</v-icon
          >{{ $t("sender_extended_test_create_challenge") }}</v-btn
        >
      </v-card-actions>
      <v-card-text style="text-align: left;">
        <div v-if="accesspoint.onboarder_extended_requested">
          <p>{{ $t("extended_test_submitted_description") }}</p>
        </div>
        <div v-else style="width: 60%">
          <p>{{ $t("accesspoint_validation_description1") }}</p>
          <p v-html="$t('accesspoint_validation_description2')" />
          <p>{{ $t("accesspoint_validation_description3") }}</p>
          <p v-html="$t('accesspoint_validation_description4')" />
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="cardheader">
        <h1 class="text-h5">{{ $t("validated_identifiers") }}</h1>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="validated_identifiers_headers"
          :items="validated_identifiers"
          hideDefaultFooter
        >
          <template slot="item" slot-scope="props">
            <td class="text-left">{{ props.item.identifier }}</td>
            <td class="text-left">
              <v-btn
                :disabled="accesspoint.onboarder_extended_requested"
                class="primary"
                small
                @click="deleteValidatedIdentifier(props.item)"
              >
                <v-icon>delete</v-icon></v-btn
              >
            </td>
          </template>
        </v-data-table>
      </v-card-text>

      <BackendConnector ref="backend"></BackendConnector>
      <StandardErrorMessage ref="errMsg"></StandardErrorMessage>
      <StandardUserMessage ref="userMsg"></StandardUserMessage>
    </v-card>
  </div>
</template>

<script>
import StandardErrorMessage from "@/components/StandardErrorMessage.vue";
import StandardUserMessage from "@/components/StandardUserMessage.vue";

export default {
  name: "ExtendedTests",
  components: {
    StandardErrorMessage,
    StandardUserMessage
  },
  props: ["accesspoint"],
  data() {
    return {
      onboarder_extended_requested: false,
      tests: { sender_tests: [], receiver_tests: [] },
      existing_challenge: null,
      validated_identifiers: [],
      validated_identifiers_headers: [
        { text: this.$t("identifier"), value: "test_type" }
      ]
    };
  },
  methods: {
    loadData: function() {
      this.loadExistingChallenges();
      this.loadValidatedIdentifiers();
      //this.loadDocumentTypes()
    },
    loadExistingChallenges: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/validation_challenges/" +
        this.accesspoint.id;
      let self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        // just pick the first one if we have more than one
        self.existing_challenge = response.data[0];
      });
    },
    loadValidatedIdentifiers: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/validated_identifiers/" +
        this.accesspoint.id;
      let self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        self.validated_identifiers = response.data;
      });
    },
    createValidationChallenge: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/create_validation_challenge/";
      let args = { ap_id: this.accesspoint.id };
      let self = this;
      this.$refs.backend
        .post_auth(url, args)
        .then(function(response) {
          self.viewValidationChallenge(response.data);
          self.loadData();
        })
        .catch(function(error) {
          //alert("Error: " + JSON.stringify(error.response.data))
          // should have been handled by backend
        });
    },
    removeValidationChallenge: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/remove_validation_challenge/";
      let args = { ap_id: this.accesspoint.id };
      let self = this;
      this.$refs.backend
        .post_auth(url, args)
        .then(function(response) {
          self.loadData();
        })
        .catch(function(error) {
          //alert("Error: " + JSON.stringify(error.response.data))
          // should have been handled by backend
        });
    },
    deleteValidatedIdentifier: function(identifier) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/validated_identifiers/delete/" +
        identifier.id;
      let self = this;
      this.$refs.backend.delete_auth(url).then(function(response) {
        self.loadData();
      });
    },
    viewValidationChallenge: function(challenge) {
      let message_table = [
        [this.$t("recipient"), "0190:00000099003214345003"],
        [this.$t("code"), challenge.challenge_code]
      ];
      this.$refs.userMsg.doShowMessage(
        this.$t("production_validation_title"),
        this.$t("production_validation_message"),
        null,
        message_table
      );
    },
    viewExistingChallenge: function() {
      this.viewValidationChallenge(this.existing_challenge);
    }
  },
  mounted() {
    try {
      this.loadData();
    } catch (berr) {
      console.log(berr);
    }
  }
};
</script>

<style scoped lang="stylus">
.options
  font-size 14px
  padding 0px
  margin 0px
  background-color #f4f4f4

.cardheader
  width 100%
</style>
