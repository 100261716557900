<template>
  <v-dialog v-model="showError" width="500">
    <v-card>
      <v-card-title class="error darken-2 white--text"><h1 class="text-h5">{{
        errorTitle
      }}</h1></v-card-title>

      <v-card-text>
        <p :lang="language" v-if="asHTML" v-html="errorMessage"></p>
        <p :lang="language" v-else>{{ errorMessage }}</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="loginAgain"
          color="primary"
          text
          @click.native="goToLogin()"
        >
          {{ $t('login') }}
        </v-btn>
        <v-btn v-else color="primary" text @click="showError = false">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardErrorMessage",
  data() {
    return {
      errorTitle: String,
      errorMessage: String,
      loginAgain: false,
      showError: false,
      asHTML: false,
      language: this.$store.getters.getLanguage
    };
  },
  props: {},
  methods: {
    doShowError: function(title, message, language, isAuthFailure, isHTML) {
      if (language) {
        this.language = language
      }
      this.errorTitle = title;
      this.errorMessage = message;
      this.asHTML = isHTML;
      if (isAuthFailure) {
        this.loginAgain = true;
      }
      this.showError = true;
    },
    goToLogin: function() {
      this.$router.push("/login");
      this.$store.commit("LOGOUT");
    }
  }
};
</script>
