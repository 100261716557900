<template>
  <div>
    <v-tabs background-color="#01689b" dark show-arrows>
      <v-tab style="color: #ffffff;" @click="setFocusToTabContent()">Details</v-tab>
      <v-tab-item>
        <div v-if="haveAccessPointDetails">
          <v-card>
            <v-card-text>
              <v-text-field
                :label="$t('name')"
                v-model="accessPointDetails.name"
                readonly
              ></v-text-field>
              <v-text-field
                :label="$t('description')"
                v-model="accessPointDetails.description"
                readonly
              ></v-text-field>
              <v-text-field
                :label="$t('as2_url')"
                v-model="accessPointDetails.url"
                readonly
              ></v-text-field>
              <v-text-field
                :label="$t('accesspoint_transport_type')"
                v-model="accessPointDetails.transport_type"
                readonly
              ></v-text-field>

              <v-text-field
                :label="$t('as2_id')"
                v-model="accessPointDetails.accesspoint_id"
                readonly
              ></v-text-field>
              <v-text-field
                :label="$t('certificate_type')"
                v-model="accessPointDetails.pki_instance"
                readonly
              ></v-text-field>
              <div style="text-align: left">
                <p>
                  Certificate subject: {{ accessPointDetails.cert_subject }}
                </p>
                <p>Certificate issuer: {{ accessPointDetails.cert_issuer }}</p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-row class="ml-2">
                <v-btn class="primary mr-2 mb-2" small @click="editAccessPoint()"
                  ><v-icon left>edit</v-icon>{{ $t("edit") }}</v-btn
                >
                <v-btn class="primary" small @click="validateAsSender()"
                  ><v-icon left>edit</v-icon>{{ $t("validate_as_sender") }}</v-btn
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </div>
      </v-tab-item>
      <v-tab style="color: #ffffff;" @click="setFocusToTabContent()">{{ $t("send_documents") }}</v-tab>
      <v-tab-item>
        <SendDocument
          v-bind:accesspoint="$route.params.ap_id"
          v-bind:successCallback="fetchDocumentTestResults"
        />
        <v-card>
          <v-card-title class="cardheader">
            <div>
              <h1 class="text-h5">{{ $t("sent_documents") }}</h1>
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              mobile-breakpoint="970"
              :options.sync="pagination"
              :serverItemsLength="sendDocumentTestResultsCount"
              :items="sendDocumentTestResults"
              :headers="sendDocumentTestResultHeaders"
              :footer-props="{
                itemsPerPageOptions:[20]
              }"
              must-sort
              hide-default-footer
            >
              <template v-slot:item.creation_date="{ item }" >
                {{ item.creation_date | formatDateTime }}
              </template>
              <template v-slot:item.filename="{ item }">
                <div style="word-break: break-all;">
                {{ item.filename }}
                </div>
              </template>
              <template v-slot:item.recipient_id="{ item }">
                <div>
                {{ item.recipient_id }}
                </div>
              </template>
              <template v-slot:item.document_sent="{ item }">
                <ResultIcon
                  v-bind:result="item.document_sent"
                ></ResultIcon>
              </template>
              <template v-slot:item.response_signature_valid="{ item }">
                <ResultIcon
                  v-bind:result="item.response_signature_valid"
                ></ResultIcon>
              </template>
              <template v-slot:item.document_delivered="{ item }">
                <ResultIcon
                  v-bind:result="item.document_delivered"
                ></ResultIcon>
              </template>
              <template v-slot:item.mlr="{ item }" @click="doShowMLRDialog(props.item)" style="cursor: pointer;">
                <v-layout wrap>
                <StatusIcon
                  v-bind:status="mlrResultIcon(item)"
                  :tooltip="mlrTooltip(item)"
                ></StatusIcon>
                <StatusIcon
                  v-if="item.mlr"
                  v-bind:status="mlrValidIcon(item)"
                  :tooltip="mlrTooltip(item)"
                ></StatusIcon>
                </v-layout>
              </template>
              <template v-slot:item.view_log="{ item }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          fab
                          small
                          class="secondary"
                          v-on="on"
                          color="secondary"
                          dark
                        >
                          <v-icon>search</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="showSendDocumentTestSendLog(item)"
                        >
                          <v-list-item-title>{{
                            $t("system_log")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="item.request"
                          @click="showSendDocumentTestRequest(item)"
                        >
                          <v-list-item-title>{{
                            $t("as2_request")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="item.response"
                          @click="showSendDocumentTestResponse(item)"
                        >
                          <v-list-item-title>{{
                            $t("as2_response")
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn
                  fab
                  small
                  class="secondary"
                  :disabled="item.disabled"
                  @click.native="
                    deleteSendDocumentTestResult(item)
                  "
                  ><v-icon>delete</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab style="color: #ffffff;" id="lastTab" @click="setFocusToTabContent()">{{ $t("extended_tests") }}</v-tab>
      <v-tab-item>
        <ExtendedTests
          v-if="haveAccessPointDetails"
          v-bind:accesspoint="accessPointDetails"
        ></ExtendedTests>
      </v-tab-item>

      <v-tab v-if="isProductionAccessPoint">{{
        $t("production_ap_validation")
      }}</v-tab>
      <v-tab-item v-if="isProductionAccessPoint">
        <ProductionAPValidation
          v-if="haveAccessPointDetails"
          v-bind:accesspoint="accessPointDetails"
        ></ProductionAPValidation>
      </v-tab-item>
    </v-tabs>

    <v-dialog width="80%" v-model="showAccessPointEditDialog">
      <AccessPointEdit
        title="Edit access point"
        v-bind:accesspoint="$route.params.ap_id"
        v-bind:callbackDone="editDone"
        ref="accesspointEdit"
      />
    </v-dialog>

    <v-dialog v-model="showMLRDialog">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn
              icon
              color="primary"
              @click.stop="showMLRDialog = false"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <ValidationResults ref="mlrValidationResults" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <BackendConnector ref="backend"></BackendConnector>
    <StandardUserConfirmation ref="userConfirmation"></StandardUserConfirmation>
    <TextViewer ref="textViewer"></TextViewer>
    <TestTaskViewer
      v-on:error="testTaskDone()"
      ref="taskViewer"
    ></TestTaskViewer>
  </div>
</template>

<script>
// @ is an alias to /src
import ResultIcon from "@/components/ResultIcon.vue";
import StatusIcon from "@/components/StatusIcon.vue";
import TestTaskViewer from "@/components/TestTaskViewer.vue";
import TextViewer from "@/components/TextViewer.vue";
import SendDocument from "@/components/SendDocument.vue";
import StandardUserConfirmation from "@/components/StandardUserConfirmation.vue";
import AccessPointEdit from "@/components/AccessPointEdit.vue";
import ExtendedTests from "@/components/ExtendedTests.vue";
import ProductionAPValidation from "@/components/ProductionAPValidation";
import ValidationResults from "@/components/ValidationResults.vue";

export default {
  name: "home",
  components: {
    ExtendedTests,
    AccessPointEdit,
    ProductionAPValidation,
    TestTaskViewer,
    ResultIcon,
    StatusIcon,
    SendDocument,
    StandardUserConfirmation,
    TextViewer,
    ValidationResults
  },
  data() {
    return {
      accessPointDetails: null,
      haveAccessPointDetails: false,
      showActionDialog: false,

      showAccessPointEditDialog: false,

      sendDeriveRecipientFromDocument: true,
      overrideRecipient: "",
      sendSelectedFile: "",

      pagination: {
        sortBy: ["creation_date"],
        descending: true,
        rowsPerPage: 10,
        page: 1
      },
      sendDocumentTestResults: [],
      sendDocumentTestResultsCount: 0,
      sendDocumentTestResultHeaders: [
        { text: this.$t("date"), value: "creation_date" },
        { text: this.$t("filename"), value: "filename" },
        { text: this.$t("recipient_id"), value: "recipient_id" },
        { text: this.$t("sent"), value: "document_sent" },
        { text: this.$t("response"), value: "response_signature_valid" },
        { text: this.$t("accepted"), value: "document_delivered" },
        { text: this.$t("mlr"), value: "mlr" },
        { text: this.$t("log"), value: "view_log", sortable: false },
        { text: this.$t("delete"), value: "delete", sortable: false }
      ],
      task_urls: {
        basic_check:
          process.env.VUE_APP_ROOT_API_WS +
          "/accesspoints/basic_checks/" +
          this.$route.params.ap_id,
        send_document:
          process.env.VUE_APP_ROOT_API_WS +
          "/accesspoints/send_document/" +
          this.$route.params.ap_id,
        receiver_test:
          process.env.VUE_APP_ROOT_API_WS +
          "/accesspoints/receiver_test/" +
          this.$route.params.ap_id
      },
      isProductionAccessPoint: false,
      showMLRDialog: false
    };
  },
  computed: {
    sendHaveSelectedFile: function() {
      return this.sendSelectedFile !== "";
    }
  },
  methods: {
    loadData: function() {
      this.fetchAccessPointDetails();
      this.fetchDocumentTestResults();
    },
    fetchAccessPointDetails: function() {
      var url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/details/" +
        this.$route.params.ap_id +
        "/";
      var self = this;
      this.$refs.backend
        .get(url, {
          headers: { Authorization: "JWT " + this.getJWT() }
        })
        .then((response) => {
          document.title = this.$t("accesspoint") + " " + response.data.name;
          self.accessPointDetails = response.data;
          self.haveAccessPointDetails = true;
          if (self.accessPointDetails.certificate_type == "PROD") {
            self.isProductionAccessPoint = true;
          }

          // }).catch(function (error) {
          // handled by backendconnector
        });
    },
    unloadData: function() {
      // TODO
      this.accessPointDetails = null;
      this.haveAccessPointDetails = false;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    },
    editAccessPoint: function() {
      this.showAccessPointEditDialog = true;
      this.$nextTick(() => {
      this.$refs.accesspointEdit.currentAccessPoint = this.accessPointDetails;
      this.$refs.accesspointEdit.setFocus();
      })
    },
    editDone: function() {
      this.showAccessPointEditDialog = false;
      this.loadData();
    },
    performBasicChecks: function() {
      // this.showActionDialog = true
      let self = this;
      this.$refs.taskViewer
        .run(this.task_urls.basic_check)
        .then(function(result) {
          self.fetchAccessPointDetails();
        })
        .catch(function(error) {
          alert("error! " + error);
        });
    },
    validateAsSender: function() {
      alert(
        "Not implemented yet: The idea here is that the Test Tool will come up with some requirement of a document that the user should put in a document to send to the test tool, thereby confirming this is not someone else's access point"
      );
    },
    performReceiverTest: function() {
      let self = this;
      this.$refs.taskViewer
        .run(this.task_urls.receiver_test)
        .then(function(result) {
          self.fetchAccessPointDetails();
        })
        .catch(function(error) {
          alert("error! " + error);
        });
    },
    testTaskDone: function() {
      this.unloadData();
      this.loadData();
      this.showActionDialog = false;
    },
    fetchDocumentTestResults: function() {
      var url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/details/" +
        this.$route.params.ap_id +
        "/document_send_results/" +
        "?page=" +
        this.pagination.page +
        "&page_size=" +
        this.pagination.rowsPerPage;
      let order_by = "creation_date";
      let direction = "desc";
      if (this.pagination.sortBy) {
        order_by = this.pagination.sortBy;
        if (!this.pagination.descending) {
          direction = "asc";
        }
      }
      url += "&order_by=" + order_by + "&direction=" + direction;

      var self = this;
      this.$refs.backend
        .get_auth(url)
        .then(function(response) {
          self.sendDocumentTestResultsCount = response.data.count;
          self.sendDocumentTestResults = response.data.results;
        })
        .catch(function() {
          self.sendDocumentTestResults = [];
        });
    },
    deleteSendDocumentTestResult: function(item) {
      item.disabled = true;
      let callbackConfirm = (arg) => {
        let index = this.sendDocumentTestResults.indexOf(item);
        this.$set(this.sendDocumentTestResults, index, item);
        let url =
          process.env.VUE_APP_ROOT_API +
          "/accesspoints/details/" +
          item.access_point +
          "/document_send_results/" +
          item.id +
          "/";
        this.$refs.backend
          .delete_auth(url)
          .then((response) => {
            this.fetchDocumentTestResults();
          })
          .catch((error) => {
            // handled by BackendConnector, but we should enable the button again
            item.disabled = false;
            this.$set(self.sendDocumentTestResults, index, item);
          });
      }
      let callbackCancel = () => {
        item.disabled = false;
      }
      this.$refs.userConfirmation.doShowUserConfirmation(
        this.$t('delete_document_confirm_title'),
        this.$t('delete_document_confirm_message'),
        callbackConfirm,
        callbackCancel,
        this
      );
/*
      item.disabled = true;
      let index = this.sendDocumentTestResults.indexOf(item);
      this.$set(this.sendDocumentTestResults, index, item);
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/details/" +
        this.$route.params.ap_id +
        "/document_send_results/" +
        item.id +
        "/";
      let self = this;
      this.$refs.backend
        .delete_auth(url)
        .then(function(response) {
          self.fetchDocumentTestResults();
        })
        .catch(function(error) {
          // handled by BackendConnector, but we should enable the button again
          item.disabled = false;
          self.$set(self.sendDocumentTestResults, index, item);
        });
*/
    },
    showSendDocumentTestSendLog: function(item) {
      this.$refs.textViewer.doShowText("System Log", item.send_log);
    },
    showSendDocumentTestRequest: function(item) {
      this.$refs.textViewer.doShowText("AS2 request", item.request);
    },
    showSendDocumentTestResponse: function(item) {
      this.$refs.textViewer.doShowText("AS2 response", item.response);
    },
    mlrResultIcon(item) {
      if (item.mlr_state) {
        if (item.mlr_state == "accepted") {
            return "success";
        } else if (item.mlr_state == "acknowledged") {
            return "warning";
        } else if (item.mlr_state == "rejected") {
            return "error";
        } else {
            console.error("Unknown status in MLR: " + item.mlr_state + ", assuming not_applicable");
            return "not_applicable";
        }
      } else {
        return "not_applicable";
      }
    },
    mlrValidIcon(item) {
      let data = JSON.parse(item.mlr_validation_summary);
      if (data.error_count > 0) {
        return "error";
      } else if (data.warning_count > 0) {
        return "warning";
      } else {
        return "success";
      }
    },
    mlrResultString(mlr_state) {
      if (mlr_state == 'accepted') {
        return this.$t('mlr_accepted');
      } else if (mlr_state == 'acknowledged') {
        return this.$t('mlr_acknowledged');
      } else if (mlr_state == 'rejected') {
        return this.$t('mlr_rejected');
      } else {
        return mlr_state;
      }
    },
    mlrValidString(mlr_validation_summary) {
      let data = JSON.parse(mlr_validation_summary);
      if (data.error_count > 0) {
        return this.$t('mlr_has_errors');
      } else if (data.warning_count > 0) {
        return this.$t('mlr_has_warnings');
      } else {
        return this.$t('mlr_validated');
      }
    },
    mlrTooltip(item) {
      if (item.mlr_state) {
        return this.mlrResultString(item.mlr_state) + ". " + this.mlrValidString(item.mlr_validation_summary) + ".";
      } else {
        return this.$t('mlr_not_received')
      }
    },
    doShowMLRDialog(item) {
        if (item.mlr_state) {
            this.$refs.mlrValidationResults.setIntroductionText(this.mlrResultString(item.mlr_state));
            this.$refs.mlrValidationResults.setIntroductionColor(this.mlrResultIcon(item));
            this.$refs.mlrValidationResults.setData(
                JSON.parse(item.mlr_validation_summary),
                item.mlr
            );
            this.showMLRDialog = true;
        }
    },
	setFocusToTabContent() {
		this.$nextTick(() => {
			let lastTabEl = document.getElementById("lastTab");
			lastTabEl.focus();
			lastTabEl.blur();
		});
	}
  },
  mounted() {
    this.$store.dispatch("inspectToken");
    try {
      this.loadData();
    } catch (berr) {
      alert(
        "[XX] error in loadData for TestAccessPointsDetails.vue (mounted): " +
          berr
      );
    }
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "updateToken":
          this.unloadData();
          try {
            // TODO: maybe not reload on login but redirect?
            if (this.$refs && this.$refs.backend) {
              this.$nextTick(this.loadData);
            }
          } catch (berr) {
            alert(
              "[XX] error in loadData for TestAccessPointDetails.vue (sub updateToken): " +
                berr
            );
          }
          break;
        case "LOGOUT":
          this.unloadData();
          break;
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
    if (this.$route.query.start) {
      this.performBasicChecks();
    }
  },
  watch: {
    pagination: {
      handler(newData, oldData) {
        this.loadData();
      }
    }
  }
};
</script>
