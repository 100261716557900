<template>
  <div>
      <v-card height="100%">
        <iframe
          style="width:100%; height:100%; border: 0px;"
          :src="iflink"
          :key="'iflink-iframe'"
        ></iframe>
      </v-card>
  </div>
</template>

<script>

export default {
  name: "about",
  components: {
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  },
  computed: {
    iflink: function() {
      return process.env.VUE_APP_DOC_URL + this.$i18n.locale + "/";
    }
  }
};
</script>
