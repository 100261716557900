export default {
  dataIterator: {
    rowsPerPageText: "Elementen per pagina:",
    rowsPerPageAll: "Alles",
    pageText: "{0}-{1} van {2}",
    noResultsText: "Geen overeenkomende elementen gevonden",
    nextPage: "Volgende pagina",
    prevPage: "Vorige pagina",
  },
  dataTable: {
    sortBy: "Sorteren op",
    rowsPerPageText: "Rijen per pagina:",
    itemsPerPageText: "Elementen per pagina:",
    ariaLabel: {
        sortNone: "Niet sorteren",
        activateAscending: "Activeer om oplopend te sorteren",
        sortAscending: "Oplopend sorteren",
        activateDescending: "Activeer om aflopend te sorteren",
        sortDescending: "Aflopend sorteren",
    }
  },
  dataFooter: {
    pageText: "Pagina",
    prevPage: "Vorige pagina",
    nextPage: "Volgende pagina",
  },
  noDataText: "Geen data beschikbaar"
};
