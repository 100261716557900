<template>
  <div>
    <v-tooltip v-if="tooltip" bottom>
      <v-icon slot="activator" v-if="cresult == true" color="success" :title="$t('status_icon_success')"
        >check_circle</v-icon
      >
      <v-icon slot="activator" v-else-if="cresult == false" color="error" :title="$t('status_icon_error')"
        >error</v-icon
      >
      <v-icon slot="activator" v-else :title="$t('status_icon_not_applicable')">not_interested</v-icon>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <div v-else>
      <v-icon slot="activator" v-if="cresult == true" color="success" :title="$t('status_icon_success')"
        >check_circle</v-icon
      >
      <v-icon slot="activator" v-else-if="cresult == false" color="error" :title="$t('status_icon_error')"
        >error</v-icon
      >
      <v-icon slot="activator" v-else :title="$t('status_icon_not_applicable')">not_interested</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultIcon",
  data() {
    return {};
  },
  props: {
    // can be true, false, or null
    result: null,
    tooltip: String
  },
  computed: {
    cresult: function() {
      if (this.result === "true" || this.result === true) {
        return true;
      } else if (this.result === "false" || this.result === false) {
        return false;
      } else {
        return null;
      }
    }
  },
  methods: {},
  mounted() {
    // alert("yo" + this.result)
  }
};
</script>
