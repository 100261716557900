<template>
  <v-dialog v-model="showMessage" width="500">
    <v-card>
      <v-card-title class="darken-2"><h1 class="text-h5">{{ messageTitle }}</h1></v-card-title>

      <v-card-text>
        <div v-show="messageMessage">
          {{ messageMessage }}
        </div>
        <div v-show="messageList">
          <p v-for="message in messageList" v-bind:key="message">
            {{ message }}
          </p>
        </div>
        <div v-if="messageTable">
          <v-data-table
            :items="messageTable"
            hideDefaultHeader
            hideDefaultFooter
            showExpand
            class="elevation-1"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td v-for="el in props.item" v-bind:key="el" class="text-xs">
                  {{ el }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showMessage = false">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardMessageMessage",
  data() {
    return {
      messageTitle: "not set",
      messageMessage: null,
      messageList: null,
      messageTable: null,
      showMessage: false
    };
  },
  props: {},
  methods: {
    // There are three elements that can be passed to the message;
    // message: a simple (one-line) message
    // message_list: a list of messages (in separate paragraphs)
    // message_table: A list of lists, shown as a table
    // They are rendered (if not null) in order
    doShowMessage: function(title, message, message_list, message_table) {
      this.messageTitle = title;
      if (message) {
        this.messageMessage = message;
      }
      if (message_list) {
        this.messageList = message_list;
      }
      if (message_table) {
        this.messageTable = message_table;
      }
      this.showMessage = true;
    }
  }
};
</script>
