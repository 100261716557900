<template>
  <div>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-card-text>
                <div v-if="registrationSent">
                  {{ $t("email_sent") }}
                </div>
                <div v-else-if="sendingRegistration">
                  <v-progress-circular
                    color="blue-grey"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <v-form
                  v-else
                  ref="form"
                  v-model="valid"
                  v-on:submit.prevent="submitRegistration"
                >
                  <v-alert :value="true" type="info" :close-label="$t('button_close')">
                    <p
                      class="text-center"
                      style="color: #000000;"
                      v-html="$t('register_only_for_service_providers')"
                    />
                  </v-alert>
                  <v-alert :value="true" type="info" :close-label="$t('button_close')">
                    <p
                      class="text-center"
                      style="color: #000000;"
                      v-html="$t('password_requirements')"
                    />
                  </v-alert>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :error-messages="emailErrors"
                    :label="$t('email')"
                    @change="validateEmail"
                    required
                    autocomplete="username"
                  ></v-text-field>
                  <v-text-field
                    v-model="password1"
                    :type="'password'"
                    :rules="password1Rules"
                    :error-messages="password1Errors"
                    :label="$t('password')"
                    @change="validatePassword1"
                    required
                    autocomplete="new-password"
                  ></v-text-field>
                  <v-text-field
                    ref="password2"
                    v-model="password2"
                    :type="'password'"
                    :rules="password2Rules"
                    :error-messages="password2Errors"
                    :label="$t('password_verification')"
                    @change="validateAll"
                    required
                    autocomplete="new-password"
                  ></v-text-field>
                  <v-text-field
                    v-model="organization"
                    :rules="organizationRules"
                    :error-messages="organizationErrors"
                    :label="$t('organization')"
                    required
                    autocomplete="organization"
                  ></v-text-field>
                  <v-checkbox
                    v-model="access_requested"
                    :label="$t('request_simplerinvoicing_access')"
                  />

                  <v-btn class="primary" type="submit" :disabled="!valid">
                    {{ $t("register") }}
                  </v-btn>
                  <v-btn class="primary" @click="clear">{{
                    $t("clear")
                  }}</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    <BackendConnector ref="backend"></BackendConnector>
  </div>
</template>

<script>

export default {
  name: "home",
  components: {
  },
  data() {
    let self = this;
    return {
      sendingRegistration: false,
      registrationSent: false,
      valid: false,
      email: "",
      emailRules: [
        v => !!v || this.$t("email_required"),
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("email_valid")
      ],
      emailErrors: [],
      organization: "",
      organizationRules: [v => !!v || this.$t("organization_required")],
      organizationErrors: [],
      access_requested: false,
      password1: "",
      password1Rules: [
        v => !!v || this.$t("password_required"),
        v => v.length >= 8 || this.$t("password_at_least", ["8"])
      ],
      password1Errors: [],
      password2: "",
      password2Rules: [v => !!v || this.$t("password_required")],
      password2Errors: []
    };
  },
  methods: {
    submitRegistration() {
      if (this.$refs.form.validate()) {
        this.sendingRegistration = true;
        let url = process.env.VUE_APP_ROOT_API + "/register/";
        let self = this;
        this.$refs.backend
          .post(url, {
            email: this.email,
            password1: this.password1,
            password2: this.password2,
            organization: this.organization,
            access_requested: this.access_requested
          })
          .then(function(response) {
            if (response.status && response.status === 201) {
              self.sendingRegistration = false;
              self.registrationSent = true;
            } else {
              //alert(JSON.stringify(response))
            }
          })
          .catch(function(error) {
            self.sendingRegistration = false;
            if (error.response.data) {
              if (error.response.data.password1) {
                self.password1Errors = error.response.data.password1;
                self.valid = false;
              }
              if (error.response.data.email) {
                self.emailErrors = error.response.data.email;
                self.valid = false;
              }
              if (error.response.data.organization) {
                self.organizationErrors = error.response.data.organization;
                self.valid = false;
              }
            } else {
              alert(JSON.stringify(error.response));
            }
          });
      } else {
        alert("form not valid");
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    checkPasswords() {
      if (this.password1 === this.password2) {
        this.password2Errors = [];
        this.valid = true;
        return true;
      } else {
        this.password2Errors = [this.$t("passwords_dont_match")];
        this.valid = false;
        return false;
      }
    },
    validateAll() {
      this.valid = this.checkPasswords() && this.$refs.form.validate();
      return this.valid;
    },
    validateEmail() {
      this.emailErrors = [];
      //return this.validateAll()
    },
    validatePassword1() {
      this.password1Errors = [];
      //return this.validateAll()
    }
  },
  watch: {
    password2(val) {
      this.validateAll();
    }
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  }
};
</script>
