<template>
  <v-app>
    <v-app-bar app dark dense clipped-left height="32" class="primary hidden-md-and-down" style="position: fixed;">

      <v-app-bar-title class="ml-0 pl-3">
        <div>{{ $t("title") }}</div>
      </v-app-bar-title>

      <v-spacer>
        <v-container class="ma-0 pa-0 d-flex justify-center">
          <a class="d-sr-only-focusable" style="color:white;" href="#maincontent">{{ $t('skip_to_main') }}</a>
        </v-container>
      </v-spacer>

      <div class="mr-4" v-if="isLoggedIn">
        <v-container>
          <v-row>
            <div class="mr-4">
            {{ $t("organisation") }}: {{ affiliation }}
            </div>
            <div>
            {{ $t("smk_address") }}: {{ smk_address }}
            </div>
          </v-row>
        </v-container>
      </div>

      <div v-if="isLoggedIn">
        <v-menu ref="sessionMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                 id="sessionMenuButton"
                 v-on:keydown.tab="menuTabPressed"
                 v-on:keydown.native.enter="menuEnterPressed"
                 v-on:click="on.click; toggleSessionMenu();"
                 v-on:keydown.up="on.keydown"
                 v-on:keydown.down="on.keydown"
                 data-menu="sessionMenu"
                 outlined
                 v-bind="attrs"
                 aria-controls="session-menu"
             >
              {{ $t("user") }}: {{ email }}
            </v-btn>
          </template>
          <v-list id="session-menu">
            <v-list-item to="/account">
              <v-list-item-title class="text-left" tabindex="0">
                {{ $t("account_settings") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout" tabindex="0">
              <v-list-item-title>
                {{ $t("logout") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div v-else>
        <v-btn @click="showLogin" outlined id="sessionMenuButton">
          {{ $t("login") }}
        </v-btn>
        <v-btn to="/register" outlined>
          {{ $t("register") }}
        </v-btn>
      </div>

      <v-menu offset-y ref="languageMenu" eager>
        <template v-slot:activator="{ on }">
          <v-btn id="languageButton"
                 v-on:keydown.tab="menuTabPressed"
                 v-on:keydown.native.enter="menuEnterPressed"
                 v-on:click="on.click; toggleLanguageMenu();"
                 data-menu="languageMenu"
                 outlined
                 aria-expanded="false"
                 aria-controls="language-menu">
            {{ $t("language") }}
          </v-btn>
        </template>
        <v-list id="language-menu">
          <v-list-item @click="changeLocale('en')">
            <v-list-item-title
              ><flag iso="gb" :squared="false" /> English</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="changeLocale('nl')">
            <v-list-item-title
              ><flag iso="nl" :squared="false" /> Nederlands</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-app-bar app dark dense height="32" class="primary hidden-lg-and-up hidden-xs-only" style="position: fixed;">
      <v-app-bar-nav-icon id="navIconMed" @click.stop="toggleNavigationDrawer" :title="$t('toggle_navigation')"></v-app-bar-nav-icon>

      <v-app-bar-title class="ml-0 pl-3">
        <div>{{ $t("title") }}</div>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y ref="sessionMenuSmall">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            :title="$t('user')"
            id="sessionButtonSmall"

            v-on:keydown.tab="menuTabPressed"
            v-on:click="on.click; toggleSessionMenuSmall();"
            v-on:keydown.native.enter="menuEnterPressed"
            v-on:keydown.up="on.keydown"
            v-on:keydown.down="on.keydown"
            data-menu="sessionMenuSmall"
            aria-controls="session-menu-small"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list id="session-menu-small" v-if="isLoggedIn">
          <v-list-item>
            <v-list-item-title class="text-left">
              {{ $t("user") }}: {{ email }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-left">
              {{ $t("organisation") }}: {{ affiliation }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-left">
              {{ $t("smk_address") }}: {{ smk_address }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/account" tabindex="0">
            <v-list-item-title>
              <v-btn>{{ $t("account_settings") }}</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item @click="logout">
              <v-list-item-title>
                <v-btn>{{ $t("logout") }}</v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list-item>
        </v-list>
        <v-list v-else id="session-menu-small">
          <v-list-item @click="showLogin">
            <v-list-item-title>
              {{ $t("login") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/register">
            <v-list-item-title>
              {{ $t("register") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y ref="languageMenuSmall">
        <template v-slot:activator="{ on, attrs }">
          <v-btn id="languageButtonSmall"
                 v-on:keydown.tab="menuTabPressed"
                 v-on:keydown.native.enter="menuEnterPressed"
                 v-on:click="on.click; toggleLanguageMenuSmall();"
                 data-menu="languageMenuSmall"
                 aria-controls="language-menu-small"
                 :title="$t('language')"
                 icon>
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>

        <v-list id="language-menu-small">
          <v-list-item @click="changeLocale('en')">
            <v-list-item-title
              ><flag iso="gb" :squared="false" /> English</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="changeLocale('nl')">
            <v-list-item-title
              ><flag iso="nl" :squared="false" /> Nederlands</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <div class="hidden-sm-and-up"  >
      <router-link to="/" style="text-decoration: none;" class="text-center">
        <h4>{{ $t('title') }}</h4>
      </router-link>
      <v-app-bar app dark dense clipped-left height="32" class="primary" style="position: relative;">
      <v-app-bar-nav-icon id="navIconSml" @click.stop="toggleNavigationDrawer" :title="$t('toggle_navigation')"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-menu offset-y ref="sessionMenuTiny">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            :title="$t('user')"
            id="sessionButtonTiny"

            v-on:keydown.tab="menuTabPressed"
            v-on:keydown.native.enter="menuEnterPressed"
            v-on:click="on.click; toggleSessionMenuTiny();"
            v-on:keydown.up="on.keydown"
            v-on:keydown.down="on.keydown"
            data-menu="sessionMenuTiny"
            aria-controls="session-menu-tiny"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list id="session-menu-tiny" v-if="isLoggedIn">
          <v-list-item class="mb-0 pb-0">
            <v-list-item-title class="text-left mb-0 pt-0">
              {{ $t("user") }}:
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mt-0 pt-0">
            <v-list-item-title class="text-left mt-0 pt-0">
              {{ email }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-left">
              {{ $t("organisation") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-left">
              {{ affiliation }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-left">
              {{ $t("smk_address") }}:
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-left">
              {{ smk_address }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/account">
            <v-list-item-title class="text-left">
              <v-btn>{{ $t("account_settings") }}</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title class="text-left">
              <v-btn>{{ $t("logout") }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else id="session-menu-tiny">
          <v-list-item @click="showLogin">
            <v-list-item-title>
              {{ $t("login") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/register">
            <v-list-item-title>
              {{ $t("register") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y ref="languageMenuTiny">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on:keydown.tab="menuTabPressed"
                 v-on:click="on.click; toggleLanguageMenuTiny();"
                 v-on:keydown.native.enter="menuEnterPressed"
                 data-menu="languageMenuTiny"
				 aria-controls="language-menu-tiny"
                 :title="$t('language')"
                 icon>
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>

        <v-list id="language-menu-tiny">
          <v-list-item @click="changeLocale('en')">
            <v-list-item-title
              ><flag iso="gb" :squared="false" /> English</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="changeLocale('nl')">
            <v-list-item-title
              ><flag iso="nl" :squared="false" /> Nederlands</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      </v-app-bar>
    </div>

    <v-navigation-drawer
      v-model="drawer"
      dense
      clipped
      app
      class="text-left"
      style="top: 32px; padding-bottom: 32px;"
      ref="navigationDrawer"
    >
      <v-list dense class="pt-0" ref="navigationDrawerList">

        <v-list-item ref="navigationDrawerListFirst">
          <v-img
            src="./assets/npa_logo.png"
            :alt="$t('logo_description')"
            width="200px"
          />
        </v-list-item>

        <v-list-item dense to="/" ripple class="navMenu" id="navMenuFirst">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("home") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/about" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>info</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("about") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/contact" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>mdi-card-account-mail</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("contact") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <v-divider />

        <v-list-item to="/discover" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>people</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("discover") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/validate" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>content_copy</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("validate") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/sendtest" ripple class="navMenu">
            <v-list-item-action>
                <v-icon>move_to_inbox</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{ $t('sendtest_title') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item to="/applicationtest" ripple class="navMenu">
            <v-list-item-action>
                <v-icon>connect_without_contact</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{ $t('connectioncoach_title') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider />

        <div v-if="isLoggedIn">
          <div v-if="isParticipant">
            <v-list-item to="/test_access_points" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>business</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("test_access_points")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/received_documents" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>move_to_inbox</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("received_documents")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/sent_documents" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>next_week</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("sent_documents")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />
          </div>
          <div v-else>
            <v-divider />

            <v-list-item @click="showNoAccessAlert()" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>business</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="color: #999999">
                  {{ $t("test_access_points") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="showNoAccessAlert()" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>move_to_inbox</v-icon>
              </v-list-item-action>
              <v-list-item-content style="color: #999999">
                <v-list-item-title style="word-break: break-word;">{{
                  $t("received_documents")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="showNoAccessAlert()" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>next_week</v-icon>
              </v-list-item-action>
              <v-list-item-content style="color: #999999">
                <v-list-item-title>{{
                  $t("sent_documents")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />
          </div>

          <div v-if="isOnboarder">
            <v-list-item to="participant_approval" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>contacts</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("participant_approval")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="test_result_approval" ripple class="navMenu">
              <v-list-item-action>
                <v-icon>business</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("test_result_approval")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />
          </div>
        </div>

        <v-list-item :href="documentationLink" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>help</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("documentation") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/sitemap" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>mdi-sitemap</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title lang="en">{{ $t("sitemap") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :href="privacyLink" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>list</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title lang="en">{{ $t("privacy") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item id="navMenuLast" :href="changelogLink" ripple class="navMenu">
          <v-list-item-action>
            <v-icon>change_history</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("changelog") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-main id="maincontent" class="fill-height">
      <router-view />
    </v-main>

    <v-dialog v-model="showLoginDialog" ref="showLoginDialog" max-width="420px" aria-labelledby="loginButton">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn icon color="primary" @click.stop="showLoginDialog = false" :title="$t('button_close')">
                <v-icon>close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <div aria-live="assertive">
            <v-alert v-if="loginErrorMessage" color="error" icon="warning" style="color: #ffffff;">
              {{ loginErrorMessage }}
            </v-alert>
          </div>
          <div v-if="isLoggedIn">
            <v-alert dark color="success" :value="loginErrorMessage">
              {{ $t('already_logged_in') }}
            </v-alert>
            <v-btn class="primary" @click="logout">{{ $t("logout") }}</v-btn>
          </div>
          <div v-else-if="isLoginPending">
            <v-progress-circular
              color="blue-grey"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-form
              ref="loginForm"
              v-model="loginFieldsValid"
              v-on:submit.prevent="submitLogin"
            >
              <v-text-field
                v-model="loginFormEmail"
                :label="$t('email')"
                :rules="emailRules"
                required
                autocomplete="username"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :label="$t('password')"
                :type="'password'"
                :rules="passwordRules"
                required
                autocomplete="current-password"
              ></v-text-field>
              <v-label id="2fa" aria-hidden="false">{{ $t('totp_if_set') }}</v-label>
              <legend class="d-sr-only">{{ $t('automatically_jump_to_next_field') }}</legend>
              <v-otp-input
                ref="otpInput"
                v-model="totp"
                length="6"
                type="number"
                class="mb-6"
                required
                aria-labelledby="2fa"
                @input="checkOTP"
              ></v-otp-input>
              <v-row>
                <v-btn
                  id="loginButton"
                  ref="loginButton"
                  type="submit"
                  class="primary mr-2 mb-2"
                  :disabled="!loginFieldsValid"
                  >{{ $t("login") }}</v-btn
                >
                <v-btn class="primary" @click="forgotPassword">
                  {{ $t("forgot_password") }}
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="showResetPasswordDialog" ref="showResetPasswordDialog" width="35%" area-labelledby="resetPasswordButton">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn
              icon
              color="primary"
              @click.stop="showResetPasswordDialog = false"
              :title="$t('button_close')"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <div v-if="resetPasswordSent">
            {{ $t("reset_password_sent") }}
          </div>
          <div v-else-if="resetPasswordPending">
            <v-progress-circular
              color="blue-grey"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <div aria-live="assertive">
            <v-alert
              color="error"
              :value="resetPasswordErrorMessage"
              v-if="resetPasswordErrorMessage"
              icon="warning"
            >
              {{ resetPasswordErrorMessage }}
            </v-alert>
            </div>
            {{ $t("forgot_password_message") }}
            <v-form
              ref="resetForm"
              v-model="resetPasswordValid"
              v-on:submit.prevent="submitResetPassword"
            >
              <v-text-field
                v-model="resetPasswordEmail"
                :label="$t('email')"
                :rules="emailRules"
                autocomplete="email"
                required
              ></v-text-field>
              <v-btn
                id="resetPasswordButton"
                type="submit"
                class="primary"
                :disabled="!resetPasswordValid"
                >{{ $t("reset_password") }}</v-btn
              >
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <StandardErrorMessage ref="errMsg"></StandardErrorMessage>
    <ExtendSessionMessage ref="extendSessionMsg"></ExtendSessionMessage>
    <BackendConnector ref="backend"></BackendConnector>

  </v-app>
</template>

<script>
import StandardErrorMessage from "@/components/StandardErrorMessage.vue";
import ExtendSessionMessage from "@/components/ExtendSessionMessage.vue";
//import VOtpInput from "vuetify/lib";

// Only used to set correct header for change_user_language
import axios from "axios";

export default {
  name: "App",
  data() {
    let self = this;
    return {
      showLoginDialog: false,
      loginFieldsValid: false,
      langbutton: false,
      loginFormEmail: "",
      password: "",
      totp: null,
      passwordRules: [v => !!v || self.$t("password_required")],
      emailRules: [
        v => !!v || this.$t("email_required"),
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("email_valid")
      ],

      showResetPasswordDialog: false,
      resetPasswordSent: false,
      resetPasswordPending: false,
      resetPasswordErrorMessage: "",
      resetPasswordValid: false,
      resetPasswordEmail: "",

      drawer: null,
      dmini: true,
      locale: "en",
      userprofile: { affiliation: "notsetyet" }
    };
  },
  components: {
    StandardErrorMessage,
    ExtendSessionMessage
  },
  props: {
    source: String
  },
  methods: {
    /*
     * Add tab-key support to v-app-bar menus.
     */
    toggleLanguageMenu: function(event) {
      let menu = this.$refs["languageMenu"];
      menu.isActive = !menu.isActive;
      let btn = document.getElementById("languageButton");
      btn.setAttribute("aria-expanded", menu.isActive);
    },
    toggleLanguageMenuSmall: function(event) {
      let menu = this.$refs["languageMenuSmall"];
      menu.isActive = !menu.isActive;
      let btn = document.getElementById("languageButtonSmall");
      btn.setAttribute("aria-expanded", menu.isActive);
    },
    toggleLanguageMenuTiny: function(event) {
      let menu = this.$refs["languageMenuTiny"];
      menu.isActive = !menu.isActive;
      let btn = document.getElementById("languageButtonTiny");
      btn.setAttribute("aria-expanded", menu.isActive);
    },
    toggleSessionMenu: function(event) {
      let menu = this.$refs["sessionMenu"];
      menu.isActive = !menu.isActive;
      let btn = document.getElementById("sessionButton");
      btn.setAttribute("aria-expanded", menu.isActive);
    },
    toggleSessionMenuSmall: function(event) {
      let menu = this.$refs["sessionMenuSmall"];
      menu.isActive = !menu.isActive;
      let btn = document.getElementById("sessionButtonSmall");
      btn.setAttribute("aria-expanded", menu.isActive);
    },
    toggleSessionMenuTiny: function(event) {
      let menu = this.$refs["sessionMenuTiny"];
      menu.isActive = !menu.isActive;
      let btn = document.getElementById("sessionButtonTiny");
      btn.setAttribute("aria-expanded", menu.isActive);
    },
    menuTabPressed: function(event) {
      let menu = this.$refs[event.target.dataset.menu];
      // make sure children are evaluated
      menu.getTiles();
      // In most cases, we want the event to be stopped,
      // however, in the cases where we tab 'out of' the menu,
      // we want to propagate the event.
      let propagate = false;

      if (menu.isActive) {
          if (event.shiftKey) {
            if (menu.listIndex <= 0) {
              menu.isActive = false;
              propagate = true;
            } else {
              menu.prevTile();
            }
          } else {
            if (menu.listIndex < 0) {
              menu.firstTile();
            } else if (menu.listIndex + 1 >= menu.tiles.length) {
              menu.isActive = false;
              propagate = true;
            } else {
              menu.nextTile()
            }
          }
      } else {
        propagate = true;
      }
      if (!propagate) {
        event.preventDefault();
      }
    },
    menuEnterPressed: function(event) {
      let menu = this.$refs[event.target.dataset.menu];
      let propagate = false;
      if (menu.isActive) {
        if (menu.listIndex < 0) {
          propagate = true;
        } else {
          menu.tiles[menu.listIndex].click();
        }
      } else {
        propagate = true;
      }
      if (!propagate) {
        event.preventDefault();
      }
    },
    toggleNavigationDrawer: function() {
        this.drawer = !this.drawer;
    },
    menuTabPrev: function(a) {
      let menu = this.$refs.languageMenu
      //console.log("Current tselection: " + typeof(menu.listIndex+1))
      //console.log("Current ttiles: " + typeof(menu.tiles.length))
      //console.log("Current selection: " + (menu.listIndex + 1))
      //console.log("Current tiles: " + (menu.tiles.length))
      //console.log("equalsO: " + ((menu.listIndex + 1) >= menu.tiles.length))
      if (menu.listIndex < 0) {
        event.preventDefault();
        menu.firstTile()
      } else if (((menu.listIndex + 1) >= menu.tiles.length)) {
        //this.$emit(a);
      } else {
        event.preventDefault();
        menu.nextTile()
      }
    },
    showNoAccessAlert: function() {
      this.$refs.errMsg.doShowError(
        this.$t("error"),
        this.$t("needs_participant_access"),
        false,
        true
      );
    },
    showDrawer: function(event) {
      this.dmini = false;
    },
    mouseLeave: function(event) {
      this.dmini = true;
    },
    showLogin: function(event) {
      //this.loginFormEmail = ''
      //this.password = ''
      this.showLoginDialog = true;
    },
    submitLogin() {
      if (this.$refs.loginForm.validate()) {
        let self = this;
        let tmp_totp = self.totp;
        self.totp = "";
        this.$store
          .dispatch("login", {
            email: self.loginFormEmail,
            password: self.password,
            totp: tmp_totp
          })
          .then(function() {
            // if login was succesful, we should have profile now, which
            // may contain the language setting
            let lang_setting = self.getSettingsLanguage;
            if (lang_setting) {
              self.setLocale(lang_setting);
            }
            // Store login for next time
            localStorage.setItem("login_email", self.loginFormEmail);
            self.showLoginDialog = false;
            self.totp = "";
          })
          .catch(function(error) {
            // always show message or just log?
            console.trace(error);
            var errorTitle = self.$t("error");
            var errorMessage = JSON.stringify(error);
            if (error.message === "BACKEND_CONN_FAILED") {
              errorMessage = self.$t("backend_connection_failed");
            }
            self.$refs.errMsg.doShowError(errorTitle, errorMessage);
            self.totp = "";
          });
      }
    },
    forgotPassword() {
      this.showLoginDialog = false;
      (this.resetPasswordSent = false),
        (this.resetPasswordPending = false),
        (this.showResetPasswordDialog = true);
    },
    submitResetPassword() {
      let url = process.env.VUE_APP_ROOT_API + "/rest-auth/password/reset/";
      let self = this;
      this.resetPasswordPending = true;
      this.$refs.backend
        .post(url, {
          email: this.resetPasswordEmail
        })
        .then(function(response) {
          self.resetPasswordPending = false;
          self.resetPasswordSent = true;
        })
        .catch(function(error) {
          self.resetPasswordPending = false;
        });
    },
    logout() {
      // this.$store.dispatch('logout')
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    changeLocale(language) {
      this.setLocale(language);
      // Store the language in the user settings on the background
      if (this.isLoggedIn) {
        axios.defaults.headers.common["Accept-Language"] = language;
        let url = process.env.VUE_APP_ROOT_API + "/auth/change_user_language";
        this.$refs.backend.post_auth(url, {});
      }
    },
    setLocale(language) {
      this.locale = language;
      if (this.$i18n) {
        this.$i18n.locale = language;
      }
      this.$vuetify.lang.current = language;
      this.$store.dispatch("setLanguage", { lang: language });
    },
    keyDownHandler (event) {
      if (event.code === 'Escape') {
        this.toggleNavigationDrawer();
      }
      if (event.code === 'Tab') {
        //console.log(document.activeElement);
        // If menu was manually toggled, 'tab' should only go to menu items
        // This is only the case in 'medium-to-small' viewports
        // (where nav drawer is togglable)
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw < 1220 && this.drawer) {
            let cur = document.activeElement;
            if (!event.shiftKey) {
                if (cur.id == 'navMenuLast' || !cur.classList.contains('navMenu')) {
                    document.getElementById('navMenuFirst').focus()
                    event.preventDefault();
                }
            } else {
                if (cur.id == 'navMenuFirst' || !cur.classList.contains('navMenu')) {
                    document.getElementById('navMenuLast').focus()
                    event.preventDefault();
                }
            }
        }
      }
    },
    checkOTP() {
      if (this.totp.length > 5) {
        //this.$refs.loginButton.focus();
        this.submitLogin();
        this.$refs.otpInput.focut();
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    sessionTimeLeft() {
      return this.$store.getters.sessionTime;
    },
    isParticipant() {
      return (
        this.$store.getters.isLoggedIn && this.$store.getters.isParticipant
      );
    },
    isOnboarder() {
      return this.$store.getters.isLoggedIn && this.$store.getters.isOnboarder;
    },
    email() {
      return this.$store.getters.email;
    },
    affiliation() {
      return this.$store.getters.getAffiliation;
    },
    smk_address() {
      return this.$store.getters.getSMKAddress;
    },
    isLoginPending() {
      return this.$store.getters.isLoginPending;
    },
    showJWT() {
      return this.$store.getters.getJWT;
    },
    loginErrorMessage() {
      return this.$store.getters.loginErrorMessage;
    },
    getLanguage() {
      return this.$store.getters.getLanguage;
    },
    getSettingsLanguage() {
      return this.$store.getters.getSettingsLanguage;
    },
    documentationLink: function() {
      return process.env.VUE_APP_DOC_URL + this.$i18n.locale + "/";
    },
    privacyLink: function() {
      if (this.$store.getters.getLanguage === "nl") {
        return "/documentation/nl/additional/privacy_statement/";
      } else {
        return "/documentation/additional/privacy_statement/";
      }
    },
    changelogLink: function() {
      if (this.$store.getters.getLanguage === "nl") {
        return process.env.VUE_APP_DOC_URL + "nl/additional/changelog/";
      } else {
        return process.env.VUE_APP_DOC_URL + "additional/changelog/";
      }
    }
  },
  mounted() {
    this.locale = this.getLanguage;
    if (this.$i18n) {
      this.$i18n.locale = this.getLanguage;
    }
    this.$vuetify.lang.current = this.getLanguage;
    let ls_email = localStorage.getItem("login_email");
    if (ls_email) {
      this.loginFormEmail = ls_email;
    }
    window.addEventListener('keydown', this.keyDownHandler)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler)
  },
  beforeMount() {
    let self = this;
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "EXPIRED":
          self.showLogin();
          break;
      }
    });
  },
  showError() {
    alert("only for participants");
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch("inspectToken");
      document.title = this.$t(to.name) + ' | ' + this.$t('title') || this.$t('title');
      if (this.$route.query.login) {
        this.showLogin()
      }
    },
    // copy the aria-labelledby attribute after the dialog opens
    showLoginDialog: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.$nextTick(() => {
            const dialog = this.$refs?.showLoginDialog;
            const content = dialog?.$refs?.content;
            if (content) {
              // Copy aria-labelledby from v-dialog to new rendered element.
              content.setAttribute('aria-labelledby', dialog.$attrs['aria-labelledby']);
            }
          });
        }
      }
    },
    // copy the aria-labelledby attribute after the dialog opens
    showResetPasswordDialog: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.$nextTick(() => {
            const dialog = this.$refs?.showResetPasswordDialog;
            const content = dialog?.$refs?.content;
            if (content) {
              // Copy aria-labelledby from v-dialog to new rendered element.
              content.setAttribute('aria-labelledby', dialog.$attrs['aria-labelledby']);
            }
          });
        }
      }
    }
  },
  created() {
    document.title = this.$t(this.$route.name) + ' | ' + this.$t('title') || this.$t('title');
    if (this.$route.path == '/login') {
        this.showLogin();
    };
  }

};
</script>
