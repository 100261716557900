<template>
  <div>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-card class="elevation-12">
              <v-card-title mb-0 pb-0>
                <h1 class="text-h5">{{ $t("configure_2fa") }}</h1>
              </v-card-title>
              <v-card-text>
                <div v-if="hasTOTPDevice">
                  {{ $t('otp_token_enabled') }}
                </div>
                <div v-else>
                  <v-btn @click="createOTPToken">{{ $t('otp_create_token') }}</v-btn>
                </div>
              </v-card-text>
            </v-card>
        </v-layout>
      </v-container>
    <v-dialog v-model="verifyNewOTPCodeDialog" max-width="420px">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn icon color="primary" @click.stop="verifyNewOTPCodeDialog = false" :title="$t('button_close')">
                <v-icon>close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <div v-if="isVerifyPending">
            <v-progress-circular
              color="blue-grey"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-form
              ref="verifyNewOTPForm"
              v-on:submit.prevent="submitVerification"
            >
              <div>
                <center>
                <p>
                {{ $t('otp_scan_qr') }}
                </p>
                <img :src="otpQR" alt="OTP QR Code" style="max-width: 360px;" />
                <p>
                <a :href="otpURL">{{ $t('otp_auth_link') }}</a>
                </p>
                </center>
              </div>
              <v-label>{{ $t('otp_verify_token') }}</v-label>
              <v-otp-input
                ref="otpInput"
                v-model="confirmationToken"
                length="6"
                type="number"
                class="mb-6"
                required
                @input="checkOTP"
              ></v-otp-input>
              <div aria-live="assertive">
                <v-alert v-if="verifyErrorMessage" color="error" icon="warning" style="color: #ffffff;">
                  {{ verifyErrorMessage }}
                </v-alert>
              </div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <BackendConnector ref="backend"></BackendConnector>
  </div>
</template>

<script>
import BackendConnector from "@/components/BackendConnector.vue";

export default {
  name: "home",
  data() {
    return {
      verifyNewOTPCodeDialog: false,
      confirmationToken: null,
      isVerifyPending: false,
      otpTokenCreated: false,
      verifyErrorMessage: null,
      pendingTokenContent: null,
      otpURL: null,
      otpQR: null,
    }
  },
  components: {
    BackendConnector,
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  },
  computed: {
    hasTOTPDevice() {
      return this.otpTokenCreated || this.$store.getters.hasTOTPDevice;
    },
  },
  methods: {
    createOTPToken: function() {
      let url = process.env.VUE_APP_ROOT_API + "/auth/create_totp_token";

      this.$refs.backend
        .post_auth(url)
        .then((response) => {
          if (response.data && response.data.error) {
            alert("Error from server: " + response.data.error);
          } else {
            this.otpURL = response.data.url;
            this.otpQR = response.data.qr;
            this.verifyNewOTPCodeDialog = true
          }
        })
        .catch(function(error) {
          // handled by BackendConnector
        });
      this.verifyNewOTPCodeDialog = true;
    },
    submitVerification: function() {
      let url = process.env.VUE_APP_ROOT_API + "/auth/verify_totp_token";
      let data = { 'token': this.confirmationToken }
      this.$refs.backend
        .post_auth(url, data)
        .then((response) => {
          if (response.data && response.data.error) {
            this.verifyErrorMessage = response.data.error;
            this.confirmationToken = "";
            this.$refs.otpInput.focus();
          } else {
            this.otpURL = response.data.url;
            this.otpQR = response.data.qr;
            this.otpTokenCreated = true;
            this.verifyNewOTPCodeDialog = false;
            this.$store.dispatch("loadUserData");
          }
        })
        .catch(function(error) {
          // handled by BackendConnector
        });
    },
    checkOTP: function() {
      if (this.confirmationToken.length > 5) {
        this.submitVerification();
      }
    }
  }
};
</script>
