import Vue from "vue";
import Router from "vue-router";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Account from "../views/Account.vue";
import CertificateCheck from "../views/CertificateCheck.vue";
import Changelog from "../views/Changelog.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ConnectionCoach from "../views/ConnectionCoach.vue";
import Discover from "../views/Discover.vue";
import Help from "../views/Help.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ParticipantApproval from "../views/ParticipantApproval.vue";
import Privacy from "../views/Privacy.vue";
import ReceivedDocuments from "../views/ReceivedDocuments.vue";
import Register from "../views/Register.vue";
import SendTest from "../views/SendTest.vue";
import SentDocuments from "../views/SentDocuments.vue";
import Sitemap from "../views/Sitemap.vue";
// import SMP from '../views/SMP.vue'
import TestAccessPointDetails from "../views/TestAccessPointDetails.vue";
import TestAccessPoints from "../views/TestAccessPoints.vue";
import TestResultApproval from "../views/TestResultApproval.vue";
import Validate from "../views/Validate.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/about",
      name: "about",
      component: About,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
    {
      path: "/privacy",
      name: "privacy",
      component: Privacy
    },
    {
      path: "/changelog",
      name: "changelog",
      component: Changelog
    },
    {
      path: "/sendtest",
      name: "sendtest_title",
      component: SendTest
    },
    {
      path: "/connectioncoach",
      redirect: "/applicationtest"
    },
    {
      path: "/applicationtest",
      name: "connectioncoach_title",
      component: ConnectionCoach
    },
    {
      path: "/discover",
      name: "discover",
      component: Discover
    },
    {
      path: "/validate",
      name: "validate",
      component: Validate
    },
    {
      path: "/test_access_points",
      name: "test_access_points",
      component: TestAccessPoints
    },
    {
      path: "/test_access_points/:ap_id",
      name: "test_access_point_details",
      component: TestAccessPointDetails
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/received_documents",
      name: "received_documents",
      component: ReceivedDocuments
    },
    {
      path: "/sent_documents",
      name: "sent_documents",
      component: SentDocuments
    },
    {
      path: "/participant_approval",
      name: "participant_approva",
      component: ParticipantApproval
    },
    {
      path: "/test_result_approval",
      name: "test_result_approva",
      component: TestResultApproval
    },
    {
      path: "/reset/:uid/:code",
      name: "change-password",
      component: ChangePassword
    },
    {
      path: "/account",
      name: "account",
      component: Account
    },
    {
      path: "/sitemap",
      name: "sitemap",
      component: Sitemap
    }
  ]
});

export default router;
