<template>
  <v-card>
    <v-card-actions class="cardheader">
      <v-btn
        text
        style="text-transform: none !important;"
        @click="showSend = !showSend"
      >
        <v-icon>{{
          showSend ? "keyboard_arrow_down" : "keyboard_arrow_right"
        }}</v-icon>
        <div class="cardheader">
          <h1 class="text-h5">{{ $t("send_document") }}</h1>
        </div>
      </v-btn>
    </v-card-actions>

    <v-card-text v-show="showSend" :aria-expanded="showSendStatus">
      <!-- AP options -->
      <p>{{ $t('send_instructions') }}</p>
      <v-form v-on:submit.prevent="sendDocument">
        <div v-if="!ap_id_passed">
          <v-select
            v-model="selectedAP"
            :items="ap_list"
            :label="$t('send_to_access_point')"
            item-text="name"
            item-value="id"
            prepend-icon="business"
          >
          </v-select>
        </div>

        <!-- the document to send -->
        <v-file-input
          :label="$t('select_file')"
          v-model="sendSelectedFile"
          prepend-icon="folder"
        />

        <!-- document options -->
        <v-card class="options">
          <v-card-actions class="options">
            <v-btn
              text
              small
              style="text-transform: none !important;"
              @click="showOptions = !showOptions"
            >
              <v-icon>{{
                showOptions ? "keyboard_arrow_down" : "keyboard_arrow_right"
              }}</v-icon>
              <v-subheader>{{ $t("options") }}</v-subheader>
              <v-spacer />
            </v-btn>
          </v-card-actions>

          <v-slide-y-transition>
            <v-card-text v-show="showOptions" :aria-expanded="showSendOptionsStatus">
              <v-container>
                <v-row justify="start" align="center">
                  <v-col style="max-width: 300px;">
                    <v-switch
                      :label="$t('send_overwrite_recipient')"
                      v-model="overwriteRecipient"
                      style="padding: 0px; margin: 0px;"
                    ></v-switch>
                    <v-switch
                      v-if="overwriteRecipient"
                      :label="$t('send_overwrite_recipient_in_document')"
                      v-model="overwriteRecipientInDocument"
                      style="padding: 0px; margin: 0px;"
                    ></v-switch>
                  </v-col>
                  <RecipientField
                    ref="sendRecipientField"
                    v-if="overwriteRecipient"
                  ></RecipientField>
                </v-row>
              </v-container>
            </v-card-text>
          </v-slide-y-transition>
        </v-card>

        <v-card-actions>
          <v-btn
            type="submit"
            class="primary"
            :disabled="!sendHaveSelectedFile"
            >{{ $t("send") }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
    <BackendConnector ref="backend"></BackendConnector>
    <TestTaskViewer
      v-on:error="testTaskDone()"
      ref="taskViewer"
    ></TestTaskViewer>
  </v-card>
</template>

<script>
// @ is an alias to /src
import BackendConnector from "@/components/BackendConnector.vue";
import RecipientField from "@/components/RecipientField.vue";
import TestTaskViewer from "@/components/TestTaskViewer.vue";

export default {
  name: "SendDocument",
  components: {
    BackendConnector,
    TestTaskViewer,
    RecipientField
  },
  data() {
    return {
      showSend: false,
      showOptions: true,
      overwriteRecipient: false,
      overwriteRecipientInDocument: false,
      changeRecipientInDocument: false,
      overwriteRecipientValue: "0108:",
      sendSelectedFile: null,
      ap_list: [],
      ap_id_passed: false,
      selectedAP: 0
    };
  },
  props: {
    accesspoint: null,
    successCallback: null
  },
  computed: {
    sendHaveSelectedFile: function() {
      return this.sendSelectedFile && this.sendSelectedFile !== "";
    },
    showSendStatus: function() {
      return this.showSend?"true":"false";
    },
    showSendOptionsStatus: function() {
      return this.showOptions?"true":"false";
    }
  },
  methods: {
    loadData: function() {
      // get the possible access points if not provided with a fixed one
      if (!this.ap_id_passed) {
        var url = process.env.VUE_APP_ROOT_API + "/accesspoints/list/";
        var self = this;
        this.$refs.backend.get_auth(url).then(function(response) {
          // alert(JSON.stringify(response.data))
          self.ap_list = response.data;
          // If we had no element selected, pick the first one
          if (self.selectedAP == 0 && self.ap_list) {
            let ls_selected_ap = localStorage.getItem("send_selected_ap");
            if (ls_selected_ap) {
              self.selectedAP = JSON.parse(ls_selected_ap);
            } else {
              self.selectedAP = self.ap_list[0].id;
            }
          }
          // }).catch(function (error) {
          // handled by BackendConnector
        });
      } else {
        this.selectedAP = this.accesspoint;
      }
    },
    unloadData: function() {},
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    },
    testTaskDone: function() {
      this.unloadData();
      this.loadData();
      this.showActionDialog = false;
    },
    updateRecipientFormField: function(new_value) {
      if (this.$refs.sendRecipientField) {
        this.$refs.sendRecipientField.setIdentifier(new_value);
      }
    },
    sendDocument: function() {
      // store some settings for next time
      localStorage.setItem("send_overwrite_recipient", this.overwriteRecipient);
      localStorage.setItem(
        "send_overwrite_recipient_in_document",
        this.overwriteRecipientInDocument
      );
      if (this.$refs.sendRecipientField) {
        localStorage.setItem(
          "send_overwrite_recipient_value",
          this.$refs.sendRecipientField.getIdentifier()
        );
      }
      localStorage.setItem("send_selected_ap", this.selectedAP);

      let file = this.sendSelectedFile;
      let reader = new FileReader();
      var filedata = {};
      let self = this;

      reader.onload = (function(result) {
        return function(e) {
          result.document = e.target.result;
          result.filename = file.name;
          if (self.overwriteRecipient) {
            // TODO: use v-model
            result.override_recipient = self.$refs.sendRecipientField.getIdentifier();
            result.override_recipient_in_document =
              self.overwriteRecipientInDocument;
          }
          self.showActionDialog = true;
          let url =
            process.env.VUE_APP_ROOT_API_WS +
            "/accesspoints/send_document/" +
            self.selectedAP;
          self.$refs.taskViewer
            .run(url, result)
            .then(function(result) {
              if (self.successCallback) {
                self.successCallback();
              }
              //self.fetchDocumentTestResults()
            })
            .catch(function(error) {
              //  alert('error! ' + error)
            });
        };
      })(filedata);
      reader.readAsText(file);
    }
  },
  mounted() {
    this.$store.dispatch("inspectToken");
    if (this.accesspoint) {
      this.ap_id_passed = true;
    }
    try {
      this.loadData();
    } catch (berr) {
      alert(
        "[XX] error in loadData for TestAccessPointsDetails.vue (mounted): " +
          berr
      );
    }
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }

    // check localStorage values, they are stored when sending
    let ls_overwrite_recipient = localStorage.getItem(
      "send_overwrite_recipient"
    );
    if (JSON.parse(ls_overwrite_recipient)) {
      this.overwriteRecipient = true;
    }
    let ls_overwrite_recipient_in_document = localStorage.getItem(
      "send_overwrite_recipient_in_document"
    );
    if (JSON.parse(ls_overwrite_recipient_in_document)) {
      this.overwriteRecipientInDocument = true;
    }
    let ls_overwrite_recipient_value = localStorage.getItem(
      "send_overwrite_recipient_value"
    );
    if (ls_overwrite_recipient_value) {
      this.$nextTick(() => {
        this.updateRecipientFormField(ls_overwrite_recipient_value);
      });
    }
  }
};
</script>

<style scoped lang="stylus">
.options
  font-size 14px
  padding 0px
  margin 0px
  background-color #f4f4f4

.cardheader
  width 100%
</style>
