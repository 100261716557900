import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";

/*
import Vuetify from "vuetify/es5/components/Vuetify";
import VApp from "vuetify/es5/components/VApp";
import VAlert from "vuetify/es5/components/VAlert";
import VAutocomplete from "vuetify/es5/components/VAutocomplete";
import VAvatar from "vuetify/es5/components/VAvatar";
import VBtn from "vuetify/es5/components/VBtn";
import VBtnToggle from "vuetify/es5/components/VBtnToggle";
import VCard from "vuetify/es5/components/VCard";
import VCheckbox from "vuetify/es5/components/VCheckbox";
import VDataTable from "vuetify/es5/components/VDataTable";
import VDialog from "vuetify/es5/components/VDialog";
import VDivider from "vuetify/es5/components/VDivider";
import VForm from "vuetify/es5/components/VForm";
import VGrid from "vuetify/es5/components/VGrid";
import VIcon from "vuetify/es5/components/VIcon";
import VInput from "vuetify/es5/components/VInput";
import VLabel from "vuetify/es5/components/VLabel";
import VList from "vuetify/es5/components/VList";
import VMenu from "vuetify/es5/components/VMenu";
import VNavigationDrawer from "vuetify/es5/components/VNavigationDrawer";
import VPagination from "vuetify/es5/components/VPagination";
import VProgressCircular from "vuetify/es5/components/VProgressCircular";
import VRadioGroup from "vuetify/es5/components/VRadioGroup";
import VSelect from "vuetify/es5/components/VSelect";
import VStepper from "vuetify/es5/components/VStepper";
import VSubheader from "vuetify/es5/components/VSubheader";
import VSwitch from "vuetify/es5/components/VSwitch";
import VTabs from "vuetify/es5/components/VTabs";
import VTextField from "vuetify/es5/components/VTextField";
import VTextarea from "vuetify/es5/components/VTextarea";
import VToolbar from "vuetify/es5/components/VToolbar";
import VTooltip from "vuetify/es5/components/VTooltip";
import transitions from "vuetify/es5/components/transitions";
*/

import Vuetify from 'vuetify/lib';

import nl from "../i18n/nl.js";

Vue.use(Vuetify);

/*Vue.use(Vuetify, {
  components: {
    VApp,
    VAlert,
    VAutocomplete,
    VAvatar,
    VBtn,
    VBtnToggle,
    VCard,
    VCheckbox,
    VDataTable,
    VDialog,
    VDivider,
    VForm,
    VGrid,
    VIcon,
    VInput,
    VLabel,
    VList,
    VMenu,
    VNavigationDrawer,
    VPagination,
    VProgressCircular,
    VRadioGroup,
    VSelect,
    VStepper,
    VSubheader,
    VSwitch,
    VTabs,
    VTextField,
    VTextarea,
    VToolbar,
    VTooltip,
    transitions
  },
  lang: {
    locales: { nl },
    current: "en"
  },
  theme: {
    primary: "#01689b",
    secondary: "#5bb0ff",
    accent: "#8c9eff",
    error: "#b71c1c"
  }
});*/

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { nl },
        current: "en"
    },
    theme: {
        themes: {
            light: {
                primary: "#01689b",
                secondary: "#3a556f",//5bb0ff
                accent: "#8c9eff",
                error: "#b71c1c",
                success: "#16741a",
                warning: "#9C520D"
            }
        }
    }
});
