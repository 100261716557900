<template>
  <div>
    <SendDocument v-bind:successCallback="fetchDocumentTestResults" />

    &nbsp;
    <v-card id="sent_documents">
      <v-card-title class="cardheader">
        <div>
          <h1 class="text-h5">{{ $t("sent_documents") }}</h1>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :options.sync="pagination"
          :serverItemsLength="sendDocumentTestResultsCount"
          :items="sendDocumentTestResults"
          :headers="sendDocumentTestResultHeaders"
          :footer-props="{
            itemsPerPageOptions:[20]
          }"
          must-sort
          hide-actions0
          class="mytable"
          mobile-breakpoint="960"
        >
              <template v-slot:item.creation_date="{ item }">
                {{ item.creation_date | formatDateTime }}
              </template>
              <template v-slot:item.access_point="{ item }">
                <div @click="goToAccessPoint(item.access_point)" style="cursor: pointer;">
                <v-btn icon class="secondary d-sr-only-focusable" :title="$t('details')">
                  <v-icon>search</v-icon>
                </v-btn>
                  <button>{{ item.access_point_name }}</button>
                </div>
              </template>
              <template v-slot:item.filename="{ item }">
                <div style="word-break: break-all;">
                {{ item.filename }}
                </div>
              </template>
              <template v-slot:item.recipient_id="{ item }">
                <div>
                {{ item.recipient_id }}
                </div>
              </template>
              <template v-slot:item.document_sent="{ item }">
                <ResultIcon
                  v-bind:result="item.document_sent"
                ></ResultIcon>
              </template>
              <template v-slot:item.response_signature_valid="{ item }">
                <ResultIcon
                  v-bind:result="item.response_signature_valid"
                ></ResultIcon>
              </template>
              <template v-slot:item.document_delivered="{ item }">
                <ResultIcon
                  v-bind:result="item.document_delivered"
                ></ResultIcon>
              </template>
              <template v-slot:item.mlr="{ item }">
                <template v-if="item.mlr_state">
                <button v-on:click="doShowMLRDialog(item)" class="fkbtn">
                  <v-layout nowrap>
                  <StatusIcon
                    v-bind:status="mlrResultIcon(item)"
                    :tooltip="mlrTooltip(item)"
                  ></StatusIcon>
                  <StatusIcon
                    v-if="item.mlr"
                    v-bind:status="mlrValidIcon(item)"
                    :tooltip="mlrTooltip(item)"
                  ></StatusIcon>
                  </v-layout>
                </button>
                </template>
                <template v-else>
                  <StatusIcon
                    v-bind:status="mlrResultIcon(item)"
                    :tooltip="mlrTooltip(item)"
                  ></StatusIcon>
                </template>
                <!--
                <div @click="doShowMLRDialog(item)" style="cursor: pointer;">
                  <v-layout nowrap>
                  <StatusIcon
                    v-bind:status="mlrResultIcon(item)"
                    :title="mlrTooltip(item)"
                  ></StatusIcon>
                  <StatusIcon
                    v-if="item.mlr"
                    v-bind:status="mlrValidIcon(item)"
                    :title="mlrTooltip(item)"
                  ></StatusIcon>
                  </v-layout>
                </div>
                -->
              </template>
              <template v-slot:item.view_log="{ item }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          fab
                          small
                          class="secondary"
                          v-on="on"
                          color="secondary"
                          dark
                          :title="$t('logs')"
                        >
                          <v-icon>search</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="showSendDocumentTestSendLog(item)"
                        >
                          <v-list-item-title>{{
                            $t("system_log")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="item.request"
                          @click="showSendDocumentTestRequest(item)"
                        >
                          <v-list-item-title>{{
                            $t("as2_request")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="item.response"
                          @click="showSendDocumentTestResponse(item)"
                        >
                          <v-list-item-title>{{
                            $t("as2_response")
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn
                  fab
                  small
                  class="secondary"
                  :disabled="item.disabled"
                  :title="$t('delete')"
                  @click.native="
                    deleteSendDocumentTestResult(item)
                  "
                  ><v-icon>delete</v-icon></v-btn>
              </template>

<!--
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-left">
                {{ props.item.creation_date | formatDateTime }}
              </td>
              <td
                class="text-left"
                @click="goToAccessPoint(props.item.access_point)"
              >
                {{ props.item.access_point_name }}
              </td>
              <td class="text-left">{{ props.item.filename }}</td>
              <td class="text-left">{{ props.item.recipient_id }}</td>
              <td>
                <ResultIcon
                  v-bind:result="props.item.document_sent"
                ></ResultIcon>
              </td>
              <td>
                <ResultIcon
                  v-bind:result="props.item.response_signature_valid"
                ></ResultIcon>
              </td>
              <td>
                <ResultIcon
                  v-bind:result="props.item.document_delivered"
                  :tooltip="props.item.response_status_message"
                ></ResultIcon>
              </td>

              <td @click="doShowMLRDialog(props.item)" style="cursor: pointer;">
                <v-layout wrap>
                <v-row>
                <StatusIcon
                  v-bind:status="mlrResultIcon(item)"
                  :tooltip="mlrTooltip(props.item)"
                ></StatusIcon>
                <StatusIcon
                  v-if="props.item.mlr"
                  v-bind:status="mlrValidIcon(item)"
                  :tooltip="mlrTooltip(props.item)"
                ></StatusIcon>
                </v-row>
                </v-layout>
              </td>

              <td>
                <v-menu offset-y>
                  <template v-slot:activator="{ on: menu }">
                    <v-btn
                      fab
                      small
                      class="secondary"
                      slot="activator"
                      color="secondary"
                      dark
                      v-on="menu"
                    >
                      <v-icon>search</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="showSendDocumentTestSendLog(props.item)"
                    >
                      <v-list-item-title>{{
                        $t("system_log")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="props.item.request"
                      @click="showSendDocumentTestRequest(props.item)"
                    >
                      <v-list-item-title>{{
                        $t("as2_request")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="props.item.response"
                      @click="showSendDocumentTestResponse(props.item)"
                    >
                      <v-list-item-title>{{
                        $t("as2_response")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>

              <td>
                <v-btn
                  fab
                  small
                  class="secondary"
                  :disabled="props.item.disabled"
                  @click="deleteSendDocumentTestResult(props.index, props.item)"
                  :title="$t('delete'})"
                  ><v-icon>delete</v-icon></v-btn
                >
              </td>
            </tr>
          </template>
-->
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showMLRDialog">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn
              icon
              color="primary"
              @click.stop="showMLRDialog = false"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <ValidationResults ref="mlrValidationResults" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <BackendConnector ref="backend"></BackendConnector>
    <TextViewer ref="textViewer"></TextViewer>
    <StandardUserConfirmation ref="userConfirmation"></StandardUserConfirmation>
  </div>
</template>

<script>
// @ is an alias to /src
import ResultIcon from "@/components/ResultIcon.vue";
import StatusIcon from "@/components/StatusIcon.vue";
import TextViewer from "@/components/TextViewer.vue";
import SendDocument from "@/components/SendDocument.vue";
import StandardUserConfirmation from "@/components/StandardUserConfirmation.vue";
import ValidationResults from "@/components/ValidationResults.vue";

export default {
  name: "home",
  components: {
    ResultIcon,
    StatusIcon,
    SendDocument,
    StandardUserConfirmation,
    TextViewer,
    ValidationResults
  },
  data() {
    return {
      pagination: {
        sortBy: ["creation_date"],
        descending: true,
        rowsPerPage: 10,
        page: 1
      },
      // TODO: is this used?
      dropdown_font: ["aaa", "bbb", "ccc"],
      sendDocumentTestResults: [],
      sendDocumentTestResultsCount: 0,
      task_urls: {
        send_document:
          process.env.VUE_APP_ROOT_API_WS +
          "/accesspoints/send_document/" +
          this.$route.params.ap_id
      },
      showMLRDialog: false
    };
  },
  computed: {
    sendHaveSelectedFile: function() {
      return this.sendSelectedFile !== "";
    },
    sendDocumentTestResultHeaders() { return [
        { text: this.$t("date"), value: "creation_date", sortable: false },
        { text: this.$t("accesspoint"), value: "access_point", sortable: false },
        { text: this.$t("filename"), value: "filename", sortable: false },
        { text: this.$t("recipient_id"), value: "recipient_id", sortable: false },
        { text: this.$t("sent"), value: "document_sent", sortable: false },
        { text: this.$t("response"), value: "response_signature_valid", sortable: false },
        { text: this.$t("accepted"), value: "document_delivered", sortable: false },
        { text: this.$t("mlr"), value: "mlr", sortable: false },
        { text: this.$t("log"), value: "view_log", sortable: false },
        { text: this.$t("delete"), value: "delete", sortable: false }
    ]}
  },
  methods: {
    loadData: function() {
      this.fetchDocumentTestResults();
    },
    unloadData: function() {
      // TODO
      this.accessPointDetails = null;
      this.haveAccessPointDetails = false;
      this.accessPointResults = [];
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    },
    testTaskDone: function() {
      this.unloadData();
      this.loadData();
      this.showActionDialog = false;
    },
    fetchDocumentTestResults: function() {
      // use AP ID 0 to get send results to all access points of this user
      var url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/details/0/document_send_results/?page=" +
        this.pagination.page +
        "&page_size=" +
        this.pagination.rowsPerPage;
      let order_by = "creation_date";
      let direction = "desc";
      if (this.pagination.sortBy) {
        order_by = this.pagination.sortBy;
        if (!this.pagination.descending) {
          direction = "asc";
        }
      }
      url += "&order_by=" + order_by + "&direction=" + direction;
      var self = this;
      this.$refs.backend
        .get_auth(url)
        .then(function(response) {
          self.sendDocumentTestResultsCount = response.data.count;
          self.sendDocumentTestResults = response.data.results;
        })
        .catch(function() {
          self.sendDocumentTestResults = [];
        });
    },
    deleteSendDocumentTestResult: function(item) {
      item.disabled = true;
      let callbackConfirm = (arg) => {
        let index = this.sendDocumentTestResults.indexOf(item);
        this.$set(this.sendDocumentTestResults, index, item);
        let url =
          process.env.VUE_APP_ROOT_API +
          "/accesspoints/details/" +
          item.access_point +
          "/document_send_results/" +
          item.id +
          "/";
        this.$refs.backend
          .delete_auth(url)
          .then((response) => {
            this.fetchDocumentTestResults();
          })
          .catch((error) => {
            // handled by BackendConnector, but we should enable the button again
            item.disabled = false;
            this.$set(self.sendDocumentTestResults, index, item);
          });
      }
      let callbackCancel = () => {
        item.disabled = false;
      }
      this.$refs.userConfirmation.doShowUserConfirmation(
        this.$t('delete_document_confirm_title'),
        this.$t('delete_document_confirm_message'),
        callbackConfirm,
        callbackCancel,
        this
      );
/*
      item.disabled = true;
      let index = this.sendDocumentTestResults.indexOf(item);
      this.$set(this.sendDocumentTestResults, index, item);
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/details/" +
        item.access_point +
        "/document_send_results/" +
        item.id +
        "/";
      let self = this;
      this.$refs.backend
        .delete_auth(url)
        .then(function(response) {
          self.fetchDocumentTestResults();
        })
        .catch(function(error) {
          // handled by BackendConnector, but we should enable the button again
          item.disabled = false;
          self.$set(self.sendDocumentTestResults, index, item);
        });
*/
    },
    showSendDocumentTestSendLog: function(item) {
      this.$refs.textViewer.doShowText(this.$t('system_log'), item.send_log);
    },
    showSendDocumentTestRequest: function(item) {
      this.$refs.textViewer.doShowText("Log", item.request);
    },
    showSendDocumentTestResponse: function(item) {
      this.$refs.textViewer.doShowText("Log", item.response);
    },
    goToAccessPoint: function(ap_id) {
      this.$router.push("/test_access_points/" + ap_id);
    },
    mlrResultIcon(item) {
      if (item.mlr_state) {
        if (item.mlr_state == "accepted") {
            return "success";
        } else if (item.mlr_state == "acknowledged") {
            return "warning";
        } else if (item.mlr_state == "rejected") {
            return "error";
        } else {
            console.error("Unknown status in MLR: " + item.mlr_state + ", assuming not_applicable");
            return "not_applicable";
        }
      } else {
        return "not_applicable";
      }
    },
    mlrValidIcon(item) {
      let data = JSON.parse(item.mlr_validation_summary);
      if (data.error_count > 0) {
        return "error";
      } else if (data.warning_count > 0) {
        return "warning";
      } else {
        return "success";
      }
    },
    mlrResultString(mlr_state) {
      if (mlr_state == 'accepted') {
        return this.$t('mlr_accepted');
      } else if (mlr_state == 'acknowledged') {
        return this.$t('mlr_acknowledged');
      } else if (mlr_state == 'rejected') {
        return this.$t('mlr_rejected');
      } else {
        return mlr_state;
      }
    },
    mlrValidString(mlr_validation_summary) {
      let data = JSON.parse(mlr_validation_summary);
      if (data.error_count > 0) {
        return this.$t('mlr_has_errors');
      } else if (data.warning_count > 0) {
        return this.$t('mlr_has_warnings');
      } else {
        return this.$t('mlr_validated');
      }
    },
    mlrTooltip(item) {
      if (item.mlr_state) {
        return this.mlrResultString(item.mlr_state) + ". " + this.mlrValidString(item.mlr_validation_summary) + ".";
      } else {
        return this.$t('mlr_not_received')
      }
    },
    doShowMLRDialog(item) {
        if (item.mlr_state) {
          this.showMLRDialog = true;
          this.$nextTick(() => {
              this.$refs.mlrValidationResults.setIntroductionText(this.mlrResultString(item.mlr_state));
              this.$refs.mlrValidationResults.setIntroductionColor(this.mlrResultIcon(item));
              this.$refs.mlrValidationResults.setData(
                  JSON.parse(item.mlr_validation_summary),
                  item.mlr
              );
          })
        } else {
          alert('no mlr');
        }
    }
  },
  mounted() {
    this.$store.dispatch("inspectToken");
    try {
      this.loadData();
    } catch (berr) {
      alert(
        "[XX] error in loadData for TestAccessPointsDetails.vue (mounted): " +
          berr
      );
    }
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "updateToken":
          this.unloadData();
          try {
            // TODO: maybe not reload on login but redirect?
            if (this.$refs && this.$refs.backend) {
              this.$nextTick(this.loadData);
            }
          } catch (berr) {
            alert(
              "[XX] error in loadData for TestAccessPointDetails.vue (sub updateToken): " +
                berr
            );
          }
          break;
        case "LOGOUT":
          this.unloadData();
          break;
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  },
  watch: {
    pagination: {
      handler(newData, oldData) {
        this.loadData();
      }
    }
  }
};
</script>

<style>
.fkbtn {
	background: none !important;
	color: inherit !important;
	border: none;
	padding: 0 !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
}

.fkbtn:focus {
	background: none !important;
	color: inherit !important;
	border: 2px solid #ff0000 !important;
	padding: 0 !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
}

</style>
