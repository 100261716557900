<template>
  <div>
      <v-container justify-center>
      <v-layout justify-center align-center>

      <v-spacer />
        <v-card class="elevation-12" color="white" >
          <v-card-title class="cardheader" mb-0 pb-0>
            <h1>{{ $t("title") }}</h1>
          </v-card-title>
          <v-card-text text-align="left">
            <div>
              <h3>{{ $t("home") }}</h3>
              <p>{{ $t("title_version") }}</p>
              <p>{{ $t("select_function") }}</p>
            </div>
          </v-card-text>
        </v-card>
      <v-spacer />
      </v-layout>
      </v-container>
      <v-container>

      <v-stepper v-model="e1" alt-labels>
        <v-stepper-header>
          <div v-for="(item, index) in chooser_breadcrumbs"
               v-bind:key="item">
            <v-stepper-step
              :complete="index < chooser_breadcrumbs.length - 1"
              :step="index + 1"
            >
              {{ $t(item) }}
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
              v-for="n in steps"
              :key="`${n}-content`"
              :step="n">
            <v-layout justify-center align-center>
            
              <v-btn
                v-if="e1 > 1"
                @click="chooserBackClicked"
                valign="center"
                fab
                color="primary"
                small
                :title="$t('button_back')"
                class="chooserItem"
              >
              <div class="large">&#8592;</div>
              </v-btn>

              <v-container class="hidden-sm-and-down">
                <v-row>
                  <v-col
                    v-for="(item,index) in choosers[chooser_current].options"
                    v-bind:key="index"
                  >
                    <v-card
                      color="primary"
                      hover
                      dark
                      v-on="choosers[chooser_current].options.length > 1 ? { click: () => chooserClicked(item) } : { }"
                      v-on:keydown.native.enter="chooserClicked(item);"
                      :style="getChooserStyle(item)"
                      active-class="activecard"
                    >
                      <v-card-title class="justify-center"><h2 class="text-h5"><button>{{ $t(item.title) }}</button></h2></v-card-title>
                      <v-card-text style="color: #ffffff;">{{ $t(item.description) }}</v-card-text>
                      <v-card-actions justify-center>
                          <v-spacer />
                          <v-btn text
                            v-if="item.component_link"
                            @click="goTo(item.component_link)"
                            class="white"
                            style="color: black;"
                          >
                            {{$t('chooser_goto') }} {{ $t(item.title) }}
                          </v-btn>
                          <v-btn
                            v-if="item.documentation_link"
                            :href="getHelpURL(item.documentation_link)"
                            class="white"
                            style="color: black;"
                            :title="$t(item.title) + ' ' + $t('chooser_documentation_link_text')"
                          >
                            {{ $t('chooser_documentation_link_text') }}
                          </v-btn>
                          <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="hidden-md-and-up">
                <v-row>
                  <v-col>
                    <v-card
                      v-for="(item,index) in choosers[chooser_current].options"
                      v-bind:key="index"
                      color="primary"
                      hover
                      dark
                      v-on="choosers[chooser_current].options.length > 1 ? { click: () => chooserClicked(item) } : { }"
                      v-on:keydown.native.enter="chooserClicked(item);"
                      :style="getChooserStyle(item)"
                      class="mb-4"
                    >
                      <v-card-title class="justify-center"><h3>{{ $t(item.title) }}</h3></v-card-title>
                      <v-card-text style="color: #ffffff;">{{ $t(item.description) }}</v-card-text>
                      <v-card-actions justify-center>
                      <v-layout class="mx-auto justify-center">
                      <v-row class="mx-auto justify-center">
                          <v-btn text
                            v-if="item.component_link"
                            @click="goTo(item.component_link)"
                            class="secondary mr-2 mb-2"
                            style="color: black;"
                            :title="$t(item.title)"
                          >
                            {{ $t(item.title) }}
                          </v-btn>
                          <v-btn
                            v-if="item.documentation_link"
                            :href="getHelpURL(item.documentation_link)"
                            class="secondary"
                            style="color: black;"
                            :title="$t('chooser_documentation_link_text')"
                          >
                            {{ $t('chooser_documentation_link_text') }}
                          </v-btn>
                      </v-row>
                      </v-layout>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-layout>


          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>


      </v-container>
  </div>
</template>

<script>

export default {
  name: "home",
  components: {
  },
  data () {
    return {
      e1: 1,
      steps: 5,
      chooser_current: "chooser_default",
      chooser_breadcrumbs: ["chooser_default"],
      // TODO:
      // Are the titles used? Can we replace uses with 'next' value?
      choosers: {
        "chooser_default": {
          "title": "Kies je functie",
          "options": [
            {
              "title": "chooser_sender",
              "description": "chooser_sender_option_description",
              "next": "chooser_sender"
            },
            {
              "title": "chooser_softwareprovider",
              "description": "chooser_softwareprovider_option_description",
              "next": "chooser_softwareprovider"
            },
            {
              "title": "chooser_serviceprovider",
              "description": "chooser_serviceprovider_option_description",
              "next": "chooser_serviceprovider"
            },
          ]
        },
        "chooser_sender": {
          "title": "Kies je doel om te testen",
          "options": [
            {
              "title": "chooser_sendtest",
              "description": "chooser_sendtest_option_description",
              "next": "chooser_sendtest"
            },
            {
              "title": "chooser_validate",
              "description": "chooser_validate_option_description",
              "next": "chooser_validate"
            },
            {
              "title": "chooser_discover",
              "description": "chooser_discover_option_description",
              "next": "chooser_discover"
            }
          ]
        },
        "chooser_sendtest": {
          "title": "Verstuurtest",
          "options": [
            {
                "title": "chooser_sendtest_title",
                "description": "chooser_sendtest_description",
                "component_link": "/sendtest",
                "documentation_link": "components/sendtest",
            }
          ]
        },
        "chooser_validate": {
          "title": "Documentvalidatie",
          "options": [
            {
                "title": "chooser_validate_title",
                "description": "chooser_validate_description",
                "component_link": "/validate",
                "documentation_link": "components/validate",
            }
          ]
        },
        "chooser_discover": {
          "title": "Verstuurtest",
          "options": [
            {
                "title": "chooser_discover_title",
                "description": "chooser_discover_description",
                "component_link": "/discover",
                "documentation_link": "components/discover",
            }
          ]
        },
        "chooser_softwareprovider": {
          "options": [
            {
                "title": "chooser_validate_title",
                "description": "chooser_validate_description",
                "component_link": "/validate",
                "documentation_link": "components/validate",
            }
          ]
        },
        "chooser_serviceprovider": {
          "options": [
            {
              "title": "chooser_accesspoint",
              "description": "chooser_accesspoint_option_description",
              "next": "chooser_accesspoint"
            },
            {
              "title": "chooser_validate",
              "description": "chooser_validate_option_description",
              "next": "chooser_validate"
            },
            {
              "title": "chooser_discover",
              "description": "chooser_discover_option_description",
              "next": "chooser_discover"
            }
          ]
        },
        "chooser_accesspoint": {
          "options": [
            {
                "title": "chooser_accesspoint_title",
                "description": "chooser_accesspoint_description",
                "component_link": "/test_access_points",
                "documentation_link": "serviceprovider_components/accesspoints",
                "require_account": true
            }
          ]
        }
      }
    }
  },
  methods: {
    chooserClicked: function(item) {
        if (item.next) {
            this.chooser_breadcrumbs.push(item.next)
            this.chooser_current = item.next;
            this.$nextTick(() => {
            this.e1 = this.e1 + 1;
            if (this.e1 > this.steps) {
                this.e1 = 1;
            }
            })
        }
    },
    chooserBackClicked: function(item) {
        if (this.e1 > 1) {
            this.e1 = this.e1 - 1;
            this.chooser_breadcrumbs.splice(-1,1)
            // TODO: remove; can get from breadcrumbs?
            if (this.e1 == 1) {
                this.chooser_current = "chooser_default";
            } else {
                this.chooser_current = this.chooser_breadcrumbs[this.chooser_breadcrumbs.length-1]
            }

            this.$nextTick(() => {
                let el = document.getElementById("navMenuLast")
                this.$nextTick(() => { 
                  el.focus()
                  el.blur()
                });
            });
        }
    },
    getChooserStyle(item) {
        if (item.next) {
            return {"cursor": "pointer" };
        } else {
            return "";
        }
    },
    getHelpURL(path) {
      if (this.$store.getters.getLanguage === "nl") {
        return process.env.VUE_APP_DOC_URL + "nl/" + path;
      } else {
        return process.env.VUE_APP_DOC_URL + path;
      }
    },
    goTo(path) {
        this.$router.push(path)
    },
    haveAccess(item) {
        return (!item.require_account) || (this.$store.getters.isLoggedIn && this.$store.getters.isParticipant);
    },

  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  }
};
</script>
<style scoped lang="stylus">
.large {
    font-size: 2em;
    font-weight: bold;
}
.v-stepper__step {
  border-color: red !important;
}
.v-stepper-step {
  background-color: red !important;
}
@media only screen and (max-width: 959px) {
  .v-stepper__label {
    display: flex !important;
  }
  .v-divider {
    display: none !important;
  }
}

</style>
