<template>
  <div>
      <ChangePassword />
  </div>
</template>

<script>
// @ is an alias to /src
import ChangePassword from "@/components/ChangePassword.vue";

export default {
  name: "home",
  components: {
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  }
};
</script>
