<template>
  <div>
    <v-layout>
    <v-row>
      <v-text-field
        :label="$t('iso6523_identifier')"
        value="0106:"
        v-model="iso6523_identifier"
        class="ml-4"
      ></v-text-field>
      <v-spacer></v-spacer>
      <MyAutocomplete
        :label="$t('change_scheme')"
        :items="iso6523_items"
        item-title-property="displayname",
        item-subtitle-property="name",
        item-value-property="code"
        :callback="iso6523SelectionChangedNew"
      />
    </v-row>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import MyAutocomplete from "@/components/MyAutocomplete.vue";

export default {
  name: "RecipientField",
  components: {
    MyAutocomplete
  },
  data() {
    return {
      iso6523_scheme_selected: "NL:VAT",
      iso6523_identifier: "0106:",
      iso6523_items: [],
    };
  },
  methods: {
    // TODO: we have this in more places now. is it called every time?
    // perhaps use vuex?
    load_ISO6523_entries: function() {
      var url =
        process.env.VUE_APP_ROOT_API + "/lookup/data/iso6523_identifiers";
      var self = this;
      axios.get(url).then(function(response) {
        for (var i = 0; i < response.data.length; i++) {
          var el = response.data[i];
          el.displayname = el.mnemonic + " (" + el.code + ")";
        }
        self.iso6523_items = response.data;
      });
    },
    iso6523SelectionChanged: function(value) {
      if (value) {
        var els = this.iso6523_identifier.split(":");
        if (els.length > 1) {
          this.iso6523_identifier = value + ":" + els[1];
        } else {
          this.iso6523_identifier = value + ":" + els[0];
        }
      }
    },
    iso6523SelectionChangedNew: function(name, description, value) {
      console.log("[XX] ISO6523changed to " + value);
      if (value) {
        var els = this.iso6523_identifier.split(":");
        if (els.length > 1) {
          this.iso6523_identifier = value + ":" + els[1];
        } else {
          this.iso6523_identifier = value + ":" + els[0];
        }
      }
    },
    // TODO: use v-model
    getIdentifier: function() {
      return this.iso6523_identifier;
    },
    setIdentifier: function(identifier) {
      this.iso6523_identifier = identifier;
    }
  },
  beforeMount() {
    this.load_ISO6523_entries();
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = this.$store.getters.getLanguage;
          }
          break;
      }
    });
  }
};
</script>
