<template>
  <div>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12" color="primary" dark>
              <v-card-text>
                <div class="home">
                  <h1>{{ $t("title") }}</h1>
                  <p>{{ $t("select_function") }}</p>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </div>
</template>

<script>

export default {
  name: "home",
  components: {
  },
  data() {
    return {
      valid: false,
      username: "",
      usernameRules: [v => !!v || this.$t("username_required")],
      password: "",
      passwordRules: [v => !!v || this.$t("password_required")]
    };
  },
  methods: {},
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  }
};
</script>
