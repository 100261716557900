<template>
  <v-dialog v-model="showUserConfirmation" width="800" aria-labelledby="confirm-title">
    <v-card>
      <v-card-title class="primary darken-2 white--text"><h1 id="confirm-title" class="text-h5">{{
        userInputTitle
      }}</h1></v-card-title>

      <v-card-text class="mt-5"><p>{{ userConfirmationMessage }}</p></v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="primary"
          v-if="callbackConfirm"
          @click="submit"
        >
          {{ $t("submit") }}
        </v-btn>
        <v-btn class="primary" v-if="callbackCancel" @click="cancel_and_abort">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardUserConfirmation",
  data() {
    return {
      userInputTitle: String,
      userConfirmationMessage: String,
      inputErrors: [],
      showUserConfirmation: false,
      buttons: [{ name: "ok", text: "$t{'input'}", form_must_be_valid: true }],
      callbackConfirm: false,
      callbackCancel: false,
      callbackArgument: null
    };
  },
  props: {},
  methods: {
    doShowUserConfirmation: function(
      title,
      message,
      callbackConfirm,
      callbackCancel,
      callbackArgument
    ) {
      // this is the actual initialization, so reset everything here
      this.valid = false;
      this.userInputTitle = title;
      this.userConfirmationMessage = message;
      this.showUserConfirmation = true;
      this.callbackConfirm = callbackConfirm;
      this.callbackCancel = callbackCancel;
      this.callbackArgument = callbackArgument;
    },
    validateInput: function() {
      if (this.inputdata === "") {
        this.inputErrors = [this.$t("input_empty_error")];
        this.valid = false;
      } else {
        this.inputErrors = [];
        this.valid = true;
      }
    },
    submit: function() {
      // TODO: callback for 'ok', maybe it can return whether there are errors?
      // or should that be another call
      this.showUserConfirmation = false;
      this.callbackConfirm(this.callbackArgument);
    },
    cancel_and_abort: function() {
      this.showUserConfirmation = false;
      this.callbackCancel();
    },
  }
};
</script>
