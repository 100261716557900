import "material-design-icons/iconfont/material-icons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "typeface-roboto/index.css";
//import "@babel/polyfill";
import Vue from "vue";
import VueCookies from "vue-cookies";
import vuetify from "./plugins/vuetify.js";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";
import JWTDecode from "jwt-decode";
import BackendConnector from "@/components/BackendConnector.vue";
import "prismjs";
//import "prismjs/plugins/line-numbers/prism-line-numbers.js";
import i18n from "./i18n";
import FlagIcon from "vue-flag-icon";
import moment from "moment";
//require("vuetify/src/stylus/app.styl");
//require("../node_modules/prism-themes/themes/.css");
require("../node_modules/prism-themes/themes/prism-a11y-dark.css");
//require("../node_modules/prismjs/plugins/line-numbers/prism-line-numbers.css");
import "./peppoltest.css";


Vue.filter("formatDateTime", function(value) {
  if (!value) return "";
  return moment(String(value)).format("YYYY-MM-DD HH:mm");
});

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(Vuex);
Vue.use(FlagIcon);
Vue.component("BackendConnector", BackendConnector);

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const LOGOUT = "LOGOUT";
const EXPIRED = "EXPIRED";
const LANG = "LANG";
const INITIALIZE = "INITIALIZE";
const BACKEND_STARTTIME = "BACKEND_STARTTIME";
const SESSION_COOKIE = "invt";
const SESSION_COOKIE_R = "invt_r";

const store = new Vuex.Store({
  state: {
    jwt: "",
    jwtRefresh: "",
    isLoggedIn: false,
    pending: false,
    errorMessage: "",
    email: "loading",
    language: "en",
    user: {},
    endpoints: {
      obtainJWT: process.env.VUE_APP_ROOT_API + "/auth/obtain",
      refreshJWT: process.env.VUE_APP_ROOT_API + "/auth/refresh"
    },
    backend_starttime: null
  },
  getters: {
    hasTOTPDevice: state => {
      return state.user.has_totp_device;
    },
    isLoggedIn: state => {
      return state.isLoggedIn;
    },
    getSessionExpiry: state => {
      if (state.jwt != null && state.jwt != '') {
        let decoded = JWTDecode(state.jwt);
        return decoded.exp;
      } else {
        return null;
      }
    },
    isLoginPending: state => {
      return state.pending;
    },
    email: state => {
      return state.user.email;
    },
    getJWT: state => {
      return state.jwt;
    },
    loginErrorMessage: state => {
      return state.errorMessage;
    },
    getLanguage: state => {
      return state.language;
    },
    getSettingsLanguage: state => {
      return state.user.language;
    },
    getAffiliation: state => {
      return state.user.affiliation;
    },
    getSMKAddress: state => {
      return state.user.smk_recipient_id;
    },
    isParticipant: state => {
      return state.user.is_participant;
    },
    isOnboarder: state => {
      return state.user.is_onboarder;
    },
    backendStarttime: state => {
      return state.backend_starttime;
    }
  },
  mutations: {
    [INITIALIZE](state) {
      if (localStorage.getItem("lang")) {
        // check for good values
        state.language = localStorage.getItem("lang");
        if (state.language != "en" && state.language != "nl") {
          state.language = "en";
        }
        document.getElementsByTagName("html")[0].lang = state.language;
        axios.defaults.headers.common["Accept-Language"] = state.language;
      }
      let cookieData = window.$cookies.get(SESSION_COOKIE);
      if (cookieData) {
        let decoded = JWTDecode(cookieData);
        state.jwt = cookieData;
        state.isLoggedIn = true;
        state.email = decoded.email;
        this.dispatch("loadUserData");
      }
      let cookieDataRefresh = window.$cookies.get(SESSION_COOKIE_R);
      if (cookieDataRefresh) {
        let decoded = JWTDecode(cookieDataRefresh);
        state.jwtRefresh = cookieDataRefresh;
      }
    },
    [LOGIN](state) {
      state.pending = true;
      state.errorMessage = "";
    },
    [LOGIN_SUCCESS](state) {
      state.isLoggedIn = true;
      state.pending = false;
      state.errorMessage = "";
      this.dispatch("loadUserData");
    },
    [LOGIN_FAILED](state) {
      state.isLoggedIn = false;
      state.pending = false;
    },
    [LOGOUT](state) {
      state.isLoggedIn = false;
      state.jwt = "";
      state.errorMessage = "";
      state.userProfile = {};
      window.$cookies.remove(SESSION_COOKIE);
    },
    [LANG](state, newLang) {
      state.language = newLang;
      if (state.language != "en" && state.language != "nl") {
        state.language = "en";
      }
      localStorage.setItem("lang", state.language);
    },
    [EXPIRED](state) {
      // essentially the same as (manual) logout, but separate so
      // we can prompt re-login
      // this.commit(LOGOUT)
      // Note: we should probably redirect to a general login page
      state.jwt = ''
      state.errorMessage = ''
      state.userProfile = {}
      window.$cookies.remove(SESSION_COOKIE)
      state.isLoggedIn = false
    },
    updateToken(state, newToken) {
      state.jwt = newToken;
      window.$cookies.set(
        SESSION_COOKIE,
        newToken,
        3600,
        "/",
        process.env.VUE_MAIN_DOMAIN,
        process.env.VUE_APP_PRODUCTION === "true"
      );
    },
    updateRefreshToken (state, newRefreshToken) {
      state.jwtRefresh = newRefreshToken;
      window.$cookies.set(
        SESSION_COOKIE_R,
        newRefreshToken,
        3600,
        '/',
        process.env.VUE_MAIN_DOMAIN,
        process.env.VUE_APP_PRODUCTION === 'true'
      )
      // window.$cookies.set(SESSION_COOKIE, newToken, null, null, null, true)
    },
    updateUserData(state, newProfile) {
      state.user = newProfile;
      if (state.user.language) {
        this.commit(LANG, state.user.language);
      }
    },
    removeToken(state) {
      // localStorage.removeItem('t')
      state.jwt = null;
      state.jwtRefresh = null;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    [BACKEND_STARTTIME](state, starttime) {
      state.backend_starttime = starttime;
    }
  },
  actions: {
    login_orig({ commit }, creds) {
      commit(LOGIN); // show spinner
      return new Promise(resolve => {
        setTimeout(() => {
          commit(LOGIN_SUCCESS);
          resolve();
        }, 1000);
      });
    },
    logout({ commit }) {
      commit(LOGOUT);
    },
    expired({ commit }) {
      commit(EXPIRED);
    },
    login({ commit }, { email, password, totp }) {
      commit(LOGIN);

      let credentials = {
        email: email,
        password: password,
        totp: totp
      };

      try {
        return new Promise((resolve, reject) => {
          axios
            .post(this.state.endpoints.obtainJWT, credentials)
            .then(response => {
              this.commit("updateToken", response.data.access);
              this.commit("updateRefreshToken", response.data.refresh);
              this.state.email = email;
              this.commit(LOGIN_SUCCESS);
              resolve();
            })
            .catch(error => {
              this.commit(LOGIN_FAILED);
              if (error.response) {
                this.commit(
                  "setErrorMessage",
                  i18n.t('login_error')
                );
              } else {
                console.trace(error);
                console.log(error);
                // TODO: use i18n here? (use error.name instead of error.message
                // and put in a human-readable message here
                reject(new Error("BACKEND_CONN_FAILED"));
              }
            });
        });
      } catch (error) {
        alert(
          "error in POST for user credentials. This may be a Microsoft Edge issue. Please try Chrome or Firefox while we are working to solve this issue"
        );
      }
    },
    refreshToken() {
      const payload = {
        refresh: this.state.jwtRefresh
      };

      axios
        .post(this.state.endpoints.refreshJWT, payload)
        .then(response => {
          this.commit("updateToken", response.data.access);
        })
        .catch(error => {
          console.log(error);
        });
    },
    inspectToken() {
      const token = this.state.jwt;
      if (token) {
        const decoded = JWTDecode(token);
        const exp = decoded.exp;
        const originalIAT = decoded.iat;

        // refresh automatically upon activity after 10 minutes
        if (
          exp - Date.now() / 1000 < 1800
        ) {
          this.dispatch("refreshToken");
          this.dispatch("loadUserData");
        } else if (
          exp - Date.now() / 1000 < 3600
        ) {
          // DO NOTHING, DO NOT REFRESH
          //console.log(
          //  "[XX] token ok, do nothing expiry diff: " +
          //    (exp - Date.now() / 1000)
          //);
        } else {
          this.commit(EXPIRED);
        }
      }
    },
    loadUserData() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/auth/user_data", {
          headers: { Authorization: "JWT " + this.state.jwt }
        })
        .then(response => {
          let profile = response.data;
          this.commit("updateUserData", profile);
        })
        .catch(error => {
          console.log(error);
        });
    },
    setLanguage(context, language) {
      this.commit(LANG, language.lang);
      // Set the language for API requests
      axios.defaults.headers.common["Accept-Language"] = language.lang;
      // Update the html tag as well
      document.documentElement.setAttribute('lang', language.lang);
    },
    // The backend sends a specific signal (in our case, a startup time)
    // so that we can ensure the page is reloaded after a version change
    // This method checks that string and reloads when necessary
    checkBackendStartupTime(state, starttime) {
      console.log(
        "Check if backend was updated, reloading page, was: " +
          this.state.backend_starttime +
          ", now: " +
          starttime
      );
      if (starttime == null) {
        return;
      }
      if (this.state.backend_starttime == null) {
        this.commit(BACKEND_STARTTIME, starttime);
      } else if (
        this.state.backend_starttime &&
        starttime &&
        this.state.backend_starttime != starttime
      ) {
        console.log(
          "Backend has been updated, reloading page, was: " +
            this.state.backend_starttime +
            ", now: " +
            starttime
        );
        window.location.reload(true);
      }
    }
  }
});

new Vue({
  vuetify,
  router,
  render: h => h(App),
  i18n,
  store,
  theme: {},
  beforeCreate() {
    this.$store.commit("INITIALIZE");
  }
}).$mount("#app");
