<template>
  <div>
      <v-card>
        <v-card-title class="cardheader">
          <h1 class="text-h5" lang="en">{{ $t('sitemap') }}</h1>
        </v-card-title>
        <v-card-text class="sitemap">
          <v-container fluid fill-height>
            <ul>
              <li v-for="item in items">
                <div class="sitemap">{{ $t(item.name) }}</div>
                <ul>
                  <li v-for="subitem in item.children">
                    <div v-if="subitem.to"><a class="sitemap" :href="getLangLink(subitem.to)">{{ $t(subitem.name) }}</a></div>
                    <div v-else>
                      <div class="sitemap">{{ $t(subitem.name) }}</div>
                      <ul>
                        <li v-for="subsubitem in subitem.children">
                          <div v-if="subsubitem.to"><a class="sitemap" :href="getLangLink(subsubitem.to)" :title="getTitle(subsubitem)">{{ $t(subsubitem.name) }}</a></div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </v-container>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  data() {
    return {
      items: [
       {
          id: 1,
          name: 'general_pages',
          children: [
            { name: 'home', to: '/' },
            { name: 'about', to: '/about' },
            { name: 'privacy', to: '/documentation/additional/privacy_statement/' },
            { name: 'changelog', to: '/documentation/additional/changelog/' },
            { name: 'register', to: '/register' },
            //{ name: ''), to: '/' },
          ],
        },
       {
          id: 2,
          name: 'components',
          children: [
            { name: 'discover', to: '/discover' },
            { name: 'validate', to: '/validate' },
            { name: 'sendtest_title', to: '/sendtest' },
            { name: 'connectioncoach_title', to: '/applicationtest' },
            { name: 'test_access_points', to: '/test_access_points' },
            { name: 'sent_documents', to: '/sent_documents' },
            { name: 'received_documents', to: '/received_documents' },
          ],
        },
       {
          id: 3,
          name: 'documentation',
          to: '/documentation',
          children: [
            {
              name: 'introduction',
              children: [
                { name: 'whatsthis', to: '/documentation/introduction/whatsthis' },
                { name: 'registration', to: '/documentation/introduction/registration' },
              ]
            },
            {
              name: 'components',
              children: [
                { name: 'discover', to: '/documentation/components/discover' },
                { name: 'validate', to: '/documentation/components/validate' },
                { name: 'sendtest_title', to: '/documentation/components/sendtest' },
              ]
            },
            {
              name: 'serviceprovider_components',
              children: [
                { name: 'test_access_points', to: '/documentation/serviceprovider_components/accesspoints' },
                { name: 'received_documents', to: '/documentation/serviceprovider_components/received_documents' },
                { name: 'sent_documents', to: '/documentation/serviceprovider_components/sent_documents' },
              ]
            },
            {
              name: 'additional',
              children: [
                { name: 'message_level_response', to: '/documentation/additional/message_level_response' },
                { name: 'production_access_point_validation', to: '/documentation/additional/production_access_point_validation' },
                { name: 'accessibility', to: '/documentation/additional/accessibility' },
              ]
            },
          ],
        },
      ]
    }
  },
  methods: {
    isRouterLink: function(path) {
      if (path.startsWith('/documentation')) {
        return false;
      } else {
        return true;
      }
    },
    getLangLink: function(path) {
      if (path.startsWith('/documentation')) {
        if (this.$store.getters.getLanguage === "nl") {
          return path.replace("/documentation", "/documentation/nl");
        }
      }
      return path;
    },
    getTitle: function(item) {
      if (item.to.startsWith("/documentation")) {
		return this.$t(item.name) + ' ' + this.$t('documentation');
      }
	  return null;
    }
  },
  components: {
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  }
};
</script>
<style scoped lang="stylus">
.sitemap {
    font-size: 1em;
    margin: 5px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
}
.sitemap ul {
    list-style-type: none;
}
</style>
