<template>
    <div>
        <div class="home">
          <Discover></Discover>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Discover from "@/components/Discover.vue";

export default {
  name: "home",
  components: {
    Discover,
  }
};
</script>
