<template>
  <div>
    <div>
      <v-tabs color="secondary" dark slider-color="yellow">
        <v-tab>Open requests</v-tab>
        <v-tab-item>
          <div>
            <v-btn class="primary" @click="reloadButtonClicked">{{
              $t("reload")
            }}</v-btn>
            <v-data-table
              :headers="testresultHeaders"
              :items="openRequests"
              hide-actions0
            >
              <template slot="item" slot-scope="props">
                <tr v-if-not="props.item.onboarder_extended_granted">
                  <td class="text-left">
                    {{
                      props.item.onboarder_extended_request_date
                        | formatDateTime
                    }}
                  </td>
                  <td class="text-left">
                    {{ props.item.user.organization }}
                  </td>
                  <td class="text-left">{{ props.item.user.email }}</td>
                  <td class="text-left">{{ props.item.pki_instance }}</td>
                  <td class="text-left">{{ props.item.accesspoint_id }}</td>
                  <td class="text-left">{{ props.item.url }}</td>
                  <td class="text-left">
                    <v-btn
                      small
                      @click="downloadTestReport(props.item)"
                      class="primary"
                      >{{ $t("extended_test_download") }}</v-btn
                    >
                  </td>
                  <td class="text-left">
                    <v-btn
                      small
                      @click="approveTestAccessPoint(props.item)"
                      class="primary"
                      >{{ $t("approve") }}</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>

        <v-tab>Approved access points</v-tab>
        <v-tab-item>
          <div>
            <v-btn class="primary" @click="reloadButtonClicked">{{
              $t("reload")
            }}</v-btn>
            <v-data-table
              :headers="testresultHeaders"
              :items="approvedRequests"
              hide-actions0
            >
              <template slot="item" slot-scope="props">
                <tr v-if="props.item.onboarder_extended_granted">
                  <td class="text-left">
                    {{
                      props.item.onboarder_extended_request_date
                        | formatDateTime
                    }}
                  </td>
                  <td class="text-left">
                    {{ props.item.user.organization }}
                  </td>
                  <td class="text-left">{{ props.item.user.email }}</td>
                  <td class="text-left">{{ props.item.pki_instance }}</td>
                  <td class="text-left">{{ props.item.accesspoint_id }}</td>
                  <td class="text-left">{{ props.item.url }}</td>
                  <td class="text-left">
                    <v-btn
                      small
                      @click="downloadTestReport(props.item)"
                      class="primary"
                      >{{ $t("extended_test_download") }}</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>

    <BackendConnector ref="backend"></BackendConnector>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "testresult_approval",
  components: {
  },
  data() {
    return {
      activeTab: "testresults",
      testresultItems: [],
      testresultHeaders: [
        { text: this.$t("date"), value: "date" },
        { text: this.$t("affiliation"), value: "affiliation" },
        { text: this.$t("user"), value: "user" },
        { text: this.$t("certificate_type"), value: "type" },
        { text: this.$t("PEPPOL ID"), value: "peppol_id" },
        { text: this.$t("URL"), value: "url" }
      ]
    };
  },
  methods: {
    loadData: function() {
      // TMP: mock data
      var url =
        process.env.VUE_APP_ROOT_API + "/onboarding/accesspoint_approval/";
      var self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        //alert(JSON.stringify(response.data))
        self.testresultItems = response.data;
        // }).catch(function (error) {
        // handled by BackendConnector
      });
    },
    unloadData: function() {
      this.testresultItems = [];
    },
    reloadButtonClicked: function() {
      this.loadData();
    },
    approveTestAccessPoint: function(accesspoint) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/grant_approval_request/" +
        accesspoint.id;
      var self = this;
      this.$refs.backend.post_auth(url).then(function(response) {
        alert("ok");
        self.loadData();
      });
    },
    downloadTestReport: function(accesspoint) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/test_report/" +
        accesspoint.id;
      //alert(url)
      this.$refs.backend.download_auth(url);
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    }
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "updateToken":
          this.unloadData();
          try {
            // TODO: maybe not reload on login but redirect?
            if (this.$refs && this.$refs.backend) {
              this.$nextTick(this.loadData);
            }
          } catch (berr) {
            alert(
              "[XX] error in loadData for TestAccessPointDetails.vue (sub updateToken): " +
                berr
            );
          }
          break;
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
        case "LOGOUT":
          this.unloadData();
          break;
      }
    });
    try {
      this.loadData();
    } catch (berr) {
      console.log(berr);
    }
  },
  watch: {
    isLoggedIn: function() {
      alert("logged in!");
    }
  },
  computed: {
    openRequests: function() {
      let result = [];
      for (let i = 0; i < this.testresultItems.length; i++) {
        if (!this.testresultItems[i].onboarder_extended_granted) {
          result.push(this.testresultItems[i]);
        }
      }
      return result;
    },
    approvedRequests: function() {
      let result = [];
      for (let i = 0; i < this.testresultItems.length; i++) {
        if (this.testresultItems[i].onboarder_extended_granted) {
          result.push(this.testresultItems[i]);
        }
      }
      return result;
    }
  }
};
</script>
