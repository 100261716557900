<template>
    <div>
      <v-layout wrap align-center justify-center>
        <v-card
          style="min-height:600px; max-width: 600px; padding:40px;"
          class="elevation-20"
        >
          <div class="text-center" style="max-width: 500px">
            <h2>{{ $t("title") }}</h2>
            <p>{{ $t("intro1") }}</p>
            <p>{{ $t("intro2") }}</p>
            <p>{{ $t("intro3") }}</p>
          </div>
          <div>
            <v-btn large @click.native="check('me')" color="secondary"
              >Check the SI test tool (beta)</v-btn
            >
            <v-btn large @click.native="check('tt')" color="secondary"
              >Check tt.simplerinvoicing.org</v-btn
            >
            <v-btn large @click.native="check('tttest')" color="secondary"
              >Check tt-test.simplerinvoicing.org</v-btn
            >
            <v-btn large @click.native="check('tjeb')" color="secondary"
              >Check tjeb.nl (will fail; not an AS2 AP)</v-btn
            >
            <v-btn large @click.native="check('bad')" color="secondary"
              >Check sidn.nl (will fail; not allowed)</v-btn
            >
          </div>
        </v-card>
      </v-layout>
      <TestTaskViewer
        v-on:error="testTaskDone()"
        ref="taskViewer"
      ></TestTaskViewer>
    </div>
</template>

<script>
import TestTaskViewer from "@/components/TestTaskViewer.vue";

export default {
  name: "about",
  components: {
    TestTaskViewer,
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  },
  methods: {
    check: function(arg) {
      this.$refs.taskViewer
        .run(process.env.VUE_APP_ROOT_API_WS + "/certificate_check/" + arg)
        .then(function(result) {
          // ok, nothing to do here
        })
        .catch(function(error) {
          alert("error! " + error);
        });
    },
    testTaskDone: function() {
      this.unloadData();
      this.loadData();
      this.showActionDialog = false;
    }
  }
};
</script>
