<!--

Status icon with 4 options:
- success (green checkmark)
- error (red cross)
- warning (yellow warning)
- not_applicable (grey empty symbol)

Properties:
status (string, boolean, or null):
- 'success', 'true' or true: success
- 'error', 'false' or false: error
- 'warning': warning
- 'not_applicable' or null: not_applicable
- 'waiting': a clock

Example:

<ResultIcon v-bind:status="props.item.document_delivered" :tooltip="props.item.response_status_message"></ResultIcon>

-->

<template>
  <div>
    <div v-if="tooltip" bottom>
        <v-icon v-on="on" v-if="sresult === 'success'" color="success" :title="tooltip" aria-hidden="false"
          >check_circle</v-icon
        >
        <v-icon v-on="on" v-else-if="sresult === 'error'" color="error" :title="tooltip" aria-hidden="false"
          >error</v-icon
        >
        <v-icon v-on="on" v-else-if="sresult === 'warning'" color="warning" :title="tooltip" aria-hidden="false"
          >warning</v-icon
        >
        <v-icon v-on="on" v-else-if="sresult === 'waiting'" color="warning" :title="tooltip" aria-hidden="false"
          >access_time</v-icon
        >
        <v-icon v-on="on" v-else :title="tooltip" aria-hidden="false">not_interested</v-icon>
    </div>
    <div v-else>
      <div>
      <v-icon v-if="sresult === 'success'" color="success" :title="$t('status_icon_success')" aria-hidden="false"
        >check_circle</v-icon
      >
      <v-icon v-else-if="sresult === 'error'" color="error" :title="$t('status_icon_error')" aria-hidden="false"
        >error</v-icon
      >
      <v-icon v-else-if="sresult === 'warning'" color="warning" :title="$t('status_icon_warning')" aria-hidden="false"
        >warning</v-icon
      >
      <v-icon v-else-if="sresult === 'waiting'" color="warning" :title="$t('status_icon_waiting')" aria-hidden="false"
        >access_time</v-icon
      >
      <v-icon v-else :title="$t('status_icon_not_applicable')" aria-hidden="false">not_interested</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusIcon",
  data() {
    return {};
  },
  props: {
    // can be true, false, or null
    status: null,
    tooltip: String
  },
  computed: {
    sresult: function() {
      if (
        this.status === "success" ||
        this.status === "true" ||
        this.status === true
      ) {
        return "success";
      } else if (
        this.status === "error" ||
        this.status === "false" ||
        this.status === false
      ) {
        return "error";
      } else if (this.status === "warning") {
        return "warning";
      } else if (this.status === "waiting") {
        return "waiting";
      } else {
        return "not_applicable";
      }
    }
  },
  methods: {},
  mounted() {}
};
</script>
