<template>
  <div>
      <v-container fluid fill-height>
        <v-layout>
          <v-flex xs10 offset-sm1>
            <v-card>
              <v-card-title class="cardheader">
                <h1 class="text-h5">{{ $t("sendtest_title") }}</h1>

                <v-layout wrap justify-end>
                  <v-card-actions class="text-center">
<template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click.stop="" :href="getHelpURL" :title="$t('button_help')+$t('sendtest_title')"
                      ><v-icon large color="primary">help</v-icon></v-btn
                    >
</template>
                  </v-card-actions>
                </v-layout>
              </v-card-title>
              <v-card-text>
                <v-layout grid-list-xs pt-0 mt-0>
                  <v-flex
                    xs12
                    class="text-left text-sm-left text-md-left text-lg-left"
                    mt-0 pt-0
                  >
                    <div>
                      {{ $t("sendtest_description1") }}
                    </div>
                    <div>
                      {{ $t("sendtest_description2") }}
                    </div>
                    <div ml-6 pl-6>
                      <b>{{ $t("sendtest_description3") }}</b>
                    </div>
                    <div class="mt-3">
                      {{ $t("sendtest_description4") }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout grid-list-xs>
                  <v-flex
                    xs12
                    class="text-center text-sm-center text-md-center text-lg-center mt-3"
                  >
                    <v-form
                      ref="fetchForm"
                      @submit.prevent="fetchReceivedInvoices"
                    >
                      <v-text-field
                        outline
                        :label="$t('sendtest_sender_id')"
                        v-model="sender_id"
                        :rules="requiredRule"
                        required
                        :placeholder="$t('sendtest_sender_id')"
                      ></v-text-field>
                      <v-text-field
                        outline
                        :label="$t('sendtest_invoice_id')"
                        v-model="invoice_id"
                        :rules="requiredRule"
                        :placeholder="$t('sendtest_invoice_id')"
                        required
                      ></v-text-field>
                      <v-btn small class="mt-0" type="submit" color="primary">{{
                        $t("sendtest_search_received_invoices")
                      }}</v-btn>
                    </v-form>
                  </v-flex>
                </v-layout>
                <div class="mt-3">
                  {{ $t("sendtest_description6") }}
                </div>
              <v-flex xs4>
                <v-layout wrap mt-0 mb-0 v-if="receivedInvoiceCount > 0">
                  <v-icon>search</v-icon>
                  <v-text-field
                    v-model="filter"
                    :label="$t('sendtest_filter_results')"
                    :clearable="true"
                    @click:clear="filterCleared()"
                    @change="fetchReceivedInvoices()"
                  />
                </v-layout>
              </v-flex>
                <v-layout grid-list-xs class="mt-3">
                  <v-flex
                    xs12
                    class="text-center text-sm-center text-md-center text-lg-center"
                  >
                    <v-data-table
                      v-if="receivedInvoiceCount > 0"
                      :options.sync="pagination"
                      :serverItemsLength="receivedInvoiceCount"
                      :items="invoices"
                      :headers="invoiceHeaders"
                      :footer-props="{
                        itemsPerPageOptions:[20]
                      }"
                      :loading="loading"
                      must-sort
                      hide-actions0
                    >
                      <template v-slot:item.creation_date="{ item }">
                        {{ item.creation_date | formatDateTime }}
                      </template>
                      <template v-slot:item.invoice_validation="{ item }">
                        <v-btn
                          icon
                          class="text-left"
                          @click="showMessages(item)"
                          style="cursor: pointer;"
                        >
                          <StatusIcon
                            :status="getStatus(item.document)"
                            :tooltip="getSummary(item.document)"
                          />
                        </v-btn>
                      </template>
                      <template v-slot:item.download="{ item }">
                        <v-btn
                          icon
                          class="text-left"
                          @click="downloadDocument(item)"
                          :title="$t('download')">
                          <v-icon color="primary">file_download</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:item.mlr="{ item }">
                        <v-btn class="text-left"
                            @click="doShowMLRDialog(item.document)"
                            icon>
                          <StatusIcon
                            v-bind:status="mlrStatusIcon(item.document)"
                            :tooltip="
                              mlrTooltip(item.document)
                            "
                          />
                        </v-btn>
                      </template>
                      <template v-slot:item.delete="{ item }">
                        <div
                          v-if="$route.query.code"
                          @click="deleteDocument(item)"
                          :title="$t('delete')"
                        >
                          <v-icon color="error">delete</v-icon>
                        </div>
                      </template>

                      <template v-slot:item.invoice_response="{ item }">
                        <v-menu v-if="item.document.transport_pki" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              fab
                              small
                              class="secondary black--text"
                              v-on="on"
                              color="secondary"
                              dark
                              :title="$t('invoice_response_send')"
                            >
                              <v-icon>reply</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title><i>{{ $t("invoice_response_send_with_status") }}</i></v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="sendInvoiceResponse(item.document, 'AP')"
                            >
                              <v-list-item-title>{{
                                $t("invoice_response_accepted")
                              }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="sendInvoiceResponse(item.document, 'RE')"
                            >
                              <v-list-item-title>{{
                                $t("invoice_response_rejected")
                              }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="sendInvoiceResponse(item.document, 'PD')"
                            >
                              <v-list-item-title>{{
                                $t("invoice_response_paid")
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <StatusIcon v-else :tooltip="$t('invoice_response_not_available')"/>
                      </template>

                    </v-data-table>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

    <v-dialog v-model="showMLRDialog">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
<template v-slot:activator="{ on }">
            <v-btn
              icon
              color="primary"
              v-on="on"
              @click.stop="showMLRDialog = false"
              ><v-icon>close</v-icon></v-btn
            >
</template>
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <ValidationResults ref="mlrValidationResults" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <StandardUserMessage ref="userMsg"></StandardUserMessage>
    <TestTaskViewer
      v-on:error="testTaskDone()"
      ref="taskViewer"
    ></TestTaskViewer>
    <BackendConnector ref="backend"></BackendConnector>
  </div>
</template>

<script>
// @ is an alias to /src
import StatusIcon from "@/components/StatusIcon.vue";
import StandardUserMessage from "@/components/StandardUserMessage.vue";
import TestTaskViewer from "@/components/TestTaskViewer.vue";
import ValidationResults from "@/components/ValidationResults.vue";

export default {
  name: "SendTest",
  data() {
    return {
      sender_id: this.$route.query.identifier,
      invoice_id: this.$route.query.invoice,
      loading: false,
      invoices: [],
      filter: null,
      pagination: {
        sortBy: ["creation_date"],
        descending: true,
        rowsPerPage: 10,
        page: 1
      },
      requiredRule: [v => !!v || this.$t("required_field")],
      showMLRDialog: false,
    };
  },
  components: {
    StatusIcon,
    StandardUserMessage,
    TestTaskViewer,
    ValidationResults
  },
  computed: {
    receivedInvoiceCount: function() {
      return this.invoices.length;
    },
    invoiceHeaders: function() {
      let headers = [
        { text: this.$t("date"), value: "creation_date" },
        { text: this.$t("sendtest_invoice_id"), value: "invoice_id" },
        {
          text: this.$t("sendtest_invoice_supplier_company"),
          value: "invoice_supplier_company"
        },
        {
          text: this.$t("sendtest_invoice_validation"),
          value: "invoice_validation"
        },
        { text: this.$t("sendtest_invoice_download"), value: "download" },
        { text: this.$t("mlr"), value: "mlr" },
        { text: this.$t("invoice_response"), value: "invoice_response" }
      ];
      if (this.$route.query.code) {
        headers.push({ text: this.$t("delete"), value: "delete" })
      }
      return headers;
    },
    getHelpURL() {
      if (this.$store.getters.getLanguage === "nl") {
        return "/documentation/nl/components/sendtest/";
      } else {
        return "/documentation/components/sendtest/";
      }
    }
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
    if (this.$route.query.identifier && this.$route.query.invoice) {
      this.fetchReceivedInvoices();
    }
  },
  methods: {
    fetchReceivedInvoices: function() {
      if (!this.$refs.fetchForm.validate()) {
        return;
      }
      this.loading = true;
      var url =
        process.env.VUE_APP_ROOT_API +
        "/receiver/sendtest/" +
        this.sender_id +
        "/" +
        this.invoice_id;
      if (this.filter) {
        url = url + "?filter=" + this.filter
      }
      var self = this;
      this.$refs.backend
        .get(url, {
          // no headers
        })
        .then(response => {
          this.invoices = response.data;
          //alert(JSON.stringify(response.data, null, 2));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteDocument: function(invoice) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/receiver/sendtest/delete/" +
        invoice.document.sbdh_sender +
        "/" +
        invoice.invoice_id +
        "/" +
        this.$route.query.code;
      this.$refs.backend.delete(url, {}).then(() => {
        this.fetchReceivedInvoices();
      });
    },
    getStatus: function(invoice) {
      if (invoice.transport_errors > 0 || invoice.validation_errors > 0) {
        return "error";
      } else if (
        invoice.transport_warnings > 0 ||
        invoice.validation_warnings > 0
      ) {
        return "warning";
      } else {
        return "success";
      }
    },
    getSummary: function(invoice) {
      let error_count = invoice.transport_errors + invoice.validation_errors;
      let warning_count =
        invoice.transport_warnings + invoice.validation_warnings;
      return this.$t("sendtest_validation_summary", {
        warnings: warning_count,
        errors: error_count
      });
    },
    showMessages: function(invoice) {
      let message_table = [];
      let transport_data = null;
      let validation_data = null;
      try {
        transport_data = JSON.parse(invoice.document.transport_summary);
      } catch (error) {
        // ignore unparseable XML (empty string for instance)
      }
      try {
        validation_data = JSON.parse(invoice.document.validation_summary);
      } catch (error) {
        // ignore unparseable XML (empty string for instance)
      }
      
      let all_data = {
        'warnings': [],
        'warning_count': 0,
        'errors': [],
        'error_count': 0
      }
      
      if (transport_data && (transport_data['error_count'] > 0 || transport_data['warning_count'] > 0)) {
        all_data['warning_count'] += transport_data['warning_count'];
        all_data['error_count'] += transport_data['error_count'];
        for (let i=0; i<transport_data['warnings'].length; i++) {
          if (transport_data['warnings'][i]['text']) {
            transport_data['warnings'][i]['message'] = transport_data['warnings'][i]['text']
          }
          all_data['warnings'].push(transport_data['warnings'][i]);
        }
        for (let i=0; i<transport_data['errors'].length; i++) {
          if (transport_data['errors'][i]['text']) {
            transport_data['errors'][i]['message'] = transport_data['errors'][i]['text']
          }
          all_data['errors'].push(transport_data['errors'][i]);
        }
      }
      if (validation_data && (validation_data['error_count'] > 0 || validation_data['warning_count'] > 0)) {
        all_data['warning_count'] += validation_data['warning_count'];
        all_data['error_count'] += validation_data['error_count'];
        for (let i=0; i<validation_data['warnings'].length; i++) {
          if (validation_data['warnings'][i]['text']) {
            validation_data['warnings'][i]['message'] = validation_data['warnings'][i]['text']
          }
          all_data['warnings'].push(validation_data['warnings'][i]);
        }
        for (let i=0; i<validation_data['errors'].length; i++) {
          if (validation_data['errors'][i]['text']) {
            validation_data['errors'][i]['message'] = validation_data['errors'][i]['text']
          }
          all_data['errors'].push(validation_data['errors'][i]);
        }
      }

      this.showMLRDialog = true;
      this.$nextTick(() => {
      this.$refs.mlrValidationResults.setData(
          all_data,
          null
      );
      });

    },
    getMessagesFromSummary(table, prefix, summary) {
      if (summary) {
        for (let i = 0; i < summary.length; i++) {
          if (summary[i].text) {
            table.push([prefix, summary[i].text]);
          } else {
            table.push([prefix, summary[i]]);
          }
        }
      }
    },
    downloadDocument(item) {
      window.location.href = this.getDownloadURL(item)
    },
    getDownloadURL(invoice) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/receiver/sendtest/download/" +
        invoice.document.sbdh_sender +
        "/" +
        invoice.invoice_id +
        "/" +
        invoice.id;
      return url;
    },
    mlrStatusIcon(item) {
      if (item.mlr_state == 'not sent') {
        return "not_applicable";
      } else if (item.mlr_state == 'sent') {
        return "success";
      } else if (item.mlr_state == 'error') {
        return "error";
      }
    },
    mlrTooltip(item) {
      if (item.mlr_state == 'not sent') {
        return this.$t('mlr_not_sent');
      } else if (item.mlr_state == 'sent') {
        return this.$t('mlr_sent');
      } else if (item.mlr_state == 'error') {
        return this.$t('mlr_error') + item.mlr_send_error;
      }
    },
    doShowMLRDialog(item) {
        if (item.mlr_state && item.mlr_state != "not sent") {
            this.showMLRDialog = true;
            this.$nextTick(() => {
            this.$refs.mlrValidationResults.setData(
                {},
                item.mlr
            );
            });
        }
    },
    sendInvoiceResponse(item, responseCode) {
      let url = process.env.VUE_APP_ROOT_API_WS +
                "/accesspoints/send_invoice_response/" +
                item.id + "/" + responseCode;

      let args = { 'doc_id': item.id, 'response_code': responseCode }
      this.$refs.taskViewer.run(url, args).then((result) => {
          // success
          console.log("Send Invoice Response Success!");
      }).catch((error) => {
          // error
          console.error("Send invoice response failure!");
      })
    }
  }
};
</script>
