<!--
  Replacement select because v-select fails accessilibty requirements

  By default the items passed should have the following properties:
  - title (mandatory)
  - subtitle (optional, will be shown below title, and will be taken
    into account for autocomplete)
  - value (not shown, used in callback if set)
  
  You can override the property names with the props item-title-name, item-subtitle, item-value

  emit seems to not work reliably, so there is a callback prop, which
  gets the following arguments: title, subtitle, value
  
-->

<template>
  <!-- aria thing here -->
  <div 
   class="text-center"
   :id="uniqId('menudiv')"
  >
    <v-menu offset-y
     :attach="uniqId('#menudiv')"
     v-model="expanded"
     max-height="30vh"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :id="uniqId('menubutton')"
          v-bind="attrs"
          v-on="on"
          v-model="autocompleteValue"
          @focus="inputFocused"
          @change="autocompleteChanged"
          @keydown.tab="tabOrEnter"
          @keyup.enter="tabOrEnter"
          append-icon="mdi-chevron-down"
          :label="label"
          role="combobox"
          :aria-controls="uniqId('menu-list')"
          aria-autocomplete="list"
          :aria-expanded="expanded"
        >
          Dropdown
        </v-text-field>
      </template>
      <v-list :id="uniqId('menu-list')">
      <v-list-item-group>
        <v-list-item
          v-for="(item, index) in filteredItems"
          :key="index"
          @click.stop="selectFromMenu(item)"
          v-model="selectedItem"
          aria-selected="false"
        >
        <v-list-item-content class="text-left">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.subtitle">{{ item.subtitle }}</v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "Select",
  inheritAttrs: false,
  data() {
    return {
      expanded: false,
      selectedItem: 0,
      autocompleteValue: ""
    };
  },
  props: {
    initialvalue: String,
    label: String,
    items: [],
    callback: Function,
    itemTitleProperty: { type: String, default: "title" },
    itemSubtitleProperty: { type: String, default: "subtitle" },
    itemValueProperty: { type: String, default: "value" }
  },
  methods: {
    uniqId: function(base) {
      if (!base) {
        console.error("Error, need base for uniqId");
      }
      return base + "-" + this._uid;
    },
    itemClicked: function(a, b, c) {
      alert("item clicked: a: " + a + " b: " + b + " c: " + c);
    },
    togglePulldown(fixedValue) {
      let menu = this.$refs[this.uniqId('menu-listbox')];
      alert(menu.isActive);
      menu.isActive = true;
      if (fixedValue === null) {
        this.expanded = !this.expanded;
      } else {
        this.expanded = fixedValue;
      }
    },
    inputFocused(event) {
      // Expand with a slight delay; if we do it immediately
      // the click event gets confused and disables the menu again
      // (and if we don't set it here, the menu won't show when
      // receiving focus through keyboard)
      if (!this.expanded) {
        setTimeout(()=>{this.expanded = true;}, 200);
      }
      let el = document.getElementById(this.uniqId('menubutton'));
      if (el) {
          el.setSelectionRange(0, el.value.length);
      }
    },
    tabOrEnter(event) {
      // Select the first of the currently filtered items, if any
      let item = this.filteredItems[0];
      this.autocompleteValue = item.title;
      if (this.callback) {
        this.callback(item.title, item.subtitle, item.value);
      }
    },
    inputBlurred() {
      this.expanded = false;
    },
    openMenu() {
        this.expanded = true;
    },
    selectFromMenu(selectedItem, b) {
      this.expanded = false;
      this.autocompleteValue = selectedItem.title;
      let el = document.getElementById(this.uniqId('menubutton'))
      if (el) {
        el.blur();
      }
      if (this.callback) {
        this.callback(selectedItem.title, selectedItem.subtitle, selectedItem.value);
      }
    },
    autocompleteChanged(value) {
      console.log("[XX] autocompleteChange(" + value + ")");
      this.expanded = true;

      // now blur the element (and close the popup)
      // Again, use a short timeout so that existing event
      // handlers don't get confused
      setTimeout(() => {
        let el = document.getElementById(this.uniqId('menubutton'))
        el.blur();
        // Double-check that menu is closed
        this.expanded = false;
        // Now emit event to parent
      }, 200);
      console.log("[XX] changed to: " + event);
    }
  },
  computed: {
    filteredItems: function() {
      // Returns the list of items that matches a case insensitive
      // search for the name or value attribute
      // With one exception: if the current value
      // is an exact case-sensitive match of any name,
      // the full list is returned
      let result = [];
      let exactMatch = false;
      console.log(this.autocompleteValue);
      for (let i=0; i<this.items.length; i++) {
        if (this.autocompleteValue == this.items[i][this.itemTitleProperty] ||
            this.autocompleteValue == this.items[i][this.itemSubtitleProperty]) {
            exactMatch = true;
        }
      }
      if (this.autocompleteValue && this.autocompleteValue != '' && !exactMatch) {
        for (let i=0; i<this.items.length; i++) {
          let item = this.items[i];
          let filteredItem = {};
          filteredItem.title = item[this.itemTitleProperty];
          filteredItem.value = item[this.itemValueProperty];
          if (item[this.itemSubtitleProperty]) {
            filteredItem.subtitle = item[this.itemSubtitleProperty];
          }
          if (filteredItem.title.toLowerCase().indexOf(this.autocompleteValue.toLowerCase()) > -1 ||
              (filteredItem.subtitle && filteredItem.subtitle.toLowerCase().indexOf(this.autocompleteValue.toLowerCase()) > -1)) {
            result.push(filteredItem);
          }
        }
        // If the list is now empty, reject the input
        if (result.length == 0) {
          this.$nextTick(() => {
            this.autocompleteValue = this.autocompleteValue.slice(0, -1);
          })
        }
        return result;
      } else {
        for (let i=0; i<this.items.length; i++) {
          let item = this.items[i];
          let filteredItem = {};
          filteredItem.title = item[this.itemTitleProperty];
          filteredItem.value = item[this.itemValueProperty];
          if (item[this.itemSubtitleProperty]) {
            filteredItem.subtitle = item[this.itemSubtitleProperty];
          }
          result.push(filteredItem);
        }
        return result;
      }
    }
  },
  mounted() {
  }
}
</script>
