<template>
  <div>
      <Validation />
  </div>
</template>

<script>
// @ is an alias to /src
import Validation from "@/components/Validation.vue";

export default {
  name: "home",
  components: {
    Validation
  }
};
</script>
