<template>
  <div>
    <div>
      <v-card>
        <v-card-title class="cardheader">
          <h1 class="text-h5">{{ $t("received_documents") }}</h1>
        </v-card-title>
        <v-card-actions>
          <v-btn small class="primary mr-4" @click="reloadButtonClicked()"
            ><v-icon left>refresh</v-icon>{{ $t("reload") }}</v-btn
          >
          <div class="text-center ml-4">
            <v-layout wrap>
              <v-icon>search</v-icon>
              <v-text-field
                v-model="filter"
                :label="$t('filter_label')"
                :clearable="true"
                @click:clear="filterCleared()"
                @change="filterChanged()"
              />
            </v-layout>
          </div>
        </v-card-actions>
        <v-card-text>
          <v-data-table
            :options.sync="pagination"
            :serverItemsLength="receivedDocumentCount"
            :headers="receivedDocumentHeaders"
            :footer-props="{ itemsPerPageOptions:[20] }"
            :items="receivedDocumentItems"
            must-sort
            hide-actions0
            @click:row="showValidationResultsDialog"
            style="cursor: pointer;"
          >
            <template v-slot:item.creation_date="{ item }">
              <v-btn icon class="secondary d-sr-only-focusable" :title="$t('details')">
                <v-icon>search</v-icon>
              </v-btn>
              {{ item.creation_date | formatDateTime }}
            </template>

            <template v-slot:item.document="{ item }">
              <div
                v-if="item.validation_errors > 0"
                class="text-left"
              >
                <StatusIcon
                  status="error"
                  :tooltip="
                    summaryToCountString(item.validation_summary)
                  "
                />
              </div>
              <div
                v-else-if="item.validation_warnings > 0"
                class="text-left"
              >
                <StatusIcon
                  status="warning"
                  :tooltip="
                    summaryToCountString(item.validation_summary)
                  "
                />
              </div>
              <div
                v-else
                class="text-left"
              >
                <StatusIcon
                  status="success"
                  :tooltip="
                    summaryToCountString(item.validation_summary)
                  "
                />
              </div>
            </template>
            <template v-slot:item.mlr="{ item }">
              <div @click.stop="doShowMLRDialog(item)" style="cursor: pointer;">
                <StatusIcon
                  v-bind:status="mlrStatusIcon(item)"
                  :tooltip="
                    mlrTooltip(item)
                  "
                />
              </div>
            </template>

            <template v-slot:item.invoice_response="{ item }">
              <v-menu v-if="item.transport_pki" offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    small
                    class="secondary black--text"
                    v-on="on"
                    color="secondary"
                    dark
                    :title="$t('invoice_response_send')"
                  >
                    <v-icon>reply</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title><i>{{ $t("invoice_response_send_with_status") }}</i></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="sendInvoiceResponse(item, 'AP')"
                  >
                    <v-list-item-title>{{
                      $t("invoice_response_accepted")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="sendInvoiceResponse(item, 'RE')"
                  >
                    <v-list-item-title>{{
                      $t("invoice_response_rejected")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="sendInvoiceResponse(item, 'PD')"
                  >
                    <v-list-item-title>{{
                      $t("invoice_response_paid")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <StatusIcon v-else :tooltip="$t('invoice_response_not_available')"/>
            </template>


            <template v-slot:item.request="{ item }">
              <v-btn
                fab
                small
                class="secondary"
                @click.stop="showRequest(item)"
                :title="$t('request')"
                ><v-icon>search</v-icon></v-btn
              >
            </template>
            <template v-slot:item.delete="{ item }">
              <div class="text-right">
                <v-btn
                  fab
                  small
                  class="secondary"
                  :disabled="item.disabled"
                  @click.stop="
                    receivedDocumentDeleteClicked(item)
                  "
                  ><v-icon>delete</v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>

    <v-dialog v-model="showValidationResults">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn
              icon
              color="primary"
              @click.stop="showValidationResults = false"
              :title="$t('close')"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <ValidationResults ref="validationResults" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showValidationResults">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn
              icon
              color="primary"
              @click.stop="showValidationResults = false"
              :title="$t('close')"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <ValidationResults ref="validationResults" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMLRDialog">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn
              icon
              color="primary"
              @click.stop="showMLRDialog = false"
              :title="$t('close')"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>
        <v-card-text>
          <ValidationResults ref="mlrValidationResults" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <BackendConnector ref="backend"></BackendConnector>
    <TestTaskViewer
      v-on:error="testTaskDone()"
      ref="taskViewer"
    ></TestTaskViewer>
    <TextViewer ref="textViewer"></TextViewer>
    <StandardUserConfirmation ref="userConfirmation"></StandardUserConfirmation>
  </div>
</template>

<script>
// @ is an alias to /src
import StatusIcon from "@/components/StatusIcon.vue";
import TestTaskViewer from "@/components/TestTaskViewer.vue";
import TextViewer from "@/components/TextViewer.vue";
import ValidationResults from "@/components/ValidationResults.vue";
import StandardUserConfirmation from "@/components/StandardUserConfirmation.vue";

export default {
  name: "test_access_points",
  components: {
    StatusIcon,
    ValidationResults,
    StandardUserConfirmation,
    TestTaskViewer,
    TextViewer
  },
  data() {
    return {
      showValidationResults: false,
      activeTab: "participants",
      receivedDocumentItems: [],
      receivedDocumentCount: 0,
      filter: "",
      pagination: {
        sortBy: ["creation_date"],
        descending: true,
        rowsPerPage: 10,
        page: 1
      },
      showTestAccessPointDialog: false,
      testAccessPointFormUrlRules: [v => !!v || this.$t("url_required")],
      testAccessPointFormUrlErrors: [],
      valid: true,
      showTestAccessPointDeleteDialog: false,
      currentTestAccessPoint: {
        name: "",
        id: -1,
        description: "",
        url: ""
      },
      savedTestAccessPoint: {},
      showMLRDialog: false,
    };
  },
  methods: {
    loadData: function() {
      var url =
        process.env.VUE_APP_ROOT_API +
        "/receiver/received_documents_short/?page=" +
        this.pagination.page +
        "&page_size=" +
        this.pagination.rowsPerPage;
      let order_by = "creation_date";
      let direction = "desc";
      if (this.pagination.sortBy) {
        order_by = this.pagination.sortBy;
        if (!this.pagination.descending) {
          direction = "asc";
        }
      }
      url += "&order_by=" + order_by + "&direction=" + direction;

      if (this.filter && this.filter !== "") {
        url += "&filter=" + this.filter;
      }

      this.$refs.backend
        .get_auth(url)
        .then(response => {
          this.receivedDocumentCount = response.data.count;
          this.receivedDocumentItems = response.data.results;
          //alert(JSON.stringify(response.data.results, null, 2));
          // this.receivedDocumentItems = response.data
        })
        .catch(error => {
          // handled by BackendConnector
          console.log(error);
        });
    },
    unloadData: function() {
      this.receivedDocumentItems = [];
    },
    receivedDocumentDeleteClicked: function(item) {
      item.disabled = true;
      let callbackConfirm = (arg) => {
        let id = item.id;
        if (id > -1) {
          var url =
            process.env.VUE_APP_ROOT_API +
            "/receiver/received_documents/" +
            id +
            "/";
          this.$refs.backend.delete_auth(url)
          .then(response => {
            this.loadData();
          })
          .catch(error => {
            console.log(error);
            item.disabled = false;
            //this.$set(this.receivedDocumentItems, index, iii);
          });
        }
      }
      let callbackCancel = () => {
        item.disabled = false;
      }
      this.$refs.userConfirmation.doShowUserConfirmation(
        this.$t('delete_document_confirm_title'),
        this.$t('delete_document_confirm_message'),
        callbackConfirm,
        callbackCancel,
        this
      );
    },
    resetCurrentTestAccessPoint: function() {
      this.currentTestAccessPoint = JSON.parse(
        JSON.stringify(this.savedTestAccessPoint)
      );
    },
    reloadButtonClicked: function() {
      this.pagination.sortBy = "creation_date";
      this.pagination.descending = true;
      this.loadData();
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    },
    showValidationResultsDialog(item) {
      // this.$router.push({name: 'test_access_point_details', params: { ap_id: item.id }})

      let url =
        process.env.VUE_APP_ROOT_API +
        "/receiver/received_documents/" +
        item.id +
        "/";
      this.$refs.backend
        .get_auth(url)
        .then(response => {
          let doc = response.data;
          this.showValidationResults = true;
          this.$nextTick(() => {
            if (!doc.validation_summary || doc.validation_summary == "") {
              doc.validation_summary =
                '{ "warnings": [], "warning_count": 0, "errors": ["Validation not performed"], "error_count": 1}';
            }
            this.$refs.validationResults.setData(
              JSON.parse(doc.validation_summary),
              doc.document
            );
          })
        })
        .catch(error => {
          console.log(error);
        });
    },
    showTransportResults(item) {
      let text = "";
      let summary = JSON.parse(item.transport_summary);
      let i = 0;
      for (i = 0; i < summary.errors.length; i++) {
        text = text + "Error: " + summary.errors[i] + "\n";
      }
      for (i = 0; i < summary.warnings.length; i++) {
        text = text + "Warning: " + summary.warnings[i] + "\n";
      }
      this.$refs.textViewer.doShowText("Transport", text);
    },
    showRequest: function(item) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/receiver/received_documents/" +
        item.id +
        "/";
      this.$refs.backend
        .get_auth(url)
        .then(response => {
          let doc = response.data;
          this.$refs.textViewer.doShowText(this.$t("request"), doc.request);
        })
        .catch(error => {
          console.log(error);
        });
    },
    summaryToCountString(value) {
      // return a string of the form 'Errors: X, Warnings: Y'
      if (!value) {
        return "No warnings, no errors";
      }
      let data = JSON.parse(value);
      return (
        this.$t("warnings") +
        ": " +
        data.warning_count +
        " " +
        this.$t("errors") +
        ": " +
        data.error_count
      );
    },
    filterChanged() {
      this.loadData();
    },
    filterCleared() {
      this.filter = "";
      this.loadData();
    },
    mlrStatusIcon(item) {
      if (item.mlr_state == 'not sent') {
        return "not_applicable";
      } else if (item.mlr_state == 'sent') {
        return "success";
      } else if (item.mlr_state == 'error') {
        return "error";
      }
    },
    mlrTooltip(item) {
      if (item.mlr_state == 'not sent') {
        return this.$t('mlr_not_sent');
      } else if (item.mlr_state == 'sent') {
        return this.$t('mlr_sent');
      } else if (item.mlr_state == 'error') {
        return this.$t('mlr_error') + item.mlr_send_error;
      }
    },
    doShowMLRDialog(item) {
      if (item.mlr_state && item.mlr_state != "not sent") {
          this.showMLRDialog = true;
          this.$nextTick(() => {
            this.$refs.mlrValidationResults.setData(
                {},
                item.mlr
            );
          })
      }
    },
    sendInvoiceResponse(item, responseCode) {
      let url = process.env.VUE_APP_ROOT_API_WS +
                "/accesspoints/send_invoice_response/" +
                item.id + "/" + responseCode;

      let args = { 'doc_id': item.id, 'response_code': responseCode }
      this.$refs.taskViewer.run(url, args).then((result) => {
          // success
          console.log("Send Invoice Response Success!");
      }).catch((error) => {
          // error
          console.error("Send invoice response failure!");
      })
    }
  },
  computed: {
    receivedDocumentHeaders() { return [
      { text: this.$t("date"), value: "creation_date", sortable: false },
      { text: this.$t("message_id"), value: "message_id", sortable: false },
      { text: this.$t("document_id"), value: "document_id", sortable: false },
      { text: this.$t("sending_access_point"), value: "as2_from", sortable: false },
      { text: this.$t("sender"), value: "sbdh_sender", sortable: false },
      { text: this.$t("transport"), value: "transport_type", sortable: false },
      { text: this.$t("valid"), value: "document", sortable: false },
      { text: this.$t("mlr"), value: "mlr", sortable: false },
      { text: this.$t("invoice_response"), value: "invoice_response", sortable: false },
      { text: this.$t("request"), value: "request", sortable: false },
      { text: this.$t("delete"), value: "delete", sortable: false }
    ]}
  },
  watch: {
    pagination: {
      handler(newData, oldData) {
        this.loadData();
      }
    }
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "updateToken":
          this.unloadData();
          try {
            // TODO: maybe not reload on login but redirect?
            if (this.$refs && this.$refs.backend) {
              this.$nextTick(this.loadData);
            }
          } catch (berr) {
            alert(
              "[XX] error in loadData for TestAccessPointDetails.vue (sub updateToken): " +
                berr
            );
          }
          break;
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
        case "LOGOUT":
          this.unloadData();
          break;
      }
    });
    try {
      this.loadData();
    } catch (berr) {
      console.log(berr);
    }
  }
};
</script>
