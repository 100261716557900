<template>
  <div>
    <v-layout>
        <v-card class="mx-auto px-2">
          <v-card-title class="cardheader" mb-0 pb-0>
            <h1 class="text-h5">{{ $t("validate") }}</h1>
            <v-layout wrap justify-end>
              <v-card-actions class="text-center">
                <v-btn icon @click.stop="" :href="getHelpURL" :title="$t('button_help')+$t('validate')"
                  ><v-icon large color="primary">help</v-icon></v-btn
                >
              </v-card-actions>
            </v-layout>
          </v-card-title>
          <v-card-title text-xs-left mb-0 pb-0>
            {{ $t("validate_description") }}
          </v-card-title>
          <v-card-text mt-0 pt-0>
            <v-layout>
              <v-row>
                <v-file-input
                  :label="$t('select_file')"
                  v-model="selectedFile"
                  prepend-icon="folder"
                  @change="sendFile()"
                />
              </v-row>
            </v-layout>
            <v-layout>
              <v-row>
                <v-switch
                  :label="$t('validate_different_format')"
                  v-model="otherFormat"
                  @change="documentTypeSelectionSwitchChanged"
                  class="mr-3"
                ></v-switch>
                <MyAutocomplete
                  v-if="otherFormat"
                  :label="$t('select_format')"
                  :items="documentTypes"
                  :callback="mychange"
                  item-title-property="name",
                  item-subtitle-property="description",
                  item-value-property="keyword"
                />

                <v-btn
                  @click="sendFile"
                  color="primary"
                  :disabled="!haveSelectedFile"
                  class="mt-3"
                  >{{ $t("validate_document") }}</v-btn
                >
              </v-row>
            </v-layout>
          </v-card-text>
        </v-card>
    </v-layout>

    <div v-if="haveStarted">
      <ValidationResults ref="validationResults"></ValidationResults>
    </div>
    <StandardErrorMessage ref="errMsg"></StandardErrorMessage>
  </div>
</template>

<script>
import axios from "axios";
import StandardErrorMessage from "@/components/StandardErrorMessage.vue";
import ValidationResults from "@/components/ValidationResults.vue";
import MyAutocomplete from "@/components/MyAutocomplete.vue";

export default {
  name: "Validation",
  data() {
    return {
      otherFormat: false,
      //haveSelectedFile: false,
      selectedFile: null,

      haveStarted: false,
      documentTypes: [],
      documentType: "derive"
    };
  },
  methods: {
    mychange: function(title, subtitle, value) {
      //console.log("[XX] mychange! from " + b + " to " + a);
      console.log("[XX] set keyword to " + value);
      this.documentType = value;
      if (this.haveSelectedFile) {
        this.sendFile();
      }
    },
    sendFile: function() {
      let file = this.selectedFile;
      if (!file) {
        return;
      }
      let url = "";
      if (!this.otherFormat) {
        url = process.env.VUE_APP_ROOT_API + "/validate/validate/derive";
      } else {
        url =
          process.env.VUE_APP_ROOT_API +
          "/validate/validate/" +
          encodeURIComponent(this.documentType);
      }
      var self = this;
      this.haveStarted = true;

      let formData = new FormData();
      formData.append("document", file);
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function(response) {
          if (response.data && response.data.result) {
            // do error handling here or in handle?
            self.handleResponse(response.data);
          } else if (response.data && response.data.error) {
            self.haveStarted = false;
            if (response.data.error_title) {
              self.$refs.errMsg.doShowError(
                response.data.error_title,
                response.data.error,
                "en"
              );
            } else {
              self.$refs.errMsg.doShowError(
                "Server error",
                response.data.error,
                "en"
              );
            }
          }
        })
        .catch(function(error) {
          // TODO PUTMEBACK
          self.haveStarted = false;
          console.error(error);
          self.$refs.errMsg.doShowError("Server error", error, "en");
        });
    },
    handleResponse: function(data) {
      let vResult = this.$refs.validationResults;
      vResult.setData(data.result, data.xml);
    },
    loadDocumentTypes: function() {
      var url = process.env.VUE_APP_ROOT_API + "/validate/document_types";
      var self = this;
      axios.get(url).then(function(response) {
        //alert(JSON.stringify(response.data.document_types, null, 2));
        self.documentTypes = response.data.document_types;
      });
    },
    documentTypeSelectionChanged: function(value) {
      this.documentType = value;
      if (this.haveSelectedFile) {
        this.sendFile();
      }
    },
    documentTypeSelectionSwitchChanged: function(value) {
      // in this case, the user clicked the switch.
      // revalidate, but only if the switch got turned off
      if (!this.otherFormat) {
        this.documentTypeSelectionChanged(value);
      }
    }
  },
  beforeMount() {
    this.loadDocumentTypes();
  },
  components: {
    MyAutocomplete,
    ValidationResults,
    StandardErrorMessage
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
  },
  computed: {
    getHelpURL() {
      if (this.$store.getters.getLanguage === "nl") {
        return "/documentation/nl/components/validate/";
      } else {
        return "/documentation/components/validate/";
      }
    },
    haveSelectedFile() {
      return (this.selectedFile && this.selectedFile != "")
    }
  }
};
</script>
