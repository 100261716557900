<template>
  <v-dialog v-model="showMessage" persistent width="500">
    <v-card>
      <v-card-title class="text-h5 darken-2">{{ $t('session_expires_title') }}</v-card-title>

      <v-card-text>
        <div>
          {{ $t('session_expires_message', [ this.sessionTimeLeft ]) }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="extendSession">
          {{ $t('extend_session') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ExtendSessionMessage",
  data() {
    return {
      timer: null,
      sessionTimeLeft: null,
    };
  },
  props: {},
  methods: {
    extendSession: function() {
        this.$store.dispatch('refreshToken');
    },
    checkSessionTimer: function() {
      let exp = this.$store.getters.getSessionExpiry;
      if (exp != null) {
        let cur = Date.now();
        this.sessionTimeLeft = Math.round(exp - (cur / 1000));
        if (this.sessionTimeLeft <= 0) {
          this.$store.dispatch("logout");
          this.$router.push("/");
        }
      } else {
        this.sessionTimeLeft = null;
      }
    }
  },
  mounted() {
    if (this.timer == null) {
      this.timer = setInterval(this.checkSessionTimer, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  computed: {
    showMessage() {
      return this.sessionTimeLeft != null && this.sessionTimeLeft >= 0 && this.sessionTimeLeft < 120;
      //return this.sessionTimeLeft != null && this.sessionTimeLeft >= 0 && this.sessionTimeLeft < 3590;
    }
  }
};
</script>
