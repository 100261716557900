<template>
  <StandardErrorMessage ref="errMsg"></StandardErrorMessage>
</template>

<script>
import StandardErrorMessage from "@/components/StandardErrorMessage.vue";
import axios from "axios";

export default {
  components: {
    StandardErrorMessage
  },
  data() {
    null;
    return {};
  },
  methods: {
    handle_error: function(error) {
      let errorTitle = "Error";
      let errorMessage = null;
      let isAuthFailure = false;
      // If unknown, the error message is probably in english
      let errorLang = "en";
      if (error && error.response) {
        if (error.response.data && error.response.data.error_title) {
          errorTitle = error.response.data.error_title;
        } else if (error.response.statusText) {
          errorTitle = error.response.statusText;
        }
        if (error.response.data && error.response.data.detail) {
          errorMessage = error.response.data.detail;
        } else if (error.response.data) {
          errorMessage = JSON.stringify(error.response.data);
        } else if (error.response.status && error.response.status == 404) {
          errorMessage = "404, location not found";
        }

        if (error.response.status && error.response.status === 401) {
          isAuthFailure = true;
          errorTitle = this.$t("not_logged_in_title");
          errorMessage = this.$t("not_logged_in_message");
          errorLang = this.$store.getters.getLanguage;
        }
      } else {
        // error connecting to backend
        errorTitle = "Error";
        errorMessage = "Error connecting to backend";
      }
      // if it is not a specific 'showable' error from the backend, just
      // let it bubble up (e.g. for form entry errors)
      if (errorMessage) {
        this.$refs.errMsg.doShowError(errorTitle, errorMessage, errorLang, isAuthFailure);
        return true;
      } else {
        return false;
      }
    },
    get: function(url, args) {
      let self = this;
      return new Promise((resolve, reject) => {
        return axios
          .get(url, args)
          .then(function(response) {
            let backend_starttime = response.headers["x-testtool-starttime"];
            self.$store.dispatch("checkBackendStartupTime", backend_starttime);
            resolve(response);
          })
          .catch(function(error) {
            if (!self.handle_error(error)) {
              reject(error);
            }
          });
      });
    },
    get_auth: function(url, args) {
      // TODO: this currently breaks other headers, do we use those?
      this.$store.dispatch("inspectToken");
      let jwt = this.getJWT();
      let newArgs = {};
      if (jwt && jwt != '') {
        newArgs['headers'] = { Authorization: "JWT " + this.getJWT() };
      }
      return this.get(url, newArgs);
    },
    post: function(url, data, args) {
      let self = this;
      return new Promise((resolve, reject) => {
        return axios
          .post(url, data, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            self.handle_error(error);
            reject(error);
          });
      });
    },
    post_auth: function(url, data, args) {
      // TODO: this currently breaks other headers, do we use those?
      this.$store.dispatch("inspectToken");
      let jwt = this.getJWT();
      let newArgs = {};
      if (jwt && jwt != '') {
        newArgs['headers'] = { Authorization: "JWT " + this.getJWT() };
      }
      return this.post(url, data, newArgs);
    },
    put: function(url, data, args) {
      let self = this;
      return new Promise((resolve, reject) => {
        return axios
          .put(url, data, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            self.handle_error(error);
            reject(error);
          });
      });
    },
    put_auth: function(url, data, args) {
      this.$store.dispatch("inspectToken");
      // TODO: this currently breaks other headers, do we use those?
      let jwt = this.getJWT();
      let newArgs = {};
      if (jwt && jwt != '') {
        newArgs['headers'] = { Authorization: "JWT " + this.getJWT() };
      }
      return this.put(url, data, newArgs);
    },
    delete: function(url, args) {
      let self = this;
      return new Promise((resolve, reject) => {
        return axios
          .delete(url, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            self.handle_error(error);
            reject(error);
          });
      });
    },
    delete_auth: function(url, args) {
      this.$store.dispatch("inspectToken");
      // TODO: this currently breaks other headers, do we use those?
      let jwt = this.getJWT();
      let newArgs = {};
      if (jwt && jwt != '') {
        newArgs['headers'] = { Authorization: "JWT " + this.getJWT() };
      }
      return this.delete(url, newArgs);
    },
    download_auth: function(url, args) {
      // TODO: this currently breaks other headers, do we use those?
      let newArgs = {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        }
      };
      let jwt = this.getJWT();
      if (jwt && jwt != '') {
        newArgs['headers']['Authorization'] = "JWT " + jwt;
      }
      
      this.get(url, newArgs)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "test_report.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(function(error) {
          alert(error);
        });

      /* this fails when rcode != 200
      fetch(url, newArgs)
          .then(response => response.blob())
          .then(blobby => {
              let objectUrl = window.URL.createObjectURL(blobby);

              anchor.href = objectUrl;
              anchor.download = 'report.pdf';
              //alert(anchor);
              document.body.appendChild(anchor);
              //anchor.click();
              //alert('b');
              window.URL.revokeObjectURL(objectUrl);
          }).catch(function(error) {
            alert(error);
          });
    */
    },
    getJWT() {
      return this.$store.getters.getJWT;
    }
  }
};
</script>
