<template>
  <div class="mx-auto">
    <v-container text-center>
      <div v-if="isLoading">
        <v-card>
          <v-card-text>
            <v-progress-circular
              color="blue-grey"
              indeterminate
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="introductionText">
        <v-card :color="introductionColor" dark>
          <v-card-text>
            <h1 class="text-h5">{{ introductionText }}</h1>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="haveMessages || haveXML">
        <v-card>
          <v-card-title>
            <div v-if="documentType">{{ $t('validated_as') }}: {{ this.documentType }}</div>
          </v-card-title>
          <v-card-text>
            <div v-if="haveMessages">
              <v-layout row class="mb-4">
                <div v-if="errorCount == 1">
                  <v-card color="error" class="ml-4 mb-1 mx-auto">
                    <v-card-text>
                      <h3 class="whitetext">{{ $t('one_error') }}</h3>
                    </v-card-text>
                  </v-card>
                </div>
                <div v-if="errorCount > 1">
                  <v-card color="error" dark class="ml-4 mb-1">
                    <v-card-text>
                      <h3 class="whitetext" role="alert">{{ errorCount }} {{ $t('errors') }}</h3>
                    </v-card-text>
                  </v-card>
                </div>
                <div v-if="warningCount == 1">
                  <v-card color="warning" class="ml-4 mb-1">
                    <v-card-text>
                      <h3 class="whitetext" role="alert">{{ $t('one_warning') }}</h3>
                    </v-card-text>
                  </v-card>
                </div>
                <div v-if="warningCount > 1">
                  <v-card color="warning" class="ml-4 mb-1">
                    <v-card-text>
                      <h3 class="whitetext" role="alert">{{ warningCount }} {{ $t('warnings') }}</h3>
                    </v-card-text>
                  </v-card>
                </div>
              </v-layout>
              <v-data-table
                :items="messages"
                hideDefaultHeader
                :headers="validationResultHeaders"
                :hideDefaultFooter="messages.length <= 5"
              >
               <template v-slot:item="{ item }">
                      <table class="v-datatable v-table theme--light" style="word-break: break-all;">
                        <tr>
                          <td v-if="item.isError" role="alert" class="text-left error whitetext ml-0 px-4 fill-width fill-height" style="width:84px;" lang="en">
                            <b>{{ $t("validate_error_message") }}</b>
                          </td>
                          <td v-else role="alert" class="text-left whitetext warning ml-0 px-4 fill-width fill-height" style="width:84px; white-space: nowrap;">
                            <b>{{ $t("validate_warning_message") }}</b>
                          </td>
                          <td role="alert" class="text-left pl-3" lang="en">
                            <b>{{ item.message }}</b>
                          </td>
                        </tr>
                        <tr v-if="item.location">
                          <td v-if="item.isError" class="text-left error whitetext ml-0 px-4 fill-width fill-height">
                            <b>{{ $t("validate_error_location") }}</b>
                          </td>
                          <td v-else class="text-left whitetext warning ml-0 px-4 fill-width fill-height">
                            <b>{{ $t("validate_error_location") }}</b>
                          </td>
                          <td class="text-left pl-3" lang="en">
                            {{ item.location }}
                          </td>
                        </tr>
                        <tr>
                          <td v-if="item.isError" class="text-left error whitetext ml-0 px-4 fill-width fill-height">
                            <b>{{ $t("validate_error_test") }}</b>
                          </td>
                          <td v-else class="text-left whitetext warning ml-0 px-4 fill-width fill-height">
                            <b>{{ $t("validate_error_test") }}</b>
                          </td>
                          <td class="text-left pl-3" lang="en">{{ item.test }}</td>
                        </tr>
                      </table>
               </template>
              </v-data-table>
            </div>
            <div v-else>
              <v-card color="success">
                <v-card-text>
                  <h3 class="whitetext">{{ $t('document_validated') }}</h3>
                </v-card-text>
              </v-card>
            </div>
            <div mt-6></div>
            <div v-if="haveXML" id="mydiv">
              <v-card
                id="mycard"
                grid-list-md
                class=""
                height="492px"
              >
                <v-card-text>
                  <div v-html="xmlDataHTML"></div>
                <v-card-actions>
                  <v-btn class="primary" @click="downloadXML()" :title="$t('download_xml')"><v-icon>download</v-icon></v-btn>
                  <v-btn class="primary" @click="copyXML()" :title="$t('copy_xml')"><v-icon>content_copy</v-icon></v-btn>
                </v-card-actions>
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import Prism from "prismjs";

var VueScrollTo = require("vue-scrollto");

export default {
  name: "ValidationResults",
  data() {
    return {
      introductionText: null,
      introductionColor: "success",
      isLoading: true,
      haveResults: false,
      haveXML: false,
      xmlData: "",
      result_txt: "",
      haveMessages: false,
      haveErrors: 0,
      haveWarnings: 0,
      errorCount: 0,
      warningCount: 0,
      errors: [],
      warnings: [],
      messages: [],
      documentTypes: []
    };
  },
  methods: {
    setData(result, xml) {
      this.isLoading = false;

      this.haveMessages = false;
      this.messages = [];
      this.errors = [];
      this.haveErrors = false;
      this.warnings = [];
      this.haveWarnings = false;
      this.documentType = "-";

      if (result && result.errors) {
        if (result.document_type) {
            this.documentType = result.document_type;
        }
        this.errorCount = result.error_count;
        this.haveErrors = result.errors.length > 0;
        for (let i = 0; i < result.errors.length; i++) {
          this.messages.push(this.parseMessagePart(result.errors[i], true));
        }
      }
      if (result && result.warnings) {
        this.warningCount = result.warning_count;
        this.haveWarnings = result.warnings.length > 0;
        for (let i = 0; i < result.warnings.length; i++) {
          this.messages.push(this.parseMessagePart(result.warnings[i], false));
        }
      }
      this.haveMessages = this.haveErrors | this.haveWarnings;
      // TODO
      if (result.error) {
        alert("Error from validator: " + result.error);
      }
      this.xmlData = xml;
      if (xml) {
        this.haveXML = true;
      }
      this.redraw();
    },
    parseMessagePart: function(msg, isError) {
      // TODO: parse id from text if not defined
      // remove id from text
      // let text = msg.text
      // return { rule: msg.id, message: msg.text }
      msg.isError = isError;
      return msg;
    },
    scrollToLine: function(line) {
      let lineHeight = 17.85;
      var scrollOptions = {
        container: "#mycard",
        easing: "ease-in",
        offset: lineHeight * (line - 2),
        cancelable: false,
        x: false,
        y: true
      };
      VueScrollTo.scrollTo("#mycard", 300, scrollOptions);
    },
    tableItemClicked: function(item) {
      if (item.line) {
        this.scrollToLine(item.line);
      }
    },
    redraw: function() {
      this.$nextTick(function() {
        // Re-highlight after we have updated the code
        Prism.highlightAll(false);
        this.scrollToLine(0);
      });
    },
    setIntroductionText: function(text) {
      this.introductionText = text;
    },
    setIntroductionColor: function(color) {
      this.introductionColor = color;
    },
    downloadXML: function() {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:application/xml;charset=utf-8,' + encodeURIComponent(this.xmlData));
      element.setAttribute('download', 'document.xml');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    copyXML: function() {
      // Copy the text inside the text field
      navigator.clipboard.writeText(this.xmlData);
    }
  },
  computed: {
    xmlDataHTML: function() {
      // Note: to make sure prims.js highlights updated code (and not
      // only the first time), we return this as raw html, and then
      // call highlightAll() in the next tick after an update

      // Make sure we are not outputting html tags, but only the
      // tag markers
      let d = this.xmlData;
      if (d) {
          d = d.replace(/</g, "&lt;");
          d = d.replace(/>/g, "&gt;");

          return (
            '<pre id="mycode" class="overflow-x-auto overflow-y-auto" style="width: 100%; height: 400px;"><code class="language-xml">' +
            d +
            "</code></pre>"
          );
      } else {
        return "";
      }
    },
    errorsAndWarnings: function() {
      return this.errors + this.warnings;
    },
    validationResultHeaders: function() {
      let headers = [
        { text: this.$t("id"), value: "id", sortable: false },
        { text: this.$t("details"), value: "details", sortable: false },
      ]
      return headers;
    },
  }
};
</script>

<style scoped lang="stylus">
.darkrowsmall {
  margin: 100px !important;
  padding: 0px 4px 0px 4px !important;
}
.whitetext {
  color: #ffffff;
}
.blacktext {
  color: #000000;
}

</style>
