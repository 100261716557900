<template>
  <div>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
          <v-card class="elevation-12" max-width="1200px">
            <v-card-title mb-0 pb-0 dark>
              <h1 class="text-h5">{{ $t("connectioncoach_title") }}</h1>
            </v-card-title>
            <v-card-text>
            
              <div class="d-sr-only">{{ $t('connectioncoach_current_step')}}: {{ currentStepTitle }}</div>

              <v-stepper v-model="currentStep" alt-labels>
                <v-stepper-header>
                  <v-stepper-step role="status" :complete="currentStep > 0" step="" :title="completedStepText(0)">{{ $t('conncoach_step0_title') }}</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step role="status" :complete="currentStep > 1" step="1" :title="completedStepText(1)">{{ $t('conncoach_step1_title') }}</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step role="status" :complete="currentStep > 2" step="2" :title="completedStepText(2)">{{ $t('conncoach_step2_title') }}</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step role="status" :complete="currentStep > 3" step="3" :title="completedStepText(3)">{{ $t('conncoach_step3_title') }}</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step role="status" :complete="currentStep > 4" step="4" :title="completedStepText(4)">{{ $t('conncoach_step4_title') }}</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step role="status" :complete="currentStep > 5" step="5" :title="completedStepText(5)">{{ $t('conncoach_step5_title') }}</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step role="status" :complete="currentStep > 6" step="6" :title="completedStepText(6)">{{ $t('conncoach_step6_title') }}</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!--

                        Step 0: Introduction and description of process

                  -->
                  <v-stepper-content step="0">
                  <div style="margin-left: 2em; max-width: 800px;"">
                    <h3>{{ $t('conncoach_intro_title') }}</h3>
                    <br />
                    <p>{{ $t('conncoach_intro_section_1') }}</p>
                    <p>{{ $t('conncoach_intro_section_2') }}</p>
                    <p>{{ $t('conncoach_intro_section_3') }}</p>
                    <p>{{ $t('conncoach_intro_section_4') }}</p>
                    <p>{{ $t('conncoach_intro_section_5') }}</p>
                    <v-btn color="primary" v-on:click="startNewSession">{{ $t('conncoach_step0_start') }}</v-btn>
                  </div>
                  </v-stepper-content>

                  <!--

                        Step 1: User sends document to test tool.
                        Document must contain a number of specific field values

                  -->
                  <v-stepper-content step="1">
                  <div style="margin-left: 2em; max-width: 800px;"">
                    <h3>{{ $t('conncoach_step1_longtitle') }}</h3>
                    <br />
                    <p>{{ $t('conncoach_step1_section_1') }}</p>
                    <v-data-table
                      dense
                      hide-default-header
                      hide-default-footer
                      :headers="sendInfoHeaders"
                      :items="sendInfoData"
                      class="elevation-2"
                    ></v-data-table>
                    <br />
                    <p>{{ $t('conncoach_step1_section_2') }}</p>
                    <v-data-table
                      dense
                      hide-default-header
                      hide-default-footer
                      :headers="sendInfoHeaders"
                      :items="sendFieldsData"
                      class="elevation-2"
                    ></v-data-table>
                    <br />
                    <p>{{ $t('conncoach_step1_section_3') }}</p>

                  </div>
                  </v-stepper-content>

                  <!--

                        Step 2: Field values are checked, and an advice report
                        can be downloaded. After downloading (if relevant),
                        the user can move on to the receive test.

                  -->
                  <v-stepper-content step="2">
                    <h3>{{ $t('conncoach_step2_longtitle') }}</h3>
                    <br />
                    <p v-if="allSendDataCorrect">{{ $t('conncoach_step2_section_1_ok') }}</p>
                    <div v-else>
                      <p>{{ $t('conncoach_step2_section_1_errors') }}</p>
                      <p>
                      <v-btn color="primary" v-on:click="downloadSendAdvice">{{ $t('conncoach_step2_download_advice')}}</v-btn>
                      </p>
                    </div>
                    <v-data-table
                      dense
                      hide-default-footer
                      :headers="sendResultsHeaders"
                      :items="sendResultsData"
                      class="elevation-2"
                    >
                      <template v-slot:item.correct="{ item }" >
                        <StatusIcon :status="item.correct" />
                      </template>
                    </v-data-table>
                    <br />
                    <v-alert type="info" v-if="!currentTestData.send_sender_can_receive_mlr" dark v-on:click="goToStatusInfoPage">
                      {{ $t('conncoach_step2_no_mlr') }}
                    </v-alert>

                    <v-btn color="primary" v-on:click="moveToReceiveTest">{{ $t('conncoach_step2_to_receive') }}</v-btn>
                  </v-stepper-content>

                  <!--

                        Step 3: Start receive test. User can specify one
                        field, the rest are filled in by system.
                        Document is sent once user clicks 'start test'

                  -->
                  <v-stepper-content step="3">
                    <h3>{{ $t('conncoach_step3_longtitle') }}</h3>
                    <br />
                    <p>{{ $t('conncoach_step3_section_1') }}</p>
                    <p>Peppol ID: <b>{{ currentTestData.sender_identifier }}</b></p>
                    <p>{{ $t('conncoach_step3_section_2') }}</p>
                    <p><b>{{ $t('conncoach_step3_requirement') }}:</b> {{ $t('conncoach_step3_section_3') }}</p>
                    <table>
                      <tbody>
                        <tr>
                          <td class="text-start">
                            <v-select
                              dense
                              :items="receiveRequireFieldNames"
                              :label="$t('conncoach_step2_header_field')"
                              outlined
                              @change="focusReceiveRequireValueField"
                              v-model="receiveRequireField"
                            ></v-select>
                          </td>
                          <td class="text-start">
                            <v-text-field
                              dense
                              ref="receiveRequireValueField"
                              outlined
                              :label="$t('conncoach_step2_header_value')"
                              v-model="receiveRequireValue"
                            ></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p><b>{{ $t('conncoach_attention') }}</b> {{ $t('conncoach_step3_section_4') }}</p>
                    <v-btn color="primary" v-on:click="startReceiveTest">{{ $t('conncoach_step3_start_test_receive') }}</v-btn>

                  </v-stepper-content>

                  <!--

                        Step 4: User fills in all values from the received
                        document

                  -->
                  <v-stepper-content step="4">
                    <h3>{{ $t('conncoach_step4_longtitle') }}</h3>
                    <br />
                    <p>{{ $t('conncoach_step4_section_1_1') }} <b>{{ currentTestData.receive_invoice_number }}</b> {{ $t('conncoach_step4_section_1_2') }}</p>
                    <v-form v-model="receiveFormValid">
                      <v-text-field
                        outlined
                        hide-details="auto"
                        class="pb-1"
                        v-model="receiveFieldValueNote"
                        :rules="receiveFormFieldRules"
                        :label="$t('conncoach_step4_receive_question_note')"></v-text-field>
                      <v-text-field
                        outlined
                        hide-details="auto"
                        class="pb-1"
                        v-model="receiveFieldValueOrderNumber"
                        :rules="receiveFormFieldRules"
                        :label="$t('conncoach_step4_receive_question_order_number')"></v-text-field>
                      <v-text-field
                        outlined
                        hide-details="auto"
                        class="pb-1"
                        v-model="receiveFieldValueProjectNumber"
                        :rules="receiveFormFieldRules"
                        :label="$t('conncoach_step4_receive_question_project_number')"></v-text-field>
                      <v-text-field
                        outlined
                        hide-details="auto"
                        class="pb-1"
                        v-model="receiveFieldValueContractNumber"
                        :rules="receiveFormFieldRules"
                        :label="$t('conncoach_step4_receive_question_contract_number')"></v-text-field>
                     <v-text-field
                        outlined
                        hide-details="auto"
                        class="pb-1"
                        v-model="receiveFieldValueRouteCode"
                        :rules="receiveFormFieldRules"
                        :label="$t('conncoach_step4_receive_question_accounting_code')"></v-text-field>
                      <v-text-field
                        outlined
                        hide-details="auto"
                        class="pb-1"
                        v-model="receiveFieldValueCustomerReference"
                        :rules="receiveFormFieldRules"
                        :label="$t('conncoach_step4_receive_question_customer_reference')"></v-text-field>
                    </v-form>
                    <br />
                    <v-btn color="primary" :disabled="!receiveFormValid" v-on:click="checkReceiveTest">{{ $t('conncoach_step4_to_results') }}</v-btn>

                  </v-stepper-content>

                  <!--

                        Step 5: Values from step 4 are compared and the
                        result is shown.

                  -->
                  <v-stepper-content step="5">
                    <h3>{{ $t('conncoach_step5_longtitle') }}</h3>
                    <br />
                    <p v-if="allReceiveDataCorrect">{{ $t('conncoach_step5_section_1_ok') }}</p>
                    <div v-else>
                      <p>{{ $t('conncoach_step5_section_1_errors') }}</p>
                      <p>
                      <v-btn color="primary" v-on:click="downloadReceiveAdvice">{{ $t('conncoach_step2_download_advice')}}</v-btn>
                      </p>
                    </div>
                    <v-data-table
                      dense
                      hide-default-footer
                      :headers="sendResultsHeaders"
                      :items="receiveResultsData"
                      class="elevation-2"
                    >
                      <template v-slot:item.correct="{ item }" >
                        <StatusIcon :status="item.correct" />
                      </template>
                    </v-data-table>
                    <br />

                    <v-btn color="primary" v-on:click="moveToConclusion">{{ $t('conncoach_step5_to_conclusion') }}</v-btn>

                  </v-stepper-content>

                  <!--

                        Step 6: Conclusion. User can choose to start over.

                  -->
                  <v-stepper-content step="6">
                    <div style="margin-left: 2em; max-width: 800px;"">
                      <h3>{{ $t('conncoach_step6_longtitle') }}</h3>
                      <br />
                      <p>{{ $t('conncoach_step6_section_1') }}</p>
                      <p>{{ $t('conncoach_step6_section_2') }}</p>
                      <p>{{ $t('conncoach_step6_section_3') }}</p>

                      <v-btn color="primary" v-on:click="restartSession">{{ $t('conncoach_step6_restart') }}</v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>

            </v-card-text>
            <v-card-actions v-if="showControls">
                <v-btn
                  color="primary"
                  @click="prevStep()"
                >
                  {{ $t('back') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="nextStep()"
                >
                  {{ $t('next') }}
                </v-btn>
            </v-card-actions>
          </v-card>
      </v-layout>
    </v-container>
    <v-dialog v-model="showSendingDocumentSpinner" persistent max-width="450">
      <v-card style="height: 300px;">
        <v-card-title class="text-h5 primary darken-2 white--text">{{ $t('conncoach_step3_dontclose') }}</v-card-title>

        <v-card-text class="mt-5">
          <div class="text-center">
            <p>{{ $t('conncoach_step3_sending') }}</p>
            <br />
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <StandardUserConfirmation ref="userConfirmation"></StandardUserConfirmation>
    <BackendConnector ref="backend"></BackendConnector>
  </div>
</template>

<script>
import StatusIcon from "@/components/StatusIcon.vue";
import StandardUserConfirmation from "@/components/StandardUserConfirmation.vue";
import StandardUserMessage from "@/components/StandardUserMessage.vue";

export default {
  name: "home",
  components: {
    StandardUserConfirmation,
    StandardUserMessage,
    StatusIcon,
  },
  data() {
    return {
      currentStep: 0,
      showControls: false,
      sendReloadTimer: null,
      showSendingDocumentSpinner: false,
      currentTestData: {
        session_id: null,
        send_sender_can_receive_mlr: null
      },
      sendAdviceDownloaded: false,
      receiveAdviceDownloaded: false,
      sendInfoHeaders: [
        { value: 'field' },
        { value: 'value' }
      ],
      sendResultsHeaders: [
        { text: this.$t('conncoach_step2_header_field'), value: 'field' },
        { text: this.$t('conncoach_step2_header_expected'), value: 'expected' },
        { text: '', value: 'correct' },
        { text: this.$t('conncoach_step2_header_received'), value: 'received' }
      ],
      receiveRequireField: null,
      receiveRequireValue: null,
      receiveFieldValueNote: null,
      receiveFieldValueOrderNumber: null,
      receiveFieldValueProjectNumber: null,
      receiveFieldValueContractNumber: null,
      receiveFieldValueRouteCode: null,
      receiveFieldValueCustomerReference: null,
      receiveFormFieldRules: [v => !!v || this.$t("required_field")],
      receiveFormValid: false,
    }
  },
  methods: {
    prevStep() {
      this.currentStep -= 1;
    },
    nextStep() {
      this.currentStep = this.currentStep + 1;
    },
    goToStatusInfoPage() {
      window.open('https://peppolautoriteit.nl/factuur-status-berichten');
    },
    downloadSendAdvice() {
      let url = process.env.VUE_APP_ROOT_API + "/receiver/connectioncoach/send_advice/" + this.currentTestData.session_id + "?lang=" + this.$store.getters.getLanguage;;
      this.sendAdviceDownloaded = true;
      window.open(url);
      //alert("download not implemented yet");
    },
    moveToReceiveTest() {
      if (!this.allSendDataCorrect && !this.sendAdviceDownloaded) {
        let callbackConfirm = function(arg) { arg.currentStep = 3; }
        let callbackCancel = function() { }
        this.$refs.userConfirmation.doShowUserConfirmation(
          this.$t('conncoach_step2_continue_without_download_warning_title'),
          this.$t('conncoach_step2_continue_without_download_warning_content'),
          callbackConfirm,
          callbackCancel,
          this
        );
      } else {
        this.currentStep = 3;
      }
    },
    downloadReceiveAdvice() {
      let url = process.env.VUE_APP_ROOT_API + "/receiver/connectioncoach/receive_advice/" + this.currentTestData.session_id + "?lang=" + this.$store.getters.getLanguage;
      this.receiveAdviceDownloaded = true;
      window.open(url);
    },
    focusReceiveRequireValueField() {
      this.$refs.receiveRequireValueField.focus();
    },
    startNewSession() {
      let url = process.env.VUE_APP_ROOT_API + "/receiver/connectioncoach/session";
      this.$refs.backend.post(url, {}).then((response) => {
        this.currentTestData = response.data;
        this.currentStep = 1;
        this.fetchData();
      });
    },
    restartSession() {
      this.currentTestData = {
        session_id: null,
        send_sender_can_receive_mlr: null
      };
      this.currentStep = 0;
    },
    startReceiveTest() {
      this.showSendingDocumentSpinner = true;
      let url = process.env.VUE_APP_ROOT_API + "/receiver/connectioncoach/session/" + this.currentTestData.session_id + "/startReceiveTest";
      let postData = {}
      if (this.receiveRequireField && this.receiveRequireValue) {
        postData['override_field'] = this.receiveRequireField
        postData['override_value'] = this.receiveRequireValue
      }
      this.$refs.backend.post(url, postData).then((response) => {
        this.showSendingDocumentSpinner = false;
        this.currentTestData = response.data;
        this.currentStep = 4;
      }).catch(() => {
        this.showSendingDocumentSpinner = false;
      });
    },
    checkReceiveTest() {
      let url = process.env.VUE_APP_ROOT_API + "/receiver/connectioncoach/session/" + this.currentTestData.session_id + "/checkReceiveTest";
      let postData = {
        session_id: this.currentTestData.session_id,
        note: this.receiveFieldValueNote,
        order_number: this.receiveFieldValueOrderNumber,
        project_number: this.receiveFieldValueProjectNumber,
        contract_number: this.receiveFieldValueContractNumber,
        accounting_code: this.receiveFieldValueRouteCode,
        customer_reference: this.receiveFieldValueCustomerReference,
      }
      this.$refs.backend.post(url, postData).then((response) => {
        this.currentTestData = response.data
        this.currentStep = 5
      });
    },
    moveToConclusion() {
      if (!this.allReceiveDataCorrect && !this.receiveAdviceDownloaded) {
        let callbackConfirm = function(arg) { arg.currentStep = 6; }
        let callbackCancel = function() { }
        this.$refs.userConfirmation.doShowUserConfirmation(
          this.$t('conncoach_step2_continue_without_download_warning_title'),
          this.$t('conncoach_step5_continue_without_download_warning_content'),
          callbackConfirm,
          callbackCancel,
          this
        );
      } else {
        this.currentStep = 6;
      }
    },
    fetchData() {
      let url = process.env.VUE_APP_ROOT_API + "/receiver/connectioncoach/session/" + this.currentTestData.session_id;
      this.$refs.backend.get(url).then((response) => {
        this.currentTestData = response.data
        // If we are in a waiting state (step 1), reload the data every X seconds
        if (!this.haveSendResults) {
            if (this.sendReloadTimer == null) {
                this.sendReloadTimer = setInterval(() => {
                    this.fetchData();
                }, 1000)
            }
        } else {
            if (this.sendReloadTimer != null) {
                clearInterval(this.sendReloadTimer);
                this.sendReloadTimer = null;
            }
            if (this.currentStep == 1) {
                this.currentStep = 2;
            }
        }
      });
    },
    completedStepText(step) {
      if (this.currentStep > step) {
        return this.$t("step_completed");
      }
      return "";
    }
  },
  computed: {
    sendInfoData: function() {
      return [
        { field: this.$t('conncoach_step1_customer'), value: "NPa Peppol Test Tool" },
        { field: this.$t('conncoach_step1_adress'), value: "Wilhelmina van Pruisenweg 52" },
        { field: this.$t('conncoach_step1_postal'), value: "2595 AN" },
        { field: this.$t('conncoach_step1_city'), value: "Den Haag" },
        { field: this.$t('conncoach_step1_peppolid'), value: "00000099003214345001" },
      ]
    },
    sendFieldsData: function() {
      return [
        { field: this.$t('conncoach_step1_note'), value: this.currentTestData.send_note_expected },
        { field: this.$t('conncoach_step1_order_number'), value: this.currentTestData.send_order_number_expected },
        { field: this.$t('conncoach_step1_project_number'), value: this.currentTestData.send_project_number_expected },
        { field: this.$t('conncoach_step1_contract_number'), value: this.currentTestData.send_contract_number_expected },
        { field: this.$t('conncoach_step1_accounting_code'), value: this.currentTestData.send_accounting_code_expected },
        { field: this.$t('conncoach_step1_customer_reference'), value: this.currentTestData.send_customer_reference_expected },
      ]
    },
    haveSendResults: function() {
        return (this.currentTestData.send_note_received != null && this.currentTestData.send_note_received != "" ||
                this.currentTestData.send_order_number_received != null && this.currentTestData.send_order_number_received != "" ||
                this.currentTestData.send_project_number_received != null && this.currentTestData.send_project_number_received != "" ||
                this.currentTestData.send_contract_number_received != null && this.currentTestData.send_contract_number_received != "" ||
                this.currentTestData.send_accounting_code_received != null && this.currentTestData.send_accounting_code_received != "" ||
                this.currentTestData.send_customer_reference_received != null && this.currentTestData.send_customer_reference_received != "");
    },
    sendResultsData: function() {
      return [
        { field: this.$t('conncoach_step1_note'),
          expected: this.currentTestData.send_note_expected,
          correct: this.currentTestData.send_note_expected == this.currentTestData.send_note_received,
          received: this.currentTestData.send_note_received },
        { field: this.$t('conncoach_step1_order_number'),
          expected: this.currentTestData.send_order_number_expected,
          correct: this.currentTestData.send_order_number_expected == this.currentTestData.send_order_number_received,
          received: this.currentTestData.send_order_number_received },
        { field: this.$t('conncoach_step1_project_number'),
          expected: this.currentTestData.send_project_number_expected,
          correct: this.currentTestData.send_project_number_expected == this.currentTestData.send_project_number_received,
          received: this.currentTestData.send_project_number_received },
        { field: this.$t('conncoach_step1_contract_number'),
          expected: this.currentTestData.send_contract_number_expected,
          correct: this.currentTestData.send_contract_number_expected == this.currentTestData.send_contract_number_received,
          received: this.currentTestData.send_contract_number_received },
        { field: this.$t('conncoach_step1_accounting_code'),
          expected: this.currentTestData.send_accounting_code_expected,
          correct: this.currentTestData.send_accounting_code_expected == this.currentTestData.send_accounting_code_received,
          received: this.currentTestData.send_accounting_code_received },
        { field: this.$t('conncoach_step1_customer_reference'),
          expected: this.currentTestData.send_customer_reference_expected,
          correct: this.currentTestData.send_customer_reference_expected == this.currentTestData.send_customer_reference_received,
          received: this.currentTestData.send_customer_reference_received }
      ]
    },
    receiveResultsData: function() {
      return [
        { field: this.$t('conncoach_step1_note'),
          expected: this.currentTestData.receive_note_expected,
          correct: this.currentTestData.receive_note_expected == this.currentTestData.receive_note_received,
          received: this.currentTestData.receive_note_received },
        { field: this.$t('conncoach_step1_order_number'),
          expected: this.currentTestData.receive_order_number_expected,
          correct: this.currentTestData.receive_order_number_expected == this.currentTestData.receive_order_number_received,
          received: this.currentTestData.receive_order_number_received },
        { field: this.$t('conncoach_step1_project_number'),
          expected: this.currentTestData.receive_project_number_expected,
          correct: this.currentTestData.receive_project_number_expected == this.currentTestData.receive_project_number_received,
          received: this.currentTestData.receive_project_number_received },
        { field: this.$t('conncoach_step1_contract_number'),
          expected: this.currentTestData.receive_contract_number_expected,
          correct: this.currentTestData.receive_contract_number_expected == this.currentTestData.receive_contract_number_received,
          received: this.currentTestData.receive_contract_number_received },
        { field: this.$t('conncoach_step1_accounting_code'),
          expected: this.currentTestData.receive_accounting_code_expected,
          correct: this.currentTestData.receive_accounting_code_expected == this.currentTestData.receive_accounting_code_received,
          received: this.currentTestData.receive_accounting_code_received },
        { field: this.$t('conncoach_step1_customer_reference'),
          expected: this.currentTestData.receive_customer_reference_expected,
          correct: this.currentTestData.receive_customer_reference_expected == this.currentTestData.receive_customer_reference_received,
          received: this.currentTestData.receive_customer_reference_received }
      ]
    },
    allSendDataCorrect: function() {
      let td = this.currentTestData;
      return (
        td.send_note_expected == td.send_note_received &&
        td.send_order_number_expected == td.send_order_number_received &&
        td.send_project_number_expected == td.send_project_number_received &&
        td.send_contract_number_expected == td.send_contract_number_received &&
        td.send_accounting_code_expected == td.send_accounting_code_received &&
        td.send_customer_reference_expected == td.send_customer_reference_received
      )
    },
    allReceiveDataCorrect: function() {
      let td = this.currentTestData;
      return (
        td.receive_note_expected == td.receive_note_received &&
        td.receive_order_number_expected == td.receive_order_number_received &&
        td.receive_project_number_expected == td.receive_project_number_received &&
        td.receive_contract_number_expected == td.receive_contract_number_received &&
        td.receive_accounting_code_expected == td.receive_accounting_code_received &&
        td.receive_customer_reference_expected == td.receive_customer_reference_received
      )
    },
    receiveRequireFieldNames: function() {
      return [
        { text: this.$t('conncoach_step1_note'), value: 'note' },
        { text: this.$t('conncoach_step1_order_number'), value: 'order_number' },
        { text: this.$t('conncoach_step1_project_number'), value: 'project_number' },
        { text: this.$t('conncoach_step1_contract_number'), value: 'contract_number' },
        { text: this.$t('conncoach_step1_accounting_code'), value: 'accounting_code' },
        { text: this.$t('conncoach_step1_customer_reference'), value: 'customer_reference' },
      ];
    },
    currentStepTitle: function() {
		switch (this.currentStep) {
		  case 0:
			return this.$t('conncoach_step0_title')
			break;
		  case 1:
			return this.$t('conncoach_step1_title')
			break;
		  case 2:
			return this.$t('conncoach_step2_title')
			break;
		  case 3:
			return this.$t('conncoach_step3_title')
			break;
		  case 4:
			return this.$t('conncoach_step4_title')
			break;
		  case 5:
			return this.$t('conncoach_step5_title')
			break;
		  case 6:
			return this.$t('conncoach_step6_title')
			break;
		  default:
		    return '-';
		}
    }
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
    if (this.$route.query.session_id) {
        this.currentTestData.session_id = this.$route.query.session_id;
        this.fetchData();
    }
    if (this.$route.query.show_controls) {
        this.showControls = true;
    }
  }
};
</script>
<style>
.v-stepper__step__step.primary {
    background-color: red !important;
    border-color: red !important;
    color: white !important;
}
.v-stepper__step__step {
    color: black !important;
}
@media only screen and (max-width: 959px) {
  .v-stepper__label {
    display: flex !important;
  }
  .v-divider {
    display: none !important;
  }
}
</style>
