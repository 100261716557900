<template>
    <div>
      <div>
        <v-tabs color="secondary" dark slider-color="yellow">
          <v-tab>{{ $t("onboarder_approval_open_requests") }}</v-tab>
          <v-tab-item>
            <v-btn class="primary" @click="reloadButtonClicked">{{
              $t("reload")
            }}</v-btn>
            <v-data-table
              :headers="participantHeaders"
              :items="approvalRequests"
              :footer-props="{
                itemsPerPageOptions:[10,25,50,100,{ text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]
              }"
              hide-actions0
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-left">
                    {{ props.item.date_joined | formatDateTime }}
                  </td>
                  <td class="text-left">{{ props.item.organization }}</td>
                  <td class="text-left">{{ props.item.email }}</td>
                  <td class="text-left">
                    <v-btn
                      class="primary"
                      @click="approveParticipant(props.item.user_id)"
                      >{{ $t("approve") }}</v-btn
                    >
                  </td>
                  <td class="text-left">
                    <v-btn
                      class="primary"
                      @click="denyParticipant(props.item.user_id)"
                      >{{ $t("onboarder_approval_deny") }}</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab>{{ $t("onboarder_approval_approved") }}</v-tab>
          <v-tab-item>
            <v-data-table
              :headers="participantHeaders"
              :items="approvedParticipants"
              :footer-props="{
                itemsPerPageOptions:[10,25,50,100,{ text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]
              }"
              hide-actions0
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-left">
                    {{ props.item.date_joined | formatDateTime }}
                  </td>
                  <td class="text-left">{{ props.item.organization }}</td>
                  <td class="text-left">{{ props.item.email }}</td>
                  <td class="text-left">
                    <v-btn
                      class="primary"
                      @click="revokeParticipant(props.item.user_id)"
                      >{{ $t("onboarder_approval_is_not_participant") }}</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab>{{ $t("onboarder_approval_nonparticipants") }}</v-tab>
          <v-tab-item>
            <v-data-table
              :headers="participantHeaders"
              :items="nonParticipantUsers"
              :footer-props="{
                itemsPerPageOptions:[10,25,50,100,{ text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]
              }"
              hide-actions0
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-left">
                    {{ props.item.date_joined | formatDateTime }}
                  </td>
                  <td class="text-left">{{ props.item.organization }}</td>
                  <td class="text-left">{{ props.item.email }}</td>
                  <td class="text-left">
                    <v-btn
                      class="primary"
                      @click="approveParticipant(props.item.user_id)"
                      >{{ $t("onboarder_approval_is_participant") }}</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </div>
      <BackendConnector ref="backend"></BackendConnector>
    </div>

</template>

<script>
// @ is an alias to /src
import BackendConnector from "@/components/BackendConnector";

export default {
  name: "participant_approval",
  components: {
    BackendConnector
  },
  data() {
    return {
      activeTab: "participants",
      approvalRequests: [],
      approvedParticipants: [],
      nonParticipantUsers: [],
      participantItems: [],
      participantHeaders: [
        { text: this.$t("date"), value: "name" },
        { text: this.$t("affiliation"), value: "affiliation" },
        { text: this.$t("email"), value: "email" }
      ]
    };
  },
  methods: {
    loadData: function() {
      // TMP: mock data
      var url =
        process.env.VUE_APP_ROOT_API + "/onboarding/participant_approval/";
      var self = this;
      this.$refs.backend
        .get_auth(url)
        .then(function(response) {
          self.unloadData();
          self.participantItems = response.data;
          for (let i = 0; i < response.data.length; i++) {
            let userdata = response.data[i];
            if (userdata.access_requested && !userdata.is_participant) {
              self.approvalRequests.push(userdata);
            } else if (userdata.is_participant) {
              self.approvedParticipants.push(userdata);
            } else {
              self.nonParticipantUsers.push(userdata);
            }
          }
        })
        .catch(function(error) {
          // handled by BackendConnector
        });
    },
    unloadData: function() {
      this.participantItems = [];
      this.approvalRequests = [];
      this.approvedParticipants = [];
      this.nonParticipantUsers = [];
    },
    reloadButtonClicked: function() {
      this.loadData();
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    },
    approveParticipant: function(user_id) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/onboarding/approve_participant/" +
        user_id +
        "/";
      alert(url);
      let self = this;
      this.$refs.backend
        .post_auth(url)
        .then(function(response) {
          if (response.data && response.data.error) {
            alert("Error from server: " + response.data.error);
          } else {
            self.loadData();
          }
        })
        .catch(function(error) {
          // handled by BackendConnector
        });
    },
    denyParticipant: function(user_id) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/onboarding/deny_participant/" +
        user_id +
        "/";
      let self = this;
      this.$refs.backend
        .post_auth(url)
        .then(function(response) {
          if (response.data && response.data.error) {
            alert("Error from server: " + response.data.error);
          } else {
            self.loadData();
          }
        })
        .catch(function(error) {
          // handled by BackendConnector
        });
    },
    revokeParticipant: function(user_id) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/onboarding/revoke_participant/" +
        user_id +
        "/";
      let self = this;
      this.$refs.backend
        .post_auth(url)
        .then(function(response) {
          if (response.data && response.data.error) {
            alert("Error from server: " + response.data.error);
          } else {
            self.loadData();
          }
        })
        .catch(function(error) {
          // handled by BackendConnector
        });
    }
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "updateToken":
          this.unloadData();
          try {
            // TODO: maybe not reload on login but redirect?
            if (this.$refs && this.$refs.backend) {
              this.$nextTick(this.loadData);
            }
          } catch (berr) {
            alert(
              "[XX] error in loadData for TestAccessPointDetails.vue (sub updateToken): " +
                berr
            );
          }
          break;
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
        case "LOGOUT":
          this.unloadData();
          break;
      }
    });
    try {
      this.loadData();
    } catch (berr) {
      console.log(berr);
    }
  }
};
</script>
