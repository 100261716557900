<template>
    <div class="discover">
        <v-card max-width="600px" class="mx-auto mt-4">
          <v-card-title class="cardheader" mb-0 pb-0>
            <h1 class="text-h5">{{ $t("discover") }}</h1>
            <v-layout wrap justify-end>
              <v-card-actions class="text-center">
                <v-btn icon @click.stop="" :href="getHelpURL" :title="$t('button_help')+$t('discover')"
                  ><v-icon large color="primary">help</v-icon></v-btn
                >
              </v-card-actions>
            </v-layout>
          </v-card-title>
          <v-card-title class="text-body-1 text-left" mb-0 pb-0>
            {{ $t("discover_description") }}
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md text-left mt-0 pt-0>
              <v-layout wrap>
                  <v-text-field
                    xs8
                    :label="$t('iso6523_identifier')"
                    v-on:keyup.enter="doSMPLookup"
                    value=""
                    v-model="iso6523_identifier"
                  ></v-text-field>
                  <MyAutocomplete
                    :label="$t('change_scheme')"
                    :items="iso6523_items"
                    item-title-property="displayname",
                    item-subtitle-property="name",
                    item-value-property="code"
                    :callback="iso6523SelectionChangedNew"
                  />
              </v-layout>
              <v-layout wrap>
                  <v-switch
                    :label="getProductionTestText"
                    v-model="useSML"
                  ></v-switch>
              </v-layout>
              <v-layout wrap>
                  <v-btn color="primary" v-on:click.native="doSMPLookup">{{
                    $t("lookup")
                  }}</v-btn>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      <div v-if="isLoading">
        <v-card>
          <v-card-text>
            <v-progress-circular
              color="blue-grey"
              indeterminate
              role="status"
              :title="$t('loading')"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="haveResults">
        <v-card>
          <v-card-text>
            <p>{{ $t("select_endpoint") }}</p>

            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :items-per-page="1000"
              hide-default-footer
              @click:row="tableItemClicked"
              style="cursor: pointer;"
            >
              <template v-slot:item.customization_id="{ item }">
                <v-btn icon class="secondary d-sr-only-focusable" :title="$t('details')">
                  <v-icon>search</v-icon>
                </v-btn>
                {{ item.short_customization_id }}
              </template>
              <template v-slot:item.process="{ item }">
                {{ item.process }}
              </template>
              <template v-slot:item.url="{ item }">
                {{ item.endpoint }}
              </template>
              <template v-slot:item.transport="{ item }">
                {{ item.transport }}
              </template>

              <template slot="no-data">
                  <!-- TODO: this one is not shown, remove? -->
                  <v-alert
                    slot="no-results"
                    :value="true"
                    color="error"
                    icon="warning"
                    style="color: #000000;"
                  >
                    {{ $t("no_results", [search]) }}
                  </v-alert>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
      <div role="status">
        <v-alert v-model="haveMessage" :value="true" type="info" style="color: #000000;">
          <div v-if="haveMessage" lang="en">
          {{ message }}
          </div>
        </v-alert>
      </div>

      <v-dialog v-model="showDialog" aria-labelledby="endpoint_information_title">
        <v-card>
          <v-layout wrap justify-end>
            <v-card-actions class="text-center">
              <v-btn icon color="primary" @click.stop="showDialog = false" :title="$t('close')"
                ><v-icon>close</v-icon></v-btn
              >
            </v-card-actions>
          </v-layout>

          <v-card-title id="endpoint_information_title">
            {{ $t("endpoint_information") }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              :items="selectedTableItemData"
              :headers="selectedItemTableHeaders"
              :search="search"
              hide-default-footer
              hide-default-header
              v-if="showDialog"
              style="word-break: break-all;"
            >
              <template slot="progress">
                <th colspan="2" class="column">{{ $t('progress') }}</th>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import MyAutocomplete from "@/components/MyAutocomplete.vue";

/* Peppol Code List Document Types version 8.9 */
var KNOWN_CUSTOMIZATION_IDS = {
  "urn:www.peppol.eu:schema:xsd:VirtualCompanyDossier-1::VirtualCompanyDossier##urn:www.cenbii.eu:transaction:biicoretrdm991:ver0.1:#urn:www.peppol.eu:bis:peppol991a:ver1.0::0.1":
    "Virtual Company Dossier",
  "urn:www.peppol.eu:schema:xsd:VirtualCompanyDossierPackage-1::VirtualCompanyDossierPackage##urn:www.cenbii.eu:transaction:biicoretrdm992:ver0.1:#urn:www.peppol.eu:bis:peppol992a:ver1.0::0.1":
    "Virtual Company Dossier Package",
  "urn:www.peppol.eu:schema:xsd:CatalogueTemplate-1::CatalogueTemplate##urn:www.cenbii.eu:transaction:biicoretrdm993:ver0.1:#urn:www.peppol.eu:bis:peppol993a:ver1.0::0.1":
    "Catalogue Template",
  "urn:oasis:names:specification:ubl:schema:xsd:Catalogue-2::Catalogue##urn:www.cenbii.eu:transaction:biicoretrdm019:ver1.0:#urn:www.peppol.eu:bis:peppol1a:ver1.0::2.0":
    "PEPPOL Catalogue profile Catalogue V1",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:www.cenbii.eu:transaction:biicoretrdm057:ver1.0:#urn:www.peppol.eu:bis:peppol1a:ver1.0::2.0":
    "PEPPOL Catalogue profile ApplicationResponse V1",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:www.cenbii.eu:transaction:biicoretrdm058:ver1.0:#urn:www.peppol.eu:bis:peppol1a:ver1.0::2.0":
    "PEPPOL Catalogue profile ApplicationResponse V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Catalogue-2::Catalogue##urn:www.cenbii.eu:transaction:biitrns019:ver2.0:extended:urn:www.peppol.eu:bis:peppol1a:ver4.0::2.1":
    "PEPPOL Catalogue profile V4",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biicoretrdm001:ver1.0:#urn:www.peppol.eu:bis:peppol3a:ver1.0::2.0":
    "PEPPOL Order profile V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biitrns001:ver2.0:extended:urn:www.peppol.eu:bis:peppol03a:ver2.0::2.1":
    "PEPPOL Order profile V2 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biitrns001:ver2.0:extended:urn:www.peppol.eu:bis:peppol3a:ver2.0::2.1":
    "PEPPOL Order profile V2",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biicoretrdm010:ver1.0:#urn:www.peppol.eu:bis:peppol4a:ver1.0::2.0":
    "PEPPOL Invoice profile V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biitrns010:ver2.0:extended:urn:www.peppol.eu:bis:peppol4a:ver2.0::2.1":
    "PEPPOL Invoice profile V2",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biicoretrdm010:ver1.0:#urn:www.peppol.eu:bis:peppol5a:ver1.0::2.0":
    "PEPPOL Billing profile Invoice V1",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:www.cenbii.eu:transaction:biicoretrdm014:ver1.0:#urn:www.peppol.eu:bis:peppol5a:ver1.0::2.0":
    "PEPPOL Billing profile CreditNote V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biicoretrdm015:ver1.0:#urn:www.peppol.eu:bis:peppol5a:ver1.0::2.0":
    "PEPPOL Billing profile Invoice V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biitrns010:ver2.0:extended:urn:www.peppol.eu:bis:peppol5a:ver2.0::2.1":
    "PEPPOL Billing profile Invoice V2",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:www.cenbii.eu:transaction:biitrns014:ver2.0:extended:urn:www.peppol.eu:bis:peppol5a:ver2.0::2.1":
    "PEPPOL Billing profile CreditNote V2",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biicoretrdm001:ver1.0:#urn:www.peppol.eu:bis:peppol6a:ver1.0::2.0":
    "PEPPOL Procurement profile Order V1",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponseSimple-2::OrderResponseSimple##urn:www.cenbii.eu:transaction:biicoretrdm002:ver1.0:#urn:www.peppol.eu:bis:peppol6a:ver1.0::2.0":
    "PEPPOL Procurement profile OrderResponseSimple V1",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponseSimple-2::OrderResponseSimple##urn:www.cenbii.eu:transaction:biicoretrdm003:ver1.0:#urn:www.peppol.eu:bis:peppol6a:ver1.0::2.0":
    "PEPPOL Procurement profile OrderResponseSimple V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biicoretrdm010:ver1.0:#urn:www.peppol.eu:bis:peppol6a:ver1.0::2.0":
    "PEPPOL Procurement profile Invoice V1",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:www.cenbii.eu:transaction:biicoretrdm014:ver1.0:#urn:www.peppol.eu:bis:peppol6a:ver1.0::2.0":
    "PEPPOL Procurement profile CreditNote V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biicoretrdm015:ver1.0:#urn:www.peppol.eu:bis:peppol6a:ver1.0::2.0":
    "PEPPOL Procurement profile Invoice V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biicoretrdm010:ver1.0:#urn:www.peppol.eu:bis:peppol4a:ver1.0#urn:www.difi.no:ehf:faktura:ver1::2.0":
    "EHF Invoice V1",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:www.cenbii.eu:transaction:biicoretrdm014:ver1.0:#urn:www.cenbii.eu:profile:biixx:ver1.0#urn:www.difi.no:ehf:kreditnota:ver1::2.0":
    "Standalone Credit Note according to EHF V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biitrns001:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0::2.1":
    "PEPPOL Ordering profile Order V1",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::Order##urn:www.cenbii.eu:transaction:biitrns076:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0::2.1":
    "PEPPOL Ordering profile OrderResponse V1 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:www.cenbii.eu:transaction:biitrns076:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0::2.1":
    "PEPPOL Ordering profile OrderResponse V1",
  "urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2::DespatchAdvice##urn:www.cenbii.eu:transaction:biitrns016:ver1.0:extended:urn:www.peppol.eu:bis:peppol30a:ver1.0::2.1":
    "PEPPOL Despatch Advice V1",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:www.cenbii.eu:transaction:biitrns071:ver2.0:extended:urn:www.peppol.eu:bis:peppol36a:ver1.0::2.1":
    "PEPPOL Message Level Response V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0::2.1":
    "Peppol BIS Billing UBL Invoice V3",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0::2.1":
    "Peppol BIS Billing UBL CreditNote V3",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biitrns001:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0:extended:urn:fdc:peppol-authority.co.uk:spec:ordering:ver1.0::2.1":
    "DHSC Customized Ordering profile Order V1",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::Order##urn:www.cenbii.eu:transaction:biitrns076:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0:extended:urn:fdc:peppol-authority.co.uk:spec:ordering:ver1.0::2.1":
    "DHSC Customized Ordering profile OrderResponse V1 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:www.cenbii.eu:transaction:biitrns076:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0:extended:urn:fdc:peppol-authority.co.uk:spec:ordering:ver1.0::2.1":
    "DHSC Customized Ordering profile OrderResponse V1",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0::D16B":
    "Peppol BIS Billing CII Invoice V3",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestRequest-2::ExpressionOfInterestRequest##urn:www.cenbii.eu:transaction:biitrdm081:ver3.0:extended:urn:fdc:peppol.eu:2017:pracc:t001:ver1.0::2.2":
    "Peppol Procurement procedure subscription Request V1",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestResponse-2::ExpressionOfInterestResponse##urn:www.cenbii.eu:transaction:biitrdm082:ver3.0:extended:urn:fdc:peppol.eu:2017:pracc:t002:ver1.0::2.2":
    "Peppol Procurement procedure subscription Response V1",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderStatusRequest-2::TenderStatusRequest##urn:www.cenbii.eu:transaction:biitrdm097:ver3.0:extended:urn:fdc:peppol.eu:2017:pracc:t003:ver1.0::2.2":
    "Peppol Procurement document access TenderStatusRequest V1",
  "urn:oasis:names:specification:ubl:schema:xsd:CallForTenders-2::CallForTenders##urn:www.cenbii.eu:transaction:biitrdm083:ver3.0:extended:urn:fdc:peppol.eu:2017:pracc:t004:ver1.0::2.2":
    "Peppol Procurement document access CallForTenders V1",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderReceipt-2::TenderReceipt##urn:www.cenbii.eu:transaction:biitrdm045:ver3.0:extended:urn:fdc:peppol.eu:2017:pracc:t006:ver1.0::2.2":
    "Peppol Tender Submission TenderReceipt V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Tender-2::Tender##urn:www.cenbii.eu:transaction:biitrdm090:ver3.0:extended:urn:fdc:peppol.eu:2017:pracc:t005:ver1.0::2.2":
    "Peppol Tender Submission Tender V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.1::2.1":
    "XRechnung UBL Invoice V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.1::2.1":
    "XRechnung UBL CreditNote V1.1",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.1::D16B":
    "XRechnung CII Invoice V1.1",
  "urn:oioubl:names:specification:oioubl:schema:xsd:UtilityStatement-2::UtilityStatement##OIOUBL-2.02::2.0":
    "OIOUBL UtilityStatement V2.02",
  "urn:oasis:names:specification:ubl:schema:xsd:Reminder-2::Reminder##OIOUBL-2.02::2.0":
    "OIOUBL Reminder V2.02",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#conformant#urn:UBL.BE:1.0.0.20180214::2.1":
    "UBL.BE Invoice 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#conformant#urn:UBL.BE:1.0.0.20180214::2.1":
    "UBL.BE Credit Note 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:www.peppol.eu:transaction:biitrns111:ver1.0::2.1":
    "Peppol Invoice Response V1",
  "urn:oasis:names:specification:ubl:schema:xsd:Catalogue-2::Catalogue##urn:fdc:peppol.eu:poacc:trns:catalogue:3::2.1":
    "Peppol Catalogue transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:poacc:trns:catalogue_response:3::2.1":
    "Peppol Catalogue Response transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:fdc:peppol.eu:poacc:trns:order:3::2.1":
    "Peppol Order transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:poacc:trns:invoice_response:3::2.1":
    "Peppol Invoice Response transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Catalogue-2::Catalogue##urn:fdc:peppol.eu:poacc:trns:punch_out:3::2.1":
    "Peppol Punch Out transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:fdc:peppol.eu:poacc:trns:order_response:3::2.1":
    "Peppol Order Response transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2::DespatchAdvice##urn:fdc:peppol.eu:poacc:trns:despatch_advice:3::2.1":
    "Peppol Despatch Advice transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:fdc:peppol.eu:poacc:trns:order_agreement:3::2.1":
    "Peppol Order Agreement transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:poacc:trns:mlr:3::2.1":
    "Peppol Message Level Response transaction 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:nen.nl:nlcius:v1.0::2.1":
    "SI-UBL 2.0 Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:fdc:nen.nl:nlcius:v1.0::2.1":
    "SI-UBL 2.0 CreditNote",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:billing:international:sg:3.0::2.1":
    "SG Peppol BIS Billing 3.0 Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::CreditNote##urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:billing:international:sg:3.0::2.1":
    "SG PEPPOL BIS Billing 3.0 Credit Note (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:billing:international:sg:3.0::2.1":
    "SG Peppol BIS Billing 3.0 Credit Note",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.2::2.1":
    "XRechnung UBL Invoice V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.2::2.1":
    "XRechnung UBL CreditNote V1.2",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.2::D16B":
    "XRechnung CII Invoice V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:fdc:www.efaktura.gov.pl:ver1.0:trns:account_corr:ver1.0::2.1":
    "PEF.PL Accounting Note v1",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0#extended#urn:fdc:www.efaktura.gov.pl:ver1.0::2.1":
    "PEF.PL Correcting Invoice v1",
  "urn:oasis:names:specification:ubl:schema:xsd:ReceiptAdvice-2::ReceiptAdvice##urn:fdc:www.efaktura.gov.pl:ver1.0:trns:receipt_advice:ver1.0::2.1":
    "PEF.PL Receipt Advice v1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:billing:international:aunz:3.0::2.1":
    "AU-NZ Peppol BIS Billing 3.0 Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:billing:international:aunz:3.0::2.1":
    "AU-NZ Peppol BIS Billing 3.0 CreditNote",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:selfbilling:international:aunz:3.0::2.1":
    "AU-NZ Self-Billing 3.0 Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:selfbilling:international:aunz:3.0::2.1":
    "AU-NZ Self-Billing 3.0 CreditNote",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:www.cenbii.eu:transaction:biitrns010:ver2.0:extended:urn:www.peppol.eu:bis:peppol4a:ver2.0:extended:urn:www.simplerinvoicing.org:si:si-ubl:ver1.2::2.1":
    "SI-UBL 1.2 Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biitrns001:ver2.0:extended:urn:www.peppol.eu:bis:peppol3a:ver2.0:extended:urn:www.simplerinvoicing.org:si:si-ubl:ver1.2::2.1":
    "SI-UBL 1.2 Order",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.3::2.1":
    "XRechnung UBL Invoice V1.3",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.3::2.1":
    "XRechnung UBL CreditNote V1.3",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.3::16B":
    "XRechnung CII Invoice V1.3",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0::2.1":
    "XRechnung UBL Invoice V2.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0::2.1":
    "XRechnung UBL CreditNote V2.0",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0::16B":
    "XRechnung CII Invoice V2.0 (invalid)",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0::D16B":
    "XRechnung CII Invoice V2.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.3#conformant#urn:xoev-de:kosit:extension:xrechnung_1.3::2.1":
    "XRechnung UBL Invoice V1.3 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.3#conformant#urn:xoev-de:kosit:extension:xrechnung_1.3::2.1":
    "XRechnung UBL CreditNote V1.3 Extension",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_1.3#conformant#urn:xoev-de:kosit:extension:xrechnung_1.3::16B":
    "XRechnung CII Invoice V1.3 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:nen.nl:nlcius:v1.0#conformant#urn:fdc:nen.nl:gaccount:v1.0::2.1":
    "SI-UBL 2.0 G-Account Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:fdc:peppol.eu:poacc:trns:order:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.1":
    "EHF Advanced Order Initiation 3.0 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderChange-2::OrderChange##urn:fdc:anskaffelser.no:2019:ehf:spec:adv-order-change:3.0::2.1":
    "EHF Advanced Order Change 3.0 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderCancellation-2::OrderCancellation##urn:fdc:anskaffelser.no:2019:ehf:spec:adv-order-cancellation:3.0::2.1":
    "EHF Advanced Order Cancellation 3.0 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:fdc:peppol.eu:poacc:trns:order_response:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.1":
    "EHF Advanced Order Response 3.0 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017::2.1":
    "EN 16931 UBL Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017::2.1":
    "EN 16931 UBL CreditNote",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017::D16B":
    "EN 16931 CII Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0#conformant#urn:xoev-de:kosit:extension:xrechnung_2.0::2.1":
    "XRechnung UBL Invoice V2.0 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0#conformant#urn:xoev-de:kosit:extension:xrechnung_2.0::2.1":
    "XRechnung UBL CreditNote V2.0 Extension",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0#conformant#urn:xoev-de:kosit:extension:xrechnung_2.0::16B":
    "XRechnung CII Invoice V2.0 Extension (invalid)",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.0#conformant#urn:xoev-de:kosit:extension:xrechnung_2.0::D16B":
    "XRechnung CII Invoice V2.0 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:fdc:peppol.eu:poacc:trns:order:3:restrictive:urn:www.agid.gov.it:trns:ordine:3.1::2.1":
    "SimpleOrder_IT",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:fdc:peppol.eu:poacc:trns:order_response:3:restrictive:urn:www.agid.gov.it:trns:risposta_ordine:3.0::2.1":
    "OrderResponse_IT",
  "urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2::DespatchAdvice##urn:fdc:peppol.eu:poacc:trns:despatch_advice:3:extended:urn:www.agid.gov.it:trns:ddt:3.1::2.1":
    "DespatchAdvice_IT",
  "urn:kosit:names:spec:peppol-reporting:schema:xsd:Reporting-1::APData##Reporting::1.0":
    "German Peppol Usage Evaluation AP",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:fdc:peppol.eu:poacc:trns:order:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.2":
    "EHF Advanced Order Initiation 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderChange-2::OrderChange##urn:fdc:anskaffelser.no:2019:ehf:spec:adv-order-change:3.0::2.2":
    "EHF Advanced Order Change 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderCancellation-2::OrderCancellation##urn:fdc:anskaffelser.no:2019:ehf:spec:adv-order-cancellation:3.0::2.2":
    "EHF Advanced Order Cancellation 3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:fdc:peppol.eu:poacc:trns:order_response:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.2":
    "EHF Advanced Order Response 3.0",
  "urn:iso:std:iso:20022:tech:xsd:pain.001.001.03::Document##urn:fdc:bits.no:2017:iso20022:1.5::03":
    "EHF Payment initiation (Profile 01) (Pain.001 Message)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:pain.002.001.03:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment initiation (Profile 01) (Pain.002 Reception Acknowledgement)",
  "urn:fdc:difi.no:2017:payment:extras-1::HandlingException##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:pain.002.001.03:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment initiation (Profile 01) (Pain.002 Handling Exception)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.054.001.02:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment initiation (Profile 01) (Camt.054 Reception Acknowledgement)",
  "urn:fdc:difi.no:2017:payment:extras-1::HandlingException##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.054.001.02:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment initiation (Profile 01) (Camt.054 Handling Exception)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:pain.001.001.03:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment initiation (Profile 01) (Pain.001 Reception Acknowledgement)",
  "urn:fdc:difi.no:2017:payment:extras-1::HandlingException##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:pain.001.001.03:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment initiation (Profile 01) (Pain.001 Handling Exception)",
  "urn:iso:std:iso:20022:tech:xsd:pain.002.001.03::Document##urn:fdc:bits.no:2017:iso20022:1.5::03":
    "EHF Payment initiation (Profile 01) (Pain.002 Message)",
  "urn:iso:std:iso:20022:tech:xsd:camt.054.001.02::Document##urn:fdc:bits.no:2017:iso20022:1.5::02":
    "EHF Payment initiation (Profile 01) (Camt.054 Message)",
  "urn:iso:std:iso:20022:tech:xsd:camt.055.001.01::Document##urn:fdc:bits.no:2017:iso20022:1.5::01":
    "EHF Cancellation of General Credit transfer Initiation (Profile 02) (Camt.055 Message)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.029.001.03:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Cancellation of General Credit transfer Initiation (Profile 02) (Camt.029 Reception Acknowledgement)",
  "urn:fdc:difi.no:2017:payment:extras-1::HandlingException##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.029.001.03:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Cancellation of General Credit transfer Initiation (Profile 02) (Camt.029 Handling Exception)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.055.001.01:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Cancellation of General Credit transfer Initiation (Profile 02) (Camt.055 Reception Acknowledgement)",
  "urn:fdc:difi.no:2017:payment:extras-1::HandlingException##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.055.001.01:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Cancellation of General Credit transfer Initiation (Profile 02) (Camt.055 Handling Exception)",
  "urn:iso:std:iso:20022:tech:xsd:camt.029.001.03::Document##urn:fdc:bits.no:2017:iso20022:1.5::03":
    "EHF Cancellation of General Credit transfer Initiation (Profile 02) (Camt.029 Message)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:urn:iso:std:iso:20022:tech:xsd:camt.054.001.02:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Accounting/General Ledger/cash management (Profile 09) (Camt.054 Reception Acknowledgement)",
  "urn:iso:std:iso:20022:tech:xsd:camt.053.001.02::Document##urn:fdc:bits.no:2017:iso20022:1.5::02":
    "EHF Payment Profile 10 (B2C account statement)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.053.001.02:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment Profile 10 (Camt.053 Reception Acknowledgement)",
  "urn:fdc:difi.no:2017:payment:extras-1::HandlingException##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.053.001.02:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment Profile 10 (Camt.053 Handling Exception)",
  "urn:iso:std:iso:20022:tech:xsd:camt.052.001.02::Document##urn:fdc:bits.no:2017:iso20022:1.5::02":
    "EHF Payment Profile 11 (B2C account report)",
  "urn:fdc:difi.no:2017:payment:extras-1::ReceptionAcknowledgement##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.052.001.02:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment Profile 11 (Camt.052 Reception Acknowledgement)",
  "urn:fdc:difi.no:2017:payment:extras-1::HandlingException##urn:fdc:difi.no:2017:payment:handling:1.0:for:urn:iso:std:iso:20022:tech:xsd:camt.052.001.02:restricted:urn:fdc:bits.no:2017:iso20022:1.5::1.0":
    "EHF Payment Profile 11 (Camt.052 Handling Exception)",
  "urn:oasis:names:specification:ubl:schema:xsd:Enquiry-2::Enquiry##urn:fdc:peppol.eu:prac:trns:t007:1.0::2.2":
    "Peppol Tendering Questions V1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:EnquiryResponse-2::EnquiryResponse##urn:fdc:peppol.eu:prac:trns:t008:1.0::2.2":
    "Peppol Tendering Answers V1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Enquiry-2::Enquiry##urn:fdc:peppol.eu:prac:trns:t009:1.0::2.2":
    "Peppol Tender Clarification Request V1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:EnquiryResponse-2::EnquiryResponse##urn:fdc:peppol.eu:prac:trns:t010:1.0::2.2":
    "Peppol Tender Clarification Response V1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Catalogue-2::Catalogue##urn:www.cenbii.eu:transaction:biitrns019:ver2.0:extended:urn:www.peppol.eu:bis:peppol1a:ver2.0:extended:urn:www.difi.no:ehf:katalog:ver1.0::2.1":
    "EHF Catalogue 1.0 (Profile 1A) (Catalogue)",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:www.cenbii.eu:transaction:biitrns058:ver2.0:extended:urn:www.difi.no:ehf:katalogbekreftelse:ver1.0::2.1":
    "EHF Catalogue 1.0 (Profile 1A) (Catalogue Response)",
  "urn:oasis:names:specification:ubl:schema:xsd:Order-2::Order##urn:www.cenbii.eu:transaction:biitrns001:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0:extended:urn:www.difi.no:ehf:ordre:ver1.0::2.1":
    "EHF Ordering 1.0 (Profile 28A) (Order)",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:www.cenbii.eu:transaction:biitrns076:ver2.0:extended:urn:www.peppol.eu:bis:peppol28a:ver1.0:extended:urn:www.difi.no:ehf:ordrebekreftelse:ver1.0::2.1":
    "EHF Ordering 1.0 (Profile 28A) (Order Response)",
  "urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2::DespatchAdvice##urn:www.cenbii.eu:transaction:biitrns016:ver1.0:extended:urn:www.peppol.eu:bis:peppol30a:ver1.0:extended:urn:www.difi.no:ehf:pakkseddel:ver1.0::2.1":
    "EHF Despatch Advise 1.0 (Profile 30) (Despatch Advice)",
  "urn:oasis:names:specification:ubl:schema:xsd:Reminder-2::Reminder##urn:www.cenbii.eu:transaction:biicoretrdm017:ver1.0:#urn:www.cenbii.eu:profile:biixy:ver1.0#urn:www.difi.no:ehf:purring:ver1::2.0":
    "EHF Reminder 1.1 (Profile XY) (Reminder)",
  "urn:oasis:names:specification:ubl:schema:xsd:Catalogue-2::Catalogue##urn:fdc:peppol.eu:poacc:trns:catalogue:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.2":
    "EHF Catalogue 3.0 (Catalogue)",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:poacc:trns:catalogue_response:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.2":
    "EHF Catalogue 3.0 (Catalogue Response)",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:fdc:peppol.eu:poacc:trns:order_agreement:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.2":
    "EHF Order Agreement 3.0 (Order Agreement)",
  "urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2::DespatchAdvice##urn:fdc:peppol.eu:poacc:trns:despatch_advice:3:extended:urn:fdc:anskaffelser.no:2019:ehf:spec:3.0::2.2":
    "EHF Despatch Advice 3.0 (Despatch Advice)",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0#conformant#urn:fdc:anskaffelser.no:2019:ehf:reminder:3.0::2.2":
    "EHF Reminder 3.0 (Reminder)",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:fdc:anskaffelser.no:2019:ehf:spec:payment-request:3.0::2.2":
    "EHF Payment Request 3.0 (Payment Request)",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0#conformant#urn:fdc:anskaffelser.no:2019:ehf:forward-billing:3.0::2.2":
    "EHF Forward Billing 3.0 (Invoice)",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0#conformant#urn:fdc:anskaffelser.no:2019:ehf:forward-billing:3.0::2.2":
    "EHF Forward Billing 3.0 (Credit Note)",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.1::2.1":
    "XRechnung UBL Invoice V2.1",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.1::2.1":
    "XRechnung UBL CreditNote V2.1",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.1::D16B":
    "XRechnung CII Invoice V2.1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.1#conformant#urn:xoev-de:kosit:extension:xrechnung_2.1::2.1":
    "XRechnung UBL Invoice V2.1 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.1#conformant#urn:xoev-de:kosit:extension:xrechnung_2.1::2.1":
    "XRechnung UBL CreditNote V2.1 Extension",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.1#conformant#urn:xoev-de:kosit:extension:xrechnung_2.1::D16B":
    "XRechnung CII Invoice V2.1 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0#extended#urn:fdc:www.efaktura.gov.pl:ver2.0::2.1":
    "PL Faktura specjalizowana ver. 1.4",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0#extended#urn:fdc:www.efaktura.gov.pl:ver2.0::2.1":
    "PL Faktura korygująca ver. 4.0",
  "urn:oasis:names:specification:ubl:schema:xsd:SelfBilledCreditNote-2::SelfBilledCreditNote##urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0#extended#urn:fdc:www.efaktura.gov.pl:ver2.0::2.1":
    "PL Nota korygująca ver. 1.4",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestRequest-2::ExpressionOfInterestRequest##urn:fdc:peppol.eu:prac:trns:t001:1.1::2.2":
    "Procurement procedure subscription / Subscribe to Procedure V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestResponse-2::ExpressionOfInterestResponse##urn:fdc:peppol.eu:prac:trns:t002:1.1::2.2":
    "Procurement procedure subscription / Subscribe to Procedure Confirmation V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderStatusRequest-2::TenderStatusRequest##urn:fdc:peppol.eu:prac:trns:t003:1.1::2.2":
    "Procurement document access / Tender Status Inquiry V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:CallForTenders-2::CallForTenders##urn:fdc:peppol.eu:prac:trns:t004:1.1::2.2":
    "Procurement document access / Call for Tenders V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:Tender-2::Tender##urn:fdc:peppol.eu:prac:trns:t005:1.1::2.2":
    "Tender Submission / Tender V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderReceipt-2::TenderReceipt##urn:fdc:peppol.eu:prac:trns:t006:1.1::2.2":
    "Tender Submission / Tender Tender Reception Notification V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:Enquiry-2::Enquiry##urn:fdc:peppol.eu:prac:trns:t007:1.1::2.2":
    "Call for Tender Question and Answers / Tendering Questions V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:EnquiryResponse-2::EnquiryResponse##urn:fdc:peppol.eu:prac:trns:t008:1.1::2.2":
    "Call for Tender Question and Answers / Tendering Answers V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:Enquiry-2::Enquiry##urn:fdc:peppol.eu:prac:trns:t009:1.1::2.2":
    "Tender Clarification / Tender Clarification Request V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:EnquiryResponse-2::EnquiryResponse##urn:fdc:peppol.eu:prac:trns:t010:1.1::2.2":
    "Tender Clarification / Tender Clarification V1.1",
  "urn:oasis:names:tc:ebxml-regrep:xsd:query:4.0::QueryRequest##urn:fdc:peppol.eu:prac:trns:t011:1.1::4.0":
    "Search Notices / Search Notice Request V1.1",
  "urn:oasis:names:tc:ebxml-regrep:xsd:query:4.0::QueryResponse##urn:fdc:peppol.eu:prac:trns:t012:1.1::4.0":
    "Search Notices / Search Notice Response V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderWithdrawal-2::TenderWithdrawal##urn:fdc:peppol.eu:prac:trns:t013:1.1::2.2":
    "Tender Withdrawal / Tender Withdrawal V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderReceipt-2::TenderReceipt##urn:fdc:peppol.eu:prac:trns:t014:1.1::2.2":
    "Tender Withdrawal / Tender Withdrawal Notification V1.1",
  "urn:oasis:names:tc:ebxml-regrep:xsd:lcm:4.0::SubmitObjectsRequest##urn:fdc:peppol.eu:prac:trns:t015:1.1::4.0":
    "Publish Notice / Publish Notice V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:prac:trns:t016:1.1::2.2":
    "Publish Notice / Notice Publication Response V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:AwardedNotification-2::AwardedNotification##urn:fdc:peppol.eu:prac:trns:t017:1.4::2.2":
    "Notify Awarding / Awarding Notification V1.1 (invalid)",
  "urn:oasis:names:specification:ubl:schema:xsd:AwardedNotification-2::AwardedNotification##urn:fdc:peppol.eu:prac:trns:t017:1.1::2.2":
    "Notify Awarding / Awarding Notification V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.2::2.1":
    "XRechnung UBL Invoice V2.2",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.2::2.1":
    "XRechnung UBL CreditNote V2.2",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.2::D16B":
    "XRechnung CII Invoice V2.2",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.2#conformant#urn:xoev-de:kosit:extension:xrechnung_2.2::2.1":
    "XRechnung UBL Invoice V2.2 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.2#conformant#urn:xoev-de:kosit:extension:xrechnung_2.2::2.1":
    "XRechnung UBL CreditNote V2.2 Extension",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.2#conformant#urn:xoev-de:kosit:extension:xrechnung_2.2::D16B":
    "XRechnung CII Invoice V2.2 Extension",
  "urn:fdc:peppol:end-user-reporting:1.0::EndUserReport##urn:fdc:peppol.eu:oo:trns:end-user-report:1::1.0":
    "Peppol End User Report",
  "urn:fdc:peppol:transaction-statistics-reporting:1.0::TransactionStatisticsReport##urn:fdc:peppol.eu:oo:trns:transaction-statistics-reporting:1::1.0":
    "Peppol Transaction Statistics Report",
  "urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2::DespatchAdvice##urn:fdc:peppol.eu:logistics:trns:advanced_despatch_advice:1::2.1":
    "Advanced Despatch Advice",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:logistics:trns:despatch_advice_response:1::2.1":
    "Despatch Advice Response",
  "urn:oasis:names:specification:ubl:schema:xsd:WeightStatement-2::WeightStatement##urn:fdc:peppol.eu:logistics:trns:weight_statement:1::2.3":
    "Weight Statement",
  "urn:oasis:names:specification:ubl:schema:xsd:UtilityStatement-2::UtilityStatement##urn:fdc:www.efaktura.gov.pl:ver2.0:trns:us:ver1.0::2.1":
    "PL Dokument pomocniczy ver. 1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:fdc:nen.nl:nlcius:v1.0#compliant#urn:fdc:setu.nl:invoice:v2.2::2.1":
    "SETU Invoice v2.2",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:fdc:peppol:jp:billing:3.0::2.1":
    "JP PINT invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderChange-2::OrderChange##urn:fdc:peppol.eu:poacc:trns:order_change:3::2.3":
    "Peppol Order Change",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderCancellation-2::OrderCancellation##urn:fdc:peppol.eu:poacc:trns:order_cancellation:3::2.3":
    "Peppol Order Cancellation",
  "urn:oasis:names:specification:ubl:schema:xsd:OrderResponse-2::OrderResponse##urn:fdc:peppol.eu:poacc:trns:order_response_advanced:3::2.3":
    "Peppol Order Response Advanced",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.3::2.1":
    "XRechnung UBL Invoice V2.3",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.3::2.1":
    "XRechnung UBL CreditNote V2.3",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.3::D16B":
    "XRechnung CII Invoice V2.3",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.3#conformant#urn:xoev-de:kosit:extension:xrechnung_2.3::2.1":
    "XRechnung UBL Invoice V2.3 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.3#conformant#urn:xoev-de:kosit:extension:xrechnung_2.3::2.1":
    "XRechnung UBL CreditNote V2.3 Extension",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xoev-de:kosit:standard:xrechnung_2.3#conformant#urn:xoev-de:kosit:extension:xrechnung_2.3::D16B":
    "XRechnung CII Invoice V2.3 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:selfbilling-1@jp-1::2.1":
    "JP BIS Self-Billing Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestRequest-2::ExpressionOfInterestRequest##urn:fdc:peppol.eu:prac:trns:t001:1.2::2.2":
    "Procurement procedure subscription / Subscribe to Procedure V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestResponse-2::ExpressionOfInterestResponse##urn:fdc:peppol.eu:prac:trns:t002:1.2::2.2":
    "Procurement procedure subscription / Subscribe to Procedure Confirmation V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestRequest-2::ExpressionOfInterestRequest##urn:fdc:peppol.eu:prac:trns:t021:1.2::2.2":
    "Procurement procedure subscription / Unsubscribe from procedure V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:ExpressionOfInterestResponse-2::ExpressionOfInterestResponse##urn:fdc:peppol.eu:prac:trns:t022:1.2::2.2":
    "Procurement procedure subscription / Unsubscribe from procedure confirmation V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderStatusRequest-2::TenderStatusRequest##urn:fdc:peppol.eu:prac:trns:t003:1.2::2.2":
    "Procurement document access / Tender Status Inquiry V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:CallForTenders-2::CallForTenders##urn:fdc:peppol.eu:prac:trns:t004:1.2::2.2":
    "Procurement document access / Call for Tenders V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:Tender-2::Tender##urn:fdc:peppol.eu:prac:trns:t005:1.2::2.2":
    "Tender Submission / Tender V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderReceipt-2::TenderReceipt##urn:fdc:peppol.eu:prac:trns:t006:1.2::2.2":
    "Tender Submission / Tender Tender Reception Notification V1.2",
  "urn:oasis:names:tc:ebxml-regrep:xsd:query:4.0::QueryRequest##urn:fdc:peppol.eu:prac:trns:t011:1.2::4.0":
    "Search Notices / Search Notice Request V1.2",
  "urn:oasis:names:tc:ebxml-regrep:xsd:query:4.0::QueryResponse##urn:fdc:peppol.eu:prac:trns:t012:1.2::4.0":
    "Search Notices / Search Notice Response V1.2",
  "urn:oasis:names:tc:ebxml-regrep:xsd:lcm:4.0::SubmitObjectsRequest##urn:fdc:peppol.eu:prac:trns:t015:1.2::4.0":
    "Publish Notice / Publish Notice V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:prac:trns:t016:1.2::2.2":
    "Publish Notice / Notice Publication Response V1.2",
  "urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2::ApplicationResponse##urn:fdc:peppol.eu:prac:trns:t018:1.1::2.2":
    "Tendering Message Response / Tendering Message Response V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:Qualification-2::Qualification##urn:fdc:peppol.eu:prac:trns:t019:1.1::2.2":
    "Qualification / Qualification V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:TenderReceipt-2::TenderReceipt##urn:fdc:peppol.eu:prac:trns:t020:1.1::2.2":
    "Qualification / Qualification Reception Confirmation V1.1",
  "urn:oasis:names:specification:ubl:schema:xsd:TransportExecutionPlanRequest-2::TransportExecutionPlanRequest##urn:fdc:peppol.eu:logistics:trns:transport_execution_plan_request:1::2.3":
    "Peppol Transport Execution Plan Request",
  "urn:oasis:names:specification:ubl:schema:xsd:TransportExecutionPlan-2::TransportExecutionPlan##urn:fdc:peppol.eu:logistics:trns:transport_execution_plan:1::2.3":
    "Peppol Transport Execution Plan",
  "urn:oasis:names:specification:ubl:schema:xsd:Waybill-2::Waybill##urn:fdc:peppol.eu:logistics:trns:waybill:1::2.3":
    "Peppol Waybill",
  "urn:oasis:names:specification:ubl:schema:xsd:TransportationStatusRequest-2::TransportationStatusRequest##urn:fdc:peppol.eu:logistics:trns:transportation_status_request:1::2.3":
    "Peppol Transportation Status Request",
  "urn:oasis:names:specification:ubl:schema:xsd:TransportationStatus-2::TransportationStatus##urn:fdc:peppol.eu:logistics:trns:transportation_status:1::2.3":
    "Peppol Transportation Status",
  "urn:oasis:names:specification:ubl:schema:xsd:ReceiptAdvice-2::ReceiptAdvice##urn:fdc:peppol.eu:logistics:trns:receipt_advice:1::2.3":
    "Peppol Receipt Advice",
  "http://ns.hr-xml.org/2007-04-15::TimeCard##hr-xml@nl-1.4::2.5":
    "SETU HR-XML Timecard v1.4.1",
  "urn:fdc:peppol:end-user-statistics-report:1.1::EndUserStatisticsReport##urn:fdc:peppol.eu:edec:trns:end-user-statistics-report:1.1::1.1":
    "Peppol End User Statistics Report v1.1",
  "urn:fdc:peppol:transaction-statistics-report:1.0::TransactionStatisticsReport##urn:fdc:peppol.eu:edec:trns:transaction-statistics-reporting:1.0::1.0":
    "Peppol Transaction Statistics Report v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1::2.1":
    "Peppol PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:billing-1::2.1":
    "Peppol PINT CreditNote v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1@jp-1::2.1":
    "JP PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1@jp-1::2.1":
    "JP PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:selfbilling-1@jp-1::2.1":
    "JP BIS Self-Billing Invoice",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:nontaxinvoice-1@jp-1::2.1":
    "JP BIS Invoice for Non-tax Registered Businesses",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:nontaxinvoice-1@jp-1::2.1":
    "JP BIS Invoice for Non-tax Registered Businesses",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1@aunz-1::2.1":
    "A-NZ PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:billing-1@aunz-1::2.1":
    "A-NZ PINT Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:selfbilling-1@aunz-1::2.1":
    "A-NZ PINT Self-Billing Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:selfbilling-1@aunz-1::2.1":
    "A-NZ PINT Self-Billing Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1@aunz-1::2.1":
    "A-NZ PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:billing-1@aunz-1::2.1":
    "A-NZ PINT Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:selfbilling-1@aunz-1::2.1":
    "A-NZ PINT Self-Billing Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:selfbilling-1@aunz-1::2.1":
    "A-NZ PINT Self-Billing Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0::2.1":
    "XRechnung UBL Invoice V3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0::2.1":
    "XRechnung UBL CreditNote V3.0",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0::D16B":
    "XRechnung CII Invoice V3.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0#conformant#urn:xeinkauf.de:kosit:extension:xrechnung_3.0::2.1":
    "XRechnung UBL Invoice V3.0 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0#conformant#urn:xeinkauf.de:kosit:extension:xrechnung_3.0::2.1":
    "XRechnung UBL CreditNote V3.0 Extension",
  "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100::CrossIndustryInvoice##urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0#conformant#urn:xeinkauf.de:kosit:extension:xrechnung_3.0::D16B":
    "XRechnung CII Invoice V3.0 Extension",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1@sg-1::2.1":
    "SG PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:billing-1@sg-1::2.1":
    "SG PINT Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1@my-1::2.1":
    "MY PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:billing-1@my-1::2.1":
    "MY PINT Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:selfbilling-1@my-1::2.1":
    "MY PINT Self-Billing Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:selfbilling-1@my-1::2.1":
    "MY PINT Self-Billing Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:billing-1@my-1::2.1":
    "MY PINT Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:billing-1@my-1::2.1":
    "MY PINT Credit Note v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2::Invoice##urn:peppol:pint:selfbilling-1@my-1::2.1":
    "MY PINT Self-Billing Invoice v1.0",
  "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2::CreditNote##urn:peppol:pint:selfbilling-1@my-1::2.1":
    "MY PINT Self-Billing Credit Note v1.0",
  "http://ns.hr-xml.org/2007-04-15::StaffingOrder##hr-xml@nl-1.4::2.5":
    "SETU HR-XML StaffingOrder v1.4",
  "http://ns.hr-xml.org/2007-04-15::StaffingOrder##hr-xml:status@nl-1.4::2.5":
    "SETU HR-XML StaffingOrder Status v1.4",
  "http://ns.hr-xml.org/2007-04-15::HumanResource##hr-xml@nl-1.4::2.5":
    "SETU HR-XML HumanResource v1.4",
  "http://ns.hr-xml.org/2007-04-15::HumanResource##hr-xml:status@nl-1.4::2.5":
    "SETU HR-XML HumanResource Status v1.4",
  "http://ns.hr-xml.org/2007-04-15::Assignment##hr-xml@nl-1.4.1::2.5":
    "SETU HR-XML Assignment v1.4.1",
  "http://ns.hr-xml.org/2007-04-15::Assignment##hr-xml:status@nl-1.4::2.5":
    "SETU HR-XML Assignment Status v1.4",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:digital::1.0":
    "INNBYGGERPOST_DPI_DIGITAL_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:utskrift::1.0":
    "INNBYGGERPOST_DPI_UTSKRIFT_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:flyttet::1.0":
    "INNBYGGERPOST_DPI_FLYTTET_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:leveringskvittering::1.0":
    "INNBYGGERPOST_DPI_LEVERINGSKVITTERING_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:feil::1.0":
    "INNBYGGERPOST_DPI_FEILKVITTERING_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:aapningskvittering::1.0":
    "INNBYGGERPOST_DPI_AAPNINGSKVITTERING_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:mottakskvittering::1.0":
    "INNBYGGERPOST_DPI_MOTTAKSKVITTERING_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:varslingfeiletkvittering::1.0":
    "INNBYGGERPOST_DPI_VARSLINGFEILETKVITTERING_1_0",
  "urn:fdc:digdir.no:2020:innbyggerpost:xsd::innbyggerpost##urn:fdc:digdir.no:2020:innbyggerpost:schema:returpostkvittering::1.0":
    "INNBYGGERPOST_DPI_RETURPOSTKVITTERING_1_0",
};
/* end of peppol code list document types */

export default {
  name: "Discover",
  components: {
    MyAutocomplete
  },
  methods: {
    ready() {
      this.load_ISO6523_entries();
    },
    doSMPLookup(event) {
      if (this.iso6523_identifier == "") {
        return;
      }
      if (this.iso6523_identifier.indexOf(":") == -1) {
        this.haveMessage = true;
        this.message = this.$t("lookup_malformed_identifier");
        return;
      }
      // make query parameters match query
      let cur_params = {
        identifier: this.$route.query.identifier
      }
      if (this.$route.query.useSMK) {
        cur_params.useSMK = this.$route.query.useSMK
      }
      let new_params = {
        identifier: this.iso6523_identifier
      }
      if (!this.useSML) {
        new_params.useSMK = 1;
      } else {
        //new_params.useSMK = null;
      }
      if (cur_params.identifier != new_params.identifier ||
          cur_params.useSMK != new_params.useSMK) {
        //alert("NAVCHANGE: old: " + JSON.stringify(cur_params, null,2) + " new: " + JSON.stringify(new_params, null, 2))
        this.$router.push({query:new_params});
      }

      var url =
        process.env.VUE_APP_ROOT_API +
        "/lookup/" +
        (this.useSML ? "sml" : "smk") +
        "/" +
        this.iso6523_identifier;
      this.getSMPData(url);
    },
    getSMPData: function(url) {
      this.isLoading = true;
      this.haveResults = false;
      this.haveMessage = false;
      var self = this;
      axios
        .get(url)
        .then(response => {
          // do error handling here or in handle?
          self.handleSMPData(self, response.data);
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response && error.response.status == 500) {
            if (error.response.data && error.response.data.detail) {
              //alert("Error in SMP lookup: " + error.response.data.detail);
              this.haveMessage = true;
              this.message =
                "Server message: Error in SMP lookup: " +
                error.response.data.detail;
            } else {
              this.haveMessage = true;
              this.message =
                "Server message: Error 500 from lookup service, the SMP may have returned a malformed result";
            }
          } else {
            this.haveMessage = true;
            this.message =
              "Server message: Unable to perform lookup for '" +
              this.iso6523_identifier +
              "'";
          }
        });
    },
    handleSMPData: function(self, data) {
      var newItems = [];
      var ps = data.processes;
      for (var property in ps) {
        if (Object.prototype.hasOwnProperty.call(ps, property)) {
          var eps = ps[property];
          for (var i = 0; i < eps.length; i++) {
            var ep = eps[i];
            ep.short_customization_id = ep.customization_id;
            
            let full_doctype_identifier = ep.root_element + "::" + ep.local_name + "##" + ep.customization_id + "::" + ep.ubl_version
            if (KNOWN_CUSTOMIZATION_IDS[full_doctype_identifier]) {
              ep.short_customization_id =
                KNOWN_CUSTOMIZATION_IDS[full_doctype_identifier];
            }
            ep.process = property;
            newItems.push(ep);
          }
        }
      }
      if (newItems.length === 0) {
        self.isLoading = false;
        self.haveResults = false;
        self.haveMessage = true;
        self.message = self.$t("nothing_found", [self.iso6523_identifier]);
      } else {
        self.isLoading = false;
        self.haveResults = true;
        self.haveMessage = false;
      }
      self.items = newItems;
    },
    load_ISO6523_entries: function() {
      var url =
        process.env.VUE_APP_ROOT_API + "/lookup/data/iso6523_identifiers";
      var self = this;
      axios
        .get(url)
        .then(function(response) {
          for (var i = 0; i < response.data.length; i++) {
            var el = response.data[i];
            el.displayname = el.mnemonic + " (" + el.code + ")";
          }
          self.iso6523_items = response.data;
        })
        .catch(function(error) {
          alert(
            "Unable to contact server for ISO6523 scheme list, searching is unlikely to function at this time, please try again later"
          );
        });
    },
    iso6523SelectionChanged: function(value) {
      if (value) {
        var els = this.iso6523_identifier.split(":");
        if (els.length > 1) {
          this.iso6523_identifier = value + ":" + els[1];
        } else {
          this.iso6523_identifier = value + ":" + els[0];
        }
      }
    },
    iso6523SelectionChangedNew: function(name, description, value) {
      if (value) {
        var els = this.iso6523_identifier.split(":");
        if (els.length > 1) {
          this.iso6523_identifier = value + ":" + els[1];
        } else {
          this.iso6523_identifier = value + ":" + els[0];
        }
      }
    },
    addIdata: function(name, value) {
      this.selectedTableItemData.push({ name: name, value: value });
    },
    tableItemClicked: function(item) {
      this.selectedTableItemData = [];
      if (item.short_customization_id) {
        this.addIdata("Document Type", item.short_customization_id);
      }
      this.addIdata("Customization ID", item.customization_id);
      this.addIdata("Process (Profile ID)", item.process);
      this.addIdata("UBL version", item.ubl_version);
      this.addIdata("Endpoint URL", item.endpoint);
      this.addIdata("Transport type", item.transport);
      this.addIdata("Certificate Issuer", item.certificate_issuer);
      if (item.certificate_valid) {
        this.addIdata("Certificate valid", "Yes");
      } else {
        this.addIdata("Certificate valid", "No");
      }
      if (item.certificate_error) {
        this.addIdata("Certificate Error", item.certificate_error);
      }

      // split up other certificate information
      let certData = item.certificate_subject.split(",");
      for (let i = 0; i < certData.length; i++) {
        let part = certData[i].trim();
        if (part.startsWith("C=")) {
          this.addIdata("Country", part.substring(2));
        } else if (part.startsWith("O=")) {
          this.addIdata("Organization", part.substring(2));
        } else if (part.startsWith("CN=")) {
          this.addIdata("Endpoint Identifier", part.substring(3));
        }
      }

      this.showDialog = true;
    },
    setLocale: function(val) {
      if (this.$i18n) {
        this.$i18n.locale = val;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      haveResults: false,
      haveMessage: false,
      message: "",
      iso6523_scheme_selected: "NL:KVK",
      iso6523_identifier: "0106:",
      identifierErrors: ["jhg"],
      useSML: true,
      search: "",
      selectedTableItemData: [],
      showDialog: false,
      // headers: [
      //  { text: 'Customization ID', value: 'customization_id' },
      //  { text: 'URL', value: 'url' },
      //  { text: this.$t('document_type'), value: 'document_type' },
      //  { text: 'Process', value: 'process' }
      // ],
      items: [],
      // just the default scheme for now
      iso6523_items: []
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("document_type"), value: "customization_id", sortable: false },
        { text: this.$t("process"), value: "process", sortable: false },
        { text: this.$t("URL"), value: "url", sortable: false },
        { text: this.$t("transport"), value: "transport", sortable: false }
      ];
    },
    selectedItemTableHeaders() {
      return [
        { text: this.$t("name"), value: "name" },
        { text: this.$t("value"), value: "value" }
      ];
    },
    getProductionTestText() {
      // `SML/SMK: ${useSML ? 'SML' : 'SMK'}`
      if (this.useSML) {
        return (
          this.$t("production_or_test_network") +
          ": " +
          this.$t("production_or_test_network_production")
        );
      } else {
        return (
          this.$t("production_or_test_network") +
          ": " +
          this.$t("production_or_test_network_test")
        );
      }
    },
    getHelpURL() {
      if (this.$store.getters.getLanguage === "nl") {
        return "/documentation/nl/components/discover/";
      } else {
        return "/documentation/components/discover/";
      }
    }
  },
  mounted() {
    if (this.$route.query.useSMK == 1) {
        this.useSML = false;
    }
    if (this.$route.query.identifier) {
        this.iso6523_identifier = this.$route.query.identifier;
        this.doSMPLookup();
    }
  },
  beforeMount() {
    this.load_ISO6523_entries();
    this.setLocale(this.$store.getters.getLanguage);
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          this.setLocale(mutation.payload);
          break;
      }
    });
  },
  watch:{
    $route (to, from){
      let prevIdentifier = this.iso6523_identifier;
      let prevUseSML = this.useSML;
      if (this.$route.query.useSMK == 1) {
        this.useSML = false;
      } else {
          this.useSML = true;
      }
      if (this.$route.query.identifier) {
          this.iso6523_identifier = this.$route.query.identifier;
      }
      if (prevIdentifier != this.iso6523_identifier ||
          prevUseSML != this.useSML) {
          this.doSMPLookup();
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3 {
  margin: 0px 10 10;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
