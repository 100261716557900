<template>
  <div>
    <div>
      <v-card>
        <v-card-title class="cardheader">
          <h1 class="text-h5">{{ $t("test_access_points") }}</h1>
        </v-card-title>
        <v-card-actions>
          <v-btn class="primary" @click="createNewTestAccessPointClicked()"
            ><v-icon left>add_circle_outline</v-icon
            >{{ $t("add_access_point") }}</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <v-data-table
            :headers="accesspointHeaders"
            :items="testAccessPointItems"
            hideDefaultFooter
            @click:row="goToAccessPoint"
          >
            <template v-slot:item.name="{ item }">
              <v-btn icon class="secondary d-sr-only-focusable" :title="$t('edit')">
                <v-icon>edit</v-icon>
              </v-btn>
              <button tabindex="-1">{{ item.name }}</button>
            </template>
            <template v-slot:item.url="{ item }">
              <div style="word-break: break-all;">
                {{ item.url }}
              </div>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-btn
                fab
                small
                class="secondary"
                @click.stop="testAccessPointDeleteClicked(item)"
                :title="$t('delete')"
                ><v-icon>delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog max-width="800" v-model="showTestAccessPointDialog" ref="showTestAccessPointDialog" aria-labelledby="addAccessPoint">
      <AccessPointEdit
        id="addAccessPoint"
        :title="$t('add_access_point')"
        v-bind:callbackDone="editDone"
        ref="accesspointEdit"
      />
    </v-dialog>

    <v-dialog v-model="showTestAccessPointDeleteDialog" ref="showTestAccessPointDeleteDialog" width="50%" aria-labelleby="deleteAccessPointConfirmButton">
      <v-card>
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn
              icon
              color="primary"
              @click.native="showTestAccessPointDeleteDialog = false"
              :title="$t('close')"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>

        <v-card-title>
          {{ $t("delete_confirmation") }}
        </v-card-title>
        <v-card-text>
          <v-row>
          <v-btn class="primary mr-2" id="deleteAccessPointConfirmButton" @click="deleteCurrentTestAccessPoint()">{{
            $t("delete")
          }}</v-btn>
          <v-btn
            class="primary"
            @click="showTestAccessPointDeleteDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <BackendConnector ref="backend"></BackendConnector>
  </div>
</template>

<script>
// @ is an alias to /src
import AccessPointEdit from "@/components/AccessPointEdit.vue";

export default {
  name: "test_access_points",
  components: {
    AccessPointEdit,
  },
  data() {
    return {
      activeTab: "participants",
      testAccessPointItems: [],
      showTestAccessPointDialog: false,
      testAccessPointFormUrlRules: [v => !!v || this.$t("url_required")],
      testAccessPointFormUrlErrors: [],
      testAccessPointFormNameRules: [v => !!v || this.$t("name_required")],
      testAccessPointFormNameErrors: [],
      testAccessPointFormDescriptionRules: [
        v => !!v || this.$t("description_required")
      ],
      testAccessPointFormDescriptionErrors: [],
      valid: true,
      showTestAccessPointDeleteDialog: false,
      currentTestAccessPoint: {
        name: "",
        id: -1,
        description: "",
        url: "",
        transport_type: "AS4"
      },
      savedTestAccessPoint: {}
    };
  },
  methods: {
    loadData: function() {
      var url = process.env.VUE_APP_ROOT_API + "/accesspoints/list/";
      var self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        // alert(JSON.stringify(response.data))
        self.testAccessPointItems = response.data;
        // }).catch(function (error) {
        // handled by BackendConnector
      });
    },
    unloadData: function() {
      this.testAccessPointItems = [];
    },
    createNewTestAccessPointClicked: function(item) {
      this.currentTestAccessPoint = {
        name: "",
        id: -1,
        description: "",
        url: "",
        transport_type: "AS4"
      };
      this.savedTestAccessPoint = JSON.parse(
        JSON.stringify(this.currentTestAccessPoint)
      );
      this.showTestAccessPointDialog = true;
    },
    testAccessPointEditClicked: function(item) {
      // alert(JSON.stringify(item))
      this.currentTestAccessPoint = JSON.parse(JSON.stringify(item));
      this.savedTestAccessPoint = JSON.parse(
        JSON.stringify(this.currentTestAccessPoint)
      );
      this.showTestAccessPointDialog = true;
      // alert(this.currentTestAccessPointName)
    },
    testAccessPointDeleteClicked: function(item) {
      this.currentTestAccessPoint = JSON.parse(JSON.stringify(item));
      this.savedTestAccessPoint = JSON.parse(
        JSON.stringify(this.currentTestAccessPoint)
      );
      this.showTestAccessPointDeleteDialog = true;
      return false;
    },
    resetCurrentTestAccessPoint: function() {
      this.currentTestAccessPoint = JSON.parse(
        JSON.stringify(this.savedTestAccessPoint)
      );
    },
    saveCurrentTestAccessPoint: function() {
      var url = process.env.VUE_APP_ROOT_API + "/accesspoints/list/";
      var self = this;
      // Hmm, can we do this in one go?
      // TODO: REMOVE FIRST PART, move it to Details (make edit option there)
      if (this.currentTestAccessPoint.id > -1) {
        url += this.currentTestAccessPoint.id + "/";
        this.$refs.backend
          .put_auth(url, this.currentTestAccessPoint)
          .then(function(response) {
            self.loadData();
            self.showTestAccessPointDialog = false;
          })
          .catch(function(error) {
            // alert('got error: ' + JSON.stringify(error))
            if (error.response.data) {
              // TODO: if (error.response.data.description) {
              if (error.response.data.url) {
                self.testAccessPointFormUrlErrors = error.response.data.url;
                self.valid = false;
                self.$refs.form.validate();
              }
            } else {
              alert(JSON.stringify(error.response));
            }
          });
      } else {
        this.$refs.backend
          .post_auth(url, this.currentTestAccessPoint)
          .then(function(response) {
            self.loadData();
            self.showTestAccessPointDialog = false;
            if (response.data && response.data.id) {
              self.$router.push(
                "/test_access_points/" + response.data.id + "?start=true"
              );
            }
          })
          .catch(function(error) {
            if (error.response && error.response.data) {
              if (error.response.data.url) {
                self.testAccessPointFormUrlErrors = error.response.data.url;
                self.valid = false;
              }
              if (error.response.data.name) {
                self.testAccessPointNameErrors = error.response.data.name;
                self.valid = false;
              }
              if (error.response.data.description) {
                self.testAccessPointDescriptionErrors =
                  error.response.data.description;
                self.valid = false;
              }
            }

            //alert('error! ' + JSON.stringify(error))
            // TODO: handle the same as PUT above
          });
      }
    },
    deleteCurrentTestAccessPoint: function(item) {
      if (this.currentTestAccessPoint.id > -1) {
        var url =
          process.env.VUE_APP_ROOT_API +
          "/accesspoints/list/" +
          this.currentTestAccessPoint.id +
          "/";
        var self = this;
        this.$refs.backend
          .delete_auth(url)
          .then(function(response) {
            self.loadData();
            self.showTestAccessPointDeleteDialog = false;
          })
          .catch(function(error) {
            //alert('Error! ' + JSON.stringify(error))
            self.loadData();
            self.showTestAccessPointDeleteDialog = false;
          });
      }
    },
    reloadButtonClicked: function() {
      this.loadData();
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    },
    goToAccessPoint(item) {
      this.$router.push({
        name: "test_access_point_details",
        params: { ap_id: item.id }
      });
    },
    editDone: function() {
      this.showTestAccessPointDialog = false;
      this.loadData();
    }
  },
  computed: {
    accesspointHeaders() { return [
        { text: this.$t("name"), value: "name", sortable: false },
        { text: this.$t("description"), value: "description", sortable: false },
        { text: this.$t("url"), value: "url", sortable: false },
        { text: this.$t("certificate_type"), value: "certificate_type", sortable: false },
        { text: this.$t("delete"), value: "delete", sortable: false }
    ]},
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "updateToken":
          this.unloadData();
          try {
            // TODO: maybe not reload on login but redirect?
            if (this.$refs && this.$refs.backend) {
              this.$nextTick(this.loadData);
            }
          } catch (berr) {
            alert(
              "[XX] error in loadData for TestAccessPointDetails.vue (sub updateToken): " +
                berr
            );
          }
          break;
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
        case "LOGOUT":
          this.unloadData();
          break;
      }
    });
    try {
      this.loadData();
    } catch (berr) {
      console.log(berr);
    }
  },
  filters: {
    bool2successOrNA: function(value) {
      if (value) {
        return "success";
      } else {
        return "not_applicable";
      }
    },
    extendedTestsApproved: function(value) {
      if (value.onboarder_extended_requested) {
        if (value.onboarder_extended_granted) {
          return "success";
        } else {
          return "waiting";
        }
      } else {
        return "not_applicable";
      }
    }
  },
  watch: {
    isLoggedIn: function() {
      alert("logged in!");
    },
    // copy the aria-labelledby attribute after the dialog opens
    showTestAccessPointDialog: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          requestAnimationFrame(() => {
            this.$refs.accesspointEdit.setFocus();
          });
          this.$nextTick(() => {
            const dialog = this.$refs?.showTestAccessPointDialog;
            const content = dialog?.$refs?.content;
            if (content) {
              // Copy aria-labelledby from v-dialog to new rendered element.
              content.setAttribute('aria-labelledby', dialog.$attrs['aria-labelledby']);
            }
          });
        }
      }
    },
    // copy the aria-labelledby attribute after the dialog opens
    showTestAccessPointDeleteDialog: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.$nextTick(() => {
            const dialog = this.$refs?.showTestAccessPointDeleteDialog;
            const content = dialog?.$refs?.content;
            if (content) {
              // Copy aria-labelledby from v-dialog to new rendered element.
              content.setAttribute('aria-labelledby', dialog.$attrs['aria-labelledby']);
            }
          });
        }
      }
    }
  }
};
</script>

<!-- primary color for all headers defined here -->
<style lang="stylus">
.cardheader
  color #01689b
</style>
