<template>
  <div>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-card class="elevation-12" color="primary" dark>
              <v-card-title mb-0 pb-0 dark>
                <h1>{{ $t("about") }} {{ $t("title") }}</h1>
              </v-card-title>
              <v-card-text>
                <div class="home">
                  <center>
                    <div
                      style="background-color: white; border-radius: 10px;" class="mb-6"
                    >
                      <img class="mx-auto" src="../assets/npa_logo.png" alt="Logo Nederlandse Peppolautoriteit" />
                    </div>
                  </center>
                  <v-col class="text-center" style="color: #ffffff;">
                  <h3>{{ $t("title") }}</h3>
                  <p>{{ $t("title_version") }}</p>
                  <p>{{ $t('developed_by') }}</p>
                  <p><a style="color: #ffffff;" href="https://ionite.nl">www.ionite.nl</a></p>
                  <p>{{ $t('in_association') }}</p>
                  <p><a style="color: #ffffff;" href="https://headon.nl">www.headon.nl</a></p>
                  <img class="mx-auto" max-width="140" src="../assets/ionite_logo_name_white.png" alt="Logo Ionite"/>
                  <img class="mx-auto" max-width="140" style="background_color: black;" src="../assets/headon_logo_150x40_tp.png" alt="Logo HeadON" />
                  </v-col>
                </div>
                <br />
                <center>
                  <div
                    style="background-color: white; border-radius: 10px;"
                  >
                    <img
                        class="mx-auto"
                        width="340"
                        style="background_color: black;"
                        src="../assets/CEF_co_financed.png"
                        alt="Co-financed by the Connecting Europe Facility of the European Union"
                    />
                    <a
                      href="https://ec.europa.eu/inea/en/connecting-europe-facility"
                      >ec.europa.eu</a>
                  </div>
                </center>
              </v-card-text>
            </v-card>
        </v-layout>
      </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {
  },
  mounted() {
    if (this.$i18n) {
      this.$i18n.locale = this.$store.getters.getLanguage;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          if (this.$i18n) {
            this.$i18n.locale = mutation.payload;
          }
          break;
      }
    });
  }
};
</script>
