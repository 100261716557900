<template>
  <v-dialog
    v-model="showActionDialog"
    max-width="1100px"
    style="height:100%"
    fill-height
  >
    <v-card class="elevation-20" style="height:100%" fill-height>
      <div v-if="testTasksReceived" style="height:100%">
        <v-data-table :items="testTasks" hideDefaultHeader hideDefaultFooter>
          <template slot="item" slot-scope="props">
            <tr @click="tableItemClicked(props.item)">
              <td class="text-left" nowrap="nowrap">
                {{ props.item.description }}
              </td>
              <td class="text-right" nowrap="nowrap">
                <v-progress-circular
                  v-if="props.item.running"
                  indeterminate
                  color="primary"
                  size="20"
                ></v-progress-circular>
                <v-icon
                  class="text-right"
                  v-else-if="props.item.success"
                  color="success"
                  >check_circle</v-icon
                >
                <v-icon
                  class="text-right"
                  v-else-if="props.item.error"
                  color="error"
                  >error</v-icon
                >
                <v-icon
                  class="text-right"
                  v-else-if="props.item.not_applicable"
                  >not_interested</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card-text
          class="text-left"
          style="height:100%;min-height:400px;max-height=400px;"
          fill-height
        >
          <pre
            class="scroll-container"
            style="height:100%;min-height:400px;max-height=400px;">{{ convertedTextMessage }}<v-spacer></v-spacer></pre>
        </v-card-text>
      </div>
      <div v-else>
        <v-progress-circular
          indeterminate
          color="primary"
          size="40"
        ></v-progress-circular>
      </div>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click.native="testTaskDone()"
          >Done</v-btn
        >
      </v-card-actions>
    </v-card>
    <StandardErrorMessage ref="errMsg"></StandardErrorMessage>
    <StandardUserInput ref="userInput"></StandardUserInput>
  </v-dialog>
</template>

<script>
import StandardErrorMessage from "@/components/StandardErrorMessage.vue";
import StandardUserInput from "@/components/StandardUserInput.vue";

export default {
  name: "TestTaskViewer",
  components: {
    StandardErrorMessage,
    StandardUserInput
  },
  data() {
    return {
      isLoading: false,
      testTasks: [],
      testTasksReceived: false,
      message: "",
      logData: "",
      additionalArguments: {},
      showActionDialog: false,
      promise: null
    };
  },
  props: ["url"],
  methods: {
    setLocale: function(val) {
      if (this.$i18n) {
        this.$i18n.locale = val;
      }
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    getJWT() {
      return this.$store.getters.getJWT;
    },
    run: function(url, args, callbackResult) {
      this.testTasksReceived = false;
      this.message = "";
      this.testTasks = [];
      this.logData = "";
      this.showActionDialog = true;
      this.promise = this.getPromise();
      this.connect(url, args);
      this.callbackResult = callbackResult;
      return this.promise;
    },
    getPromise() {
      let _resolve, _reject;

      let promise = new Promise((resolve, reject) => {
        _reject = reject;
        _resolve = resolve;
      });

      promise.resolve_ex = value => {
        _resolve(value);
      };

      promise.reject_ex = value => {
        _reject(value);
      };

      return promise;
    },
    testTaskDone: function() {
      if (this.promise) {
        this.promise.resolve_ex();
        this.promise = null;
      }
      this.showActionDialog = false;
    },
    handleFinalResult: function(result) {
      if (this.callbackResult) {
        this.callbackResult(result);
      } else {
        alert("got final result but no callback speficied");
      }
    },
    connect: function(url, args) {
      // TODO: this should be set as prop
      let ws = new WebSocket(url);
      let self = this;

      ws.onopen = function(event) {
        let msg = {};
        if (args) {
          msg = args;
        }
        msg["Authorization"] = "JWT " + self.getJWT();

        // Send the msg object as a JSON-formatted string.
        ws.send(JSON.stringify(msg));
      };

      ws.onerror = function(event) {
        console.error(event);
        self.$refs.errMsg.doShowError(
          "Error",
          "Error in websocket connection to backend..." + JSON.stringify(event),
          "en"
        );
        self.testTaskDone();
      };

      ws.onmessage = function(event) {
        // console.log(event)

        if (event.data) {
          let data = JSON.parse(event.data);
          if (data.error) {
            // TODO: emit the error and remove the component here?
            let errorTitle = "Error";
            self.$refs.errMsg.doShowError(errorTitle, data.error, "en");
            // console.log(self.$parent)
            // self.$emit("error")
            self.testTaskDone();
          } else if (data.type) {
            switch (data.type) {
              case "log":
                // alert("got log part: " + data.log)
                self.logData = self.logData + "\n" + data.log;
                break;
              case "test_list":
                self.testTasks = [];
                for (let i = 0; i < data.test_list.length; i++) {
                  let testTask = {
                    description: data.test_list[i][0],
                    name: data.test_list[i][1],
                    running: false,
                    success: false,
                    error: false
                  };
                  self.testTasks.push(testTask);
                  self.testTasksReceived = true;
                }
                break;
              case "test_start":
                // find the corresponding test and set a runner
                for (let i = 0; i < self.testTasks.length; i++) {
                  let task = self.testTasks[i];
                  if (task.name === data.test_name) {
                    task.running = true;
                  }
                }
                break;
              case "test_result":
                // fund the corresponding test and set the given result
                for (let i = 0; i < self.testTasks.length; i++) {
                  let task = self.testTasks[i];
                  if (task.name === data.test_name) {
                    task.running = false;
                    if (data.test_result) {
                      task.success = true;
                    } else {
                      task.error = true;
                    }
                  }
                }
                break;
              case "test_not_applicable":
                for (let i = 0; i < self.testTasks.length; i++) {
                  let task = self.testTasks[i];
                  if (task.name === data.test_name) {
                    task.running = false;
                    task.not_applicable = true;
                  }
                }
                break;
              case "user_input":
                {
                  let callbackSubmit = function(value) {
                    // TODO
                    ws.send(JSON.stringify({ user_input: value }));
                  };
                  let callbackAbort = function() {
                    ws.send(JSON.stringify({ command: "abort" }));
                    self.testTaskDone();
                  };
                  let callbackSave = function() {
                    ws.send(JSON.stringify({ command: "save" }));
                    self.testTaskDone();
                  };
                  self.$refs.userInput.doShowUserInput(
                    "Input",
                    data.message,
                    "",
                    [],
                    callbackSubmit,
                    callbackAbort,
                    callbackSave
                  );
                }
                break;
              case "final_result":
                self.handleFinalResult(data.result);
                break;
              default:
                alert("unknown event data type: " + data.type);
            }
          }
        }
      };
    }
  },
  computed: {
    convertedTextMessage: function() {
      let conv = this.logData;
      // where is this converted to use &lt; etc.?
      conv = conv.replace(/\\r/g, "");
      conv = conv.replace(/\\n/g, "\n");
      conv = conv.replace(/&lt;/g, "<");
      conv = conv.replace(/&gt;/g, ">");
      conv = conv.replace(/&#39;/g, "'");
      return conv;
    }
  },
  mounted() {},
  beforeMount() {
    this.setLocale(this.$store.getters.getLanguage);
    if (this.isLoggedIn()) {
      // this.connect()
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "LANG":
          this.setLocale(mutation.payload);
          break;
        case "updateToken":
          // this.connect()
          break;
      }
    });
  }
};
</script>

<style scoped lang="stylus">
pre
  color #ffffff
  background-color #333333
  font-size 14px
  padding 10px
  height 100%
  width 100%

v-card-text
  color #ffffff
  background-color #333333
  font-size 14px
  padding 10px
  height 100%
  width 100%

.scroll-container
  height 100%
  overflow-y scroll
  backface-visibility hidden

dialog
  height 500px
</style>
