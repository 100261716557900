<template>
  <!-- overview goes here -->
  <div>
    <v-card>
      <v-card-actions>
        <v-row class="ml-2 mt-2">
          <v-btn class="primary mr-2 mb-2" small @click="loadData()"
            ><v-icon left>refresh</v-icon>{{ $t("reload") }}</v-btn
          >
          <v-btn
            v-if="accesspoint.onboarder_extended_requested"
            class="primary mr-2 mb-2"
            small
            @click="revokeApprovalSubmission()"
            ><v-icon left>not_interested</v-icon
            >{{ $t("extended_test_revoke") }}</v-btn
          >
          <!-- TODO: remove completely, just disabled for now<v-btn v-else class="primary" small @click="submitForApproval()"><v-icon left>card_membership</v-icon>{{ $t('extended_test_submit') }}</v-btn>-->
          <v-btn class="primary" small @click="downloadTestReport()"
            ><v-icon left>move_to_inbox</v-icon
            >{{ $t("extended_test_download") }}</v-btn
          >
        </v-row>
        </v-container>
      </v-card-actions>
      <v-card-text style="text-align: left;">
        <div v-if="accesspoint.onboarder_extended_requested">
          <p>{{ $t("extended_test_submitted_description") }}</p>
        </div>
        <div v-else>
          <p>
            {{ $t("extended_test_description1") }}<br />
            {{ $t("extended_test_description2") }}<br />
            {{ $t("extended_test_description3") }}<br />
            {{ $t("extended_test_description4") }}
          </p>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="cardheader">
        <h1 class="text-h5">{{ $t("sender_extended_tests") }}</h1>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="sendTestsHeaders"
          :items="shownSendTests"
          hideDefaultFooter
        >
          <template v-slot:item.passed="{ item }">
            <div v-if="item.challenge_created">
              <div v-if="item.challenge.passed">
                <StatusIcon status="success" />
              </div>
              <div v-else>
                <StatusIcon status="waiting" />
              </div>
            </div>
            <div v-else>
              <StatusIcon status="not_applicable" />
            </div>
          </template>
          <template v-slot:item.code="{ item }">
            <div v-if="item.challenge_created">
              <div v-if="item.challenge.passed">
                <v-btn
                  :disabled="accesspoint.onboarder_extended_requested"
                  class="primary"
                  small
                  @click="deleteSendTestResult(item.challenge)"
                >
                  <v-icon left>delete</v-icon>
                  {{ $t("sender_extended_test_delete_result") }}</v-btn
                >
              </div>
              <div v-else>
                <v-btn
                  :disabled="accesspoint.onboarder_extended_requested"
                  class="primary"
                  small
                  @click="viewSendTestChallenge(item.challenge)"
                >
                  <v-icon left>search</v-icon>
                  {{ $t("sender_extended_test_view_challenge") }}</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-btn
                :disabled="accesspoint.onboarder_extended_requested"
                class="primary"
                small
                @click="createSendTestChallenge(item.identifier)"
              >
                <v-icon left>create</v-icon>
                {{ $t("sender_extended_test_create_challenge") }}</v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card>
        <v-card-title class="cardheader">
          <h3>{{ $t("receiver_extended_tests") }}</h3>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="receiverTestsHeaders"
            :items="shownReceiverTests"
            hideDefaultFooter
          >
            <template v-slot:item.passed="{ item }">
              <div v-if="item.challenge_created">
                <div v-if="item.challenge.passed">
                  <StatusIcon status="success" />
                </div>
                <div v-else>
                  <StatusIcon status="waiting" />
                </div>
              </div>
              <div v-else>
                <StatusIcon status="not_applicable" />
              </div>
            </template>
            <template v-slot:item.document="{ item }">
              <div v-if="item.challenge_created">
                <div v-if="item.challenge.passed">
                  <v-btn
                    :disabled="accesspoint.onboarder_extended_requested"
                    class="primary"
                    small
                    @click="deleteReceiverTestResult(item.challenge)"
                  >
                    <v-icon left>delete</v-icon>
                    {{ $t("sender_extended_test_delete_result") }}</v-btn
                  >
                </div>
                <div v-else>
                  <v-btn
                    :disabled="accesspoint.onboarder_extended_requested"
                    class="primary"
                    small
                    @click="receiverTestChallengeEnterCode(item)"
                  >
                    <v-icon left>search</v-icon>
                    {{ $t("receiver_extended_test_enter_code") }}</v-btn
                  >
                  <v-btn
                    :disabled="accesspoint.onboarder_extended_requested"
                    class="primary"
                    small
                    @click="receiverTestChallengeShowSendDialog(item)"
                  >
                    <v-icon left>search</v-icon>
                    {{
                      $t("receiver_extended_test_send_new_document")
                    }}</v-btn
                  >
                </div>
              </div>
              <div v-else>
                <v-btn
                  :disabled="accesspoint.onboarder_extended_requested"
                  class="primary"
                  small
                  @click="receiverTestChallengeShowSendDialog(item)"
                >
                  <v-icon left>create</v-icon>
                  {{ $t("receiver_extended_test_send_document") }}</v-btn
                >
              </div>
            </template>
<!--
            <template slot="item" slot-scope="props">
              <tr>
              <td class="text-left">{{ props.item.name }}</td>
              <td class="text-left">
              </td>

              <td class="text-left">
              </td>
              </tr>
            </template>
-->
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-dialog
        max-width="60%"
        ref="recipientDialog"
        v-model="showReceiverTestRecipientDialog"
      >
        <v-card>
          <v-card-title>
            <h1 class="text-h5">{{ $t("enter_recipient") }}</h1>
          </v-card-title>
          <v-card-text>
            <RecipientField ref="recipientField"></RecipientField>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="accesspoint.onboarder_extended_requested"
              class="primary"
              @click="receiverTestChallengeSendDocument()"
              >{{ $t("send_test_document") }}</v-btn
            >
            <v-btn
              :disabled="accesspoint.onboarder_extended_requested"
              class="primary"
              @click="receiverTestChallengeCancelSendDialog()"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <BackendConnector ref="backend"></BackendConnector>
      <TestTaskViewer
        v-on:error="testTaskDone()"
        ref="taskViewer"
      ></TestTaskViewer>
      <StandardErrorMessage ref="errMsg"></StandardErrorMessage>
      <StandardUserInput ref="userInput"></StandardUserInput>
      <StandardUserMessage ref="userMsg"></StandardUserMessage>
    </v-card>
  </div>
</template>

<script>
import TestTaskViewer from "@/components/TestTaskViewer.vue";
import StatusIcon from "@/components/StatusIcon.vue";
import StandardErrorMessage from "@/components/StandardErrorMessage.vue";
import StandardUserInput from "@/components/StandardUserInput.vue";
import StandardUserMessage from "@/components/StandardUserMessage.vue";
import RecipientField from "@/components/RecipientField.vue";

export default {
  name: "ExtendedTests",
  components: {
    TestTaskViewer,
    RecipientField,
    StatusIcon,
    StandardErrorMessage,
    StandardUserInput,
    StandardUserMessage
  },
  props: ["accesspoint"],
  data() {
    return {
      onboarder_extended_requested: false,
      tests: { sender_tests: [], receiver_tests: [] },
      document_types: [],
      receiver_challenges: [],
      send_challenges: [],
      showReceiverTestRecipientDialog: false,
      recipientFieldValue: "0106:1234567",
      receiverTestIdentifier: null
    };
  },
  methods: {
    loadData: function() {
      this.loadSenderTests();
      //this.loadDocumentTypes()
      this.loadSendChallenges();
      this.loadReceiverChallenges();
    },
    loadSenderTests: function() {
      let url = process.env.VUE_APP_ROOT_API + "/accesspoints/test_list";
      let self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        self.tests = response.data;
      });
    },
    loadDocumentTypes: function() {
      // TODO: replace by test-types (which contain link to document types)
      let url = process.env.VUE_APP_ROOT_API + "/accesspoints/document_types";
      let self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        self.document_types = response.data;
      });
    },
    loadSendChallenges: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/sender_challenges/get/" +
        this.accesspoint.id;
      let self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        self.send_challenges = response.data;
      });
    },
    loadReceiverChallenges: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/receiver_challenges/get/" +
        this.accesspoint.id;
      let self = this;
      this.$refs.backend.get_auth(url).then(function(response) {
        self.receiver_challenges = response.data;
      });
    },
    updateShownDocumentTypes(challenge_data) {
      for (let i = 0; i < this.document_types.length; i++) {
        let doctype = this.document_types[i];
        if (doctype.id === challenge_data.document_type) {
          doctype["challenge_created"] = true;
          doctype["challenge"] = challenge_data;
          //this.document_types = this.document_types
          //alert(JSON.stringify(doctype))
        }
      }
    },
    createSendTestChallenge: function(test_identifier) {
      let url =
        process.env.VUE_APP_ROOT_API + "/accesspoints/sender_challenges/create";
      let args = {
        ap_id: this.accesspoint.id,
        test_identifier: test_identifier
      };
      let self = this;
      this.$refs.backend
        .post_auth(url, args)
        .then(function(response) {
          //alert("Success: " + JSON.stringify(response.data))
          // TODO: update the internal data for the given test

          self.viewSendTestChallenge(response.data);
          //alert(msg)
          self.loadSendChallenges();
          //self.loadData()
        })
        .catch(function(error) {
          //alert("Error: " + JSON.stringify(error.response.data))
          // should have been handled by backend
        });
    },
    deleteSendTestResult: function(challenge) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/sender_challenges/delete/" +
        challenge.id;
      let self = this;
      this.$refs.backend.delete_auth(url).then(function(response) {
        self.loadSendChallenges();
      });
    },
    viewSendTestChallenge: function(challenge) {
      let message_table = [
        [this.$t("recipient"), this.smk_address],
        [this.$t("document_type"), challenge.document_type_shortname],
        [this.$t("code"), challenge.challenge_code]
      ];
      this.$refs.userMsg.doShowMessage(
        this.$t("sender_extended_test_message_title"),
        this.$t("sender_extended_test_message"),
        null,
        message_table
      );
    },
    receiverTestChallengeShowSendDialog: function(challenge) {
      this.receiverTestIdentifier = challenge.identifier;
      this.showReceiverTestRecipientDialog = true;
    },
    receiverTestChallengeCancelSendDialog: function() {
      this.showReceiverTestRecipientDialog = false;
    },
    receiverTestChallengeCodeEntered: function(value, challenge_id) {
      let self = this;
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/receiver_challenges/verify";
      let args = {
        challenge_id: challenge_id,
        challenge_code: value
      };
      this.$refs.backend
        .post_auth(url, args)
        .then(function(response) {
          // TODO: update the internal data for the given test
          self.loadReceiverChallenges();
        })
        .catch(function(error) {
          alert("Error: " + JSON.stringify(error.response.data));
        });
    },
    receiverTestChallengeCodeCanceled: function() {},
    receiverTestChallengeEnterCode: function(challenge) {
      this.$refs.userInput.doShowUserInput(
        this.$t("accesspoint_validation_enter_code"),
        this.$t("accesspoint_validation_enter_code_description"),
        "",
        [],
        this.receiverTestChallengeCodeEntered,
        this.receiverTestChallengeCodeCanceled,
        this.receiverTestChallengeCodeCanceled,
        challenge.challenge.id
      );
    },
    receiverTestChallengeSendDocument: function() {
      let recipient_id = this.$refs.recipientField.getIdentifier();
      // TODO: make this a separate stored value?
      localStorage.setItem("send_overwrite_recipient_value", recipient_id);
      this.showReceiverTestRecipientDialog = false;
      // start test task viewer
      let args = {
        ap_id: this.accesspoint.id,
        test_identifier: this.receiverTestIdentifier,
        recipient: recipient_id
      };

      let url =
        process.env.VUE_APP_ROOT_API_WS + "/accesspoints/extended_test/send";
      let self = this;
      this.$refs.taskViewer
        .run(url, args)
        .then(function(result) {
          //self.fetchDocumentTestResults()
          self.loadReceiverChallenges();
        })
        .catch(function(error) {
          //  alert('error! ' + error)
        });
    },
    deleteReceiverTestResult: function(challenge) {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/receiver_challenges/delete/" +
        challenge.id;
      let self = this;
      this.$refs.backend.delete_auth(url).then(function(response) {
        self.loadReceiverChallenges();
      });
    },
    getDocTypeName: function(doctype_id) {
      for (let i = 0; i < this.document_types.length; i++) {
        if (this.document_types[i].id == doctype_id) {
          return this.document_types[i].name;
        }
      }
      return "<any>";
    },
    submitForApproval: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/request_approval/" +
        this.accesspoint.id;
      let self = this;
      this.$refs.backend.post_auth(url).then(function(response) {
        self.accesspoint.onboarder_extended_requested = true;
      });
    },
    revokeApprovalSubmission: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/revoke_approval_request/" +
        this.accesspoint.id;
      let self = this;
      this.$refs.backend.post_auth(url).then(function(response) {
        self.accesspoint.onboarder_extended_requested = false;
      });
    },
    downloadTestReport: function() {
      let url =
        process.env.VUE_APP_ROOT_API +
        "/accesspoints/test_report/" +
        this.accesspoint.id;
      this.$refs.backend.download_auth(url);
    }
  },
  mounted() {
    try {
      this.loadData();
    } catch (berr) {
      console.log(berr);
    }
    let ls_recipient_field_value = localStorage.getItem(
      // TODO: store this one separately?
      "send_overwrite_recipient_value"
    );
    if (ls_recipient_field_value) {
      if (this.showReceiverTestRecipientDialog) {
        this.$nextTick(() => {
          this.$refs.recipientField.setIdentifier(ls_recipient_field_value);
        });
      }
    }
  },
  watch: {},
  computed: {
    sendTestsHeaders() { return [
        { text: this.$t("test_type"), value: "name", sortable: false },
        { text: this.$t("passed"), value: "passed", sortable: false },
        { text: this.$t("code"), value: "code", sortable: false }
      ]
    },
    receiverTestsHeaders() { return [
        { text: this.$t("test_type"), value: "name", sortable: false },
        { text: this.$t("passed"), value: "passed", sortable: false },
        { text: this.$t("document"), value: "document", sortable: false }
      ]
    },
    shownSendTests: function() {
      /*
        let result = []
        for (let i=0; i<this.document_types.length; i++) {
            // hacky way to clone objects through json
            let doctype = JSON.parse(JSON.stringify(this.document_types[i]));
            doctype["challenge_created"] = false
            for (let j=0; j<this.send_challenges.length; j++) {
                let challenge = this.send_challenges[j]
                if (doctype.id === challenge.document_type) {
                    doctype["challenge_created"] = true
                    doctype["challenge"] = challenge
                }
            }
            result.push(doctype)
        }
        return result
        */
      let result = [];
      for (let i = 0; i < this.tests.sender_tests.length; i++) {
        // hacky way to clone objects through json
        let test = JSON.parse(JSON.stringify(this.tests.sender_tests[i]));
        test["challenge_created"] = false;
        for (let j = 0; j < this.send_challenges.length; j++) {
          let challenge = this.send_challenges[j];
          if (test.identifier === challenge.test_identifier) {
            test["challenge_created"] = true;
            test["challenge"] = challenge;
          }
        }
        result.push(test);
      }
      return result;
    },
    shownReceiverTests: function() {
      let result = [];
      for (let i = 0; i < this.tests.receiver_tests.length; i++) {
        // hacky way to clone objects through json
        let test = JSON.parse(JSON.stringify(this.tests.receiver_tests[i]));
        test["challenge_created"] = false;
        for (let j = 0; j < this.receiver_challenges.length; j++) {
          let challenge = this.receiver_challenges[j];
          if (test.identifier === challenge.test_identifier) {
            test["challenge_created"] = true;
            test["challenge"] = challenge;
          }
        }
        result.push(test);
      }
      return result;
    },
    smk_address() {
      return this.$store.getters.getSMKAddress;
    }
  }
};
</script>

<style scoped lang="stylus">
.options
  font-size 14px
  padding 0px
  margin 0px
  background-color #f4f4f4

.cardheader
  width 100%
</style>
