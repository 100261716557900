<template>
  <v-dialog v-model="showText">
    <v-card>
      <v-card-title class="text-h5 darken-2"
        >{{ textTitle }}
        <v-layout wrap justify-end>
          <v-card-actions class="text-center">
            <v-btn icon color="primary" @click.stop="showText = false" :title="$t('close')"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-layout>
      </v-card-title>

      <v-card-text class="text-left"
        ><pre>{{ convertedTextMessage }}</pre></v-card-text
      >

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showText = false">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TextViewer",
  data() {
    return {
      textTitle: String,
      textMessage: "",
      showText: false
    };
  },
  props: {},
  methods: {
    doShowText: function(title, message) {
      this.textTitle = title;
      this.textMessage = message;
      this.showText = true;
    }
  },
  computed: {
    convertedTextMessage: function() {
      let conv = this.textMessage;
      if (conv && conv !== "") {
        conv = conv.replace(/\\r/g, "");
        conv = conv.replace(/\\n/g, "\n");
      }
      return conv;
    }
  }
};
</script>

<style scoped lang="stylus">
pre
  color #ffffff
  background-color #333333
  font-size 14px
  padding 10px
</style>
