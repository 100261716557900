var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-tabs',{attrs:{"color":"secondary","dark":"","slider-color":"yellow"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t("onboarder_approval_open_requests")))]),_c('v-tab-item',[_c('v-btn',{staticClass:"primary",on:{"click":_vm.reloadButtonClicked}},[_vm._v(_vm._s(_vm.$t("reload")))]),_c('v-data-table',{attrs:{"headers":_vm.participantHeaders,"items":_vm.approvalRequests,"footer-props":{
            itemsPerPageOptions:[10,25,50,100,{ text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]
          },"hide-actions0":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(props.item.date_joined))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(props.item.organization))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(props.item.email))]),_c('td',{staticClass:"text-left"},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.approveParticipant(props.item.user_id)}}},[_vm._v(_vm._s(_vm.$t("approve")))])],1),_c('td',{staticClass:"text-left"},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.denyParticipant(props.item.user_id)}}},[_vm._v(_vm._s(_vm.$t("onboarder_approval_deny")))])],1)])]}}])})],1),_c('v-tab',[_vm._v(_vm._s(_vm.$t("onboarder_approval_approved")))]),_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.participantHeaders,"items":_vm.approvedParticipants,"footer-props":{
            itemsPerPageOptions:[10,25,50,100,{ text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]
          },"hide-actions0":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(props.item.date_joined))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(props.item.organization))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(props.item.email))]),_c('td',{staticClass:"text-left"},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.revokeParticipant(props.item.user_id)}}},[_vm._v(_vm._s(_vm.$t("onboarder_approval_is_not_participant")))])],1)])]}}])})],1),_c('v-tab',[_vm._v(_vm._s(_vm.$t("onboarder_approval_nonparticipants")))]),_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.participantHeaders,"items":_vm.nonParticipantUsers,"footer-props":{
            itemsPerPageOptions:[10,25,50,100,{ text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]
          },"hide-actions0":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(props.item.date_joined))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(props.item.organization))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(props.item.email))]),_c('td',{staticClass:"text-left"},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.approveParticipant(props.item.user_id)}}},[_vm._v(_vm._s(_vm.$t("onboarder_approval_is_participant")))])],1)])]}}])})],1)],1)],1),_c('BackendConnector',{ref:"backend"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }